// QuoteApp.js

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify'; // then this
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import { handleApiResponse } from '../../apis/apiUtils';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import BackButton from '../../common/BackButton';


const getItems = (data) => {
    return data.map(k => ({
        id: `${k._id}`,
        content: `${k.name}`,
        original_id: `${k._id}`
    }));
};


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : '#e5e5e5',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
});

function ManagePolicy() {
    const [state, setState] = useState([[], []]);
    const dispatch = useDispatch();
    const [paginationData, setPaginationData] = useState({ perpage: 200, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '' });

    useEffect(() => {
        getUserPolicy(1);
    }, []);

    const getUserPolicy = async (page) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Policy', 'Manage', ''] }));
            const res = await sendRequest('/user/policies', 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: 1 });
            handleApiResponse(res);
            dispatch(callCommonAction({ loading: false }));
            if (res.data.type === "success") {
                const policyList = res.data.data.policyList.docs;
                if (policyList.length > 0) {
                    const userPolicies = res.data.data.user.hasOwnProperty('policies') ? res.data.data.user.policies : [];
                    const array1 = await getItems(policyList);
                    const array2 = await getItems(res.data.data.user === null ? [] : policyList.filter((item) => userPolicies.includes(item._id)));
                    // Remove objects from array1 that have matching id in array2
                    const filteredArray1 = await array1.filter(item1 => !array2.some(item2 => item1.original_id === item2.original_id));
                    // console.log('filteredArray1',filteredArray1)
                    setState([array2, filteredArray1]);
                }
            }
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const onDragEnd = (result) => {
        // Check if there's a valid drop result
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;

        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            if (result[0].length === 0) return false; // If last element go to remove then false return

            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            updateUserPolicies(newState);
            setState(newState.filter(group => group.length));
        }
    };

    /** Update User Policies */
    const updateUserPolicies = async (data) => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const policyIds = data.length && data[0].map((item) => item.original_id);
            const res = await sendRequest('/user/update-policies', 'POST', { policy_ids: policyIds });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };


    console.log(state[0] === undefined || state[1] === undefined ? setState([...state, []]) : null); // Please do not clear this console

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Manage Insurance Type</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div id="kt_content_container" className="container-xxl">
                <div className="container-drag">
                    <div className='d-flex align-items-center justify-content-between'>
                        <h2 className="mb-0">DRAG & DROP</h2>
                        <BackButton marginCustom={0} />
                    </div>

                    <div>
                        <div className='row pt-10 mb-2'>
                            <div className='col-6'><h4 className="task-header d-flex align-items-center">
                                <i className="fs-1 ki-duotone ki-questionnaire-tablet me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i> <span>Associated Policies</span></h4></div>
                            <div className='col-6'><h4 className="task-header d-flex align-items-center">
                                <i className="fs-1 ki-duotone ki-notepad me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                </i> <span>All Policies</span></h4></div>
                        </div>
                        <div className='row draggable-row p-2 p-lg-5 shadow m-0' style={{ display: 'flex' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {state.map((el, ind) => (
                                    <Droppable key={ind} droppableId={`${ind}`}>
                                        {(provided, snapshot) => (
                                            <div className='col-6 p-2 p-lg-10 draggable-box'
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                                {...provided.droppableProps}
                                            >
                                                {el.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}  // Change this line to use item.id instead of item._id
                                                        draggableId={item.id}  // Change this line to use item.id instead of item._id
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div className='draggable shadow'
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-around'
                                                                    }}
                                                                >
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagePolicy;
