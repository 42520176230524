import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import constant from '../../../apis/constant';
import CommonReducer, { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import { Helmet } from 'react-helmet';
import BackButton from '../../../common/BackButton';
import Select from 'react-select';
import { validatePlanFormData } from './Validation';

const AddEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [errorsInfo, setErrorsInfo] = useState({});
    const [planDetail, setPlanDetail] = useState({ interval: 1, name: '', unit_amount: 0.00, description: '', currency: 'usd' });
    const [selectedIntervalInMonth, setSelectedIntervalInMonth] = useState(null);
    const onChangeInterval = (data) => setSelectedIntervalInMonth(data);



    useEffect(() => {
        if (params.id !== undefined) getAddEditFormData();
        dispatch(callCommonAction({ breadCrumb: ['Plan', params.id === undefined ? 'Add Form' : 'Edit Form'] }));
    }, []);

    /** Get Data Only For Edit Time **/
    const getAddEditFormData = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/plan/form/' + params.id, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if(res.data.data.unit_amount == '0'){
                toast.error('Free plan could not be updated');
                navigate(appRoutes.adminPlanListRoute);
                return false;
            }
            if (res.data.type === "success") {

                const intervalMon = res.data.data.recurring != '' ? JSON.parse(res.data.data.recurring).interval_count : 1;
                setPlanDetail((prev) => ({
                    ...prev,
                    interval: intervalMon,
                    name: res.data.data.name,
                    unit_amount: res.data.data.unit_amount,
                    description: res.data.data.description,
                    currency: 'usd'
                }));
                let objCust = constant.intervalInMonth.find(item => item.value == intervalMon);
                setSelectedIntervalInMonth(objCust);
            }
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /** Handle Change ****/
    const changeValues = (e) => {
        planDetail[e.target.name] = e.target.value;
        setPlanDetail({ ...planDetail, [e.target.name]: e.target.value });
        checkFormIsValid(e.target.name);
    };

    /** Validation implemented here */
    const checkFormIsValid = async (fieldName) => {
        const res = validatePlanFormData(planDetail, fieldName, params);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };


    /** Submit Form Data */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid('')) {
            try {
                dispatch(callCommonAction({ loading: true }));
                let body = { interval: selectedIntervalInMonth.value, name: planDetail.name, unit_amount: planDetail.unit_amount, description: planDetail.description, currency: 'usd' };
                var url = params.id === undefined ? '/plan/create' : '/plan/update/' + params.id;
                const res = await sendRequest(url, 'POST', body);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    navigate(appRoutes.adminPlanListRoute);
                }
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };


    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {/* {console.log('errorsInfo',errorsInfo,'planDetail',planDetail)} */}
            <Helmet>
                <title>iQuote | Plan Form</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div id="kt_content_container" className="container-xxl">
                <BackButton />
                <div className="card">
                    <div className="card-header" id="kt_chat_contacts_header">
                        <div className="card-title">
                            <i className="ki-duotone ki-badge fs-1 me-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                            </i>
                            <h2>Plan Form</h2>
                        </div>
                    </div>
                    <div className="card-body table-frame pt-5">
                        <form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={handleSubmit}>
                            <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                                <div className="col">
                                    <div className="fv-row fv-plugins-icon-container">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            <span className="required">Name</span>
                                        </label>
                                        <input type="text" onChange={changeValues} value={planDetail.name} className={`form-control form-control-solid ${errorsInfo.name ? 'is-invalid' : ''}`} name="name" />
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{errorsInfo.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fv-row fv-plugins-icon-container">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            <span className="required">Description</span>
                                        </label>
                                        <input type="text" onChange={changeValues} value={planDetail.description} className={`form-control form-control-solid ${errorsInfo.description ? 'is-invalid' : ''}`} name="description" />
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{errorsInfo.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fv-row fv-plugins-icon-container">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            <span className="">Price in ($)</span>
                                        </label>
                                        <input type="number" className={`form-control form-control-solid ${errorsInfo.unit_amount ? 'is-invalid' : ''}`} onChange={changeValues} placeholder="0.00" step="0.01" aria-label="Amount" name="unit_amount" value={planDetail.unit_amount} label="Amount" />
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{errorsInfo.unit_amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    params.id === undefined
                                    ?
                                    <div className="col">
                                        <div className="fv-row fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span className="required">Interval</span>
                                            </label>
                                            <Select
                                                value={selectedIntervalInMonth}
                                                onChange={onChangeInterval}
                                                options={constant.intervalInMonth}
                                            />
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.interval}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col'></div>
                                }

                                <div className='col'>
                                    <div className='d-flex flex-column align-items-start'>
                                        <label className="fs-6 fw-semibold form-label mt-3">&nbsp;</label>
                                        <button type="submit" id="kt_sign_up_submit"
                                            className="btn btn-primary me-2" disabled={loading ? true : false}>
                                            {
                                                loading
                                                    ?
                                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                    :
                                                    <span className="indicator-label">Save</span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AddEdit;
