import React, { useState, useEffect } from 'react';
import { Col, Row, Stack, Form, Card, CardBody, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateScopeFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import appConstatnt from '../../../apis/constant';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch } from 'react-redux';
import SignaturePad from './SignaturePad';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';


const Scope = () => {
    const [scopeOfAggrementDetail, setScopeOfAggrementDetail] = useState({
        BeneficiaryOrAuthorizedSignature: null,
        BeneficiaryOrAuthorizedDate: '',
        RepresentativeName: '',
        RelationshipToTheBeneficiary: '',
        AgentName: '',
        AgentPhone: '',
        BeneficiaryName: '',
        BeneficiaryPhone: '',
        BeneficiaryAddress: '',
        InitialMethodOfContact: '',
        AgentSignature: '',
        PlanTheAgentDuringThisMeeting: '',
        DateAppointmentCompleted: '',
        PlanUseOnly: '',
        productsForScope: appConstatnt.productsForScope
    });

    const [signatureImage, setSignatureImage] = useState(null);

    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const [showSignaturePad, setShowSignaturePad] = useState(false);



    useEffect(() => {
        getScopeOfAppointment();
    }, []);

    const handleClose = () => setShowSignaturePad(false);

    const handleShow = () => setShowSignaturePad(true);

    const onSaveSignature = async (signatureData) => {
        setShowSignaturePad(false);
    };


    const handleDownload = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));

            // Adjust the endpoint to include the user ID if needed
            const res = await sendRequest('/user/download-scope-appointment-pdf', 'GET');

            // Check if pdfData is a Blob object
            if (!(res.data instanceof Blob)) {
                throw new Error('Invalid PDF data received');
            }

            const url = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'output.pdf');
            document.body.appendChild(link);
            link.click();

            dispatch(callCommonAction({ loading: false }));
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };


    const getScopeOfAppointment = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/user/get-scope-of-appointment', 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success" && res.data.data !== null) {

                const productsForScopePromises = appConstatnt.productsForScope.map(async (checkbox) => {
                    return await res.data.data.products_for_scope.includes(Number(checkbox.id)) ? { ...checkbox, checked: !checkbox.checked } : checkbox;
                });

                const productsForScope = await Promise.all(productsForScopePromises);


                console.log('productsForScope', productsForScope);

                setScopeOfAggrementDetail({
                    BeneficiaryOrAuthorizedSignature: res.data.data.beneficiary_or_authorized_signature,
                    BeneficiaryOrAuthorizedDate: res.data.data.beneficiary_or_authorized_date,
                    RepresentativeName: res.data.data.representative_name,
                    RelationshipToTheBeneficiary: res.data.data.relationship_to_the_beneficiary,
                    AgentName: res.data.data.agent_name,
                    AgentPhone: res.data.data.agent_phone,
                    BeneficiaryName: res.data.data.beneficiary_name,
                    BeneficiaryPhone: res.data.data.beneficiary_phone,
                    BeneficiaryAddress: res.data.data.beneficiary_address,
                    InitialMethodOfContact: res.data.data.initial_method_of_contact,
                    AgentSignature: res.data.data.agent_signature,
                    PlanTheAgentDuringThisMeeting: res.data.data.plan_the_agent_during_this_meeting,
                    DateAppointmentCompleted: res.data.data.date_appointment_completed,
                    PlanUseOnly: res.data.data.plan_use_only,
                    productsForScope: productsForScope
                });
            }
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /** Handle Input Change Manage Heere */
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        if (name == 'productsForScope') {
            const updatedCheckboxes = scopeOfAggrementDetail.productsForScope.map((checkbox) => {
                return checkbox.id === String(value) ? { ...checkbox, checked: !checkbox.checked } : checkbox;
            });
            scopeOfAggrementDetail[name] = updatedCheckboxes;
            setScopeOfAggrementDetail({ ...scopeOfAggrementDetail, [name]: updatedCheckboxes });
        } else {
            scopeOfAggrementDetail[name] = value;
            setScopeOfAggrementDetail({ ...scopeOfAggrementDetail, [name]: value });
        }

        checkFormIsValid(e.target.name);
    };

    /* Validation implemented here */
    const checkFormIsValid = async (fieldName) => {
        const res = validateScopeFormData(scopeOfAggrementDetail, fieldName);
        setErrorsInfo(res.errors);
        return res.formValidCheck;
    };

    /** Submit Scope Of Appoitment **/
    const saveScopeOfAppointment = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid('')) {
            try {
                const formData = new FormData();
                Object.keys(scopeOfAggrementDetail).forEach((key) => {
                    if (key == 'productsForScope') {
                        formData.append(key, JSON.stringify(scopeOfAggrementDetail[key]));
                    } else {
                        formData.append(key, scopeOfAggrementDetail[key]);
                    }

                });

                formData.append('old_user_image', signatureImage);
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest('/user/save-scope-of-appointment', 'POST', formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {

                }
            } catch (error) {
                console.log('error', error);
                alert(error.message);
                toast.error(error.message);
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };


    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {/* {
                console.log('productsForScope', scopeOfAggrementDetail.productsForScope)
            } */}
            <Helmet>
                <title>iQuote | Scope of Appointment</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {
                        scopeOfAggrementDetail.AgentName && <div className='text-end mb-3'>
                            <Button size='sm' onClick={handleDownload}><i class="ki-duotone ki-file-down fs-3" >
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i> Download PDF</Button>
                        </div>
                    }


                    <Form onSubmit={saveScopeOfAppointment}>
                        <Row>
                            <Col xs={12}>
                                <h2>Scope of Sales Appointment Confirmation Form</h2>
                                <p>TheCenters for Medicare and Medicaid Services requires agentsto document the scope of a marketing appointment prior to any individual sales meeting to ensure understanding of what will be discussed between the agent and the Medicare beneficiary (or their authorized representative). All information provided on this form is confidential and should be completed by each person with Medicare or his/her authorized representative</p>
                            </Col>
                            <Col xs={12} className=''>

                                <div className='only-for-web'>
                                    <Card className='bg-body-tertiary mt-8 mb-8'>
                                        <CardBody>
                                            <Stack className='d-flex text-start bg-body-tertiary gap-6 '>
                                                <div className='text-center'>
                                                    <strong className='d-block'>Please initial below beside the type of product(s) you want the agent to discuss.</strong>
                                                    (Refer to page 2 for product type descriptions)
                                                </div>
                                                {
                                                    scopeOfAggrementDetail.productsForScope.length > 0 && scopeOfAggrementDetail?.productsForScope.map(item => {
                                                        return <Form.Check name="productsForScope" onChange={handleChangeInput} key={item.id} className='text-body' aria-label="" value={item.id} label={item.type} checked={item.checked} />;

                                                    })
                                                }
                                            </Stack>
                                        </CardBody>
                                    </Card>

                                    <div className='text-center mb-12'>
                                        <strong className='d-block'>By signing thisform, you agree to a meeting with a sales agent to discuss the types of products you initialed above.</strong>
                                        Please note, the person who will discuss the products is either employed or contracted by a Medicare plan. They do not work directly for the Federal government. This individual may also be paid based on your enrollment in a plan. Signing this form  does NOT obligate you to enroll in a plan, affect your current or future enrollment, or enroll you in a Medicare plan.
                                    </div>
                                </div>

                                <Card className='bg-body-tertiary'>
                                    <CardBody>

                                        <div className='only-for-web'>
                                            <div className='mt-3 mb-5'><strong>Beneficiary or Authorized Representative Signature and Signature Date:</strong></div>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Signature</Form.Label>
                                                        <div>
                                                            <Button onClick={handleShow} size='sm'>
                                                                <i class="ki-duotone ki-pencil">
                                                                    <span class="path1"></span>
                                                                    <span class="path2"></span>
                                                                </i>
                                                                Write Signature
                                                            </Button>
                                                        </div>
                                                        <Modal size='lg' show={showSignaturePad} onHide={handleClose}>
                                                            <Modal.Header className='py-4' closeButton>
                                                                <Modal.Title>Write your signature</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <SignaturePad onSave={onSaveSignature} setSignatureImage={setSignatureImage} />
                                                            </Modal.Body>
                                                        </Modal>

                                                        {
                                                            signatureImage
                                                                ?
                                                                <div className='border border-dark-subtle mt-3 p-3 rounded-2 sign-image'>
                                                                    <p className='m-0 small'>Signature Image:</p>
                                                                    <img width={300} src={signatureImage} alt="Signature" />
                                                                </div>
                                                                :
                                                                scopeOfAggrementDetail.BeneficiaryOrAuthorizedSignature &&
                                                                <div className='border border-dark-subtle mt-3 p-3 rounded-2 sign-image'>
                                                                    <p className='m-0 small'>Signature Image:</p>
                                                                    <img width={300} src={process.env.REACT_APP_ASSET_ENDPOINT + '/' + scopeOfAggrementDetail.BeneficiaryOrAuthorizedSignature} alt="Signature" />
                                                                </div>
                                                        }

                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.BeneficiaryOrAuthorizedSignature}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Signature Date</Form.Label>
                                                        <Form.Control type="date" value={scopeOfAggrementDetail.BeneficiaryOrAuthorizedDate} name="BeneficiaryOrAuthorizedDate" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.BeneficiaryOrAuthorizedDate}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='mt-3 mb-5'><strong>If you are the authorized representative, please sign above and print below:
                                            </strong></div>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Representative’s Name</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.RepresentativeName} name="RepresentativeName" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.RepresentativeName}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Your Relationship to the Beneficiary</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.RelationshipToTheBeneficiary} name="RelationshipToTheBeneficiary" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.RelationshipToTheBeneficiary}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className='mt-3 mb-5'><strong>To be completed by Agent:</strong></div>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Agent Name</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.AgentName} name="AgentName" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.AgentName}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Agent Phone</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.AgentPhone} name="AgentPhone" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.AgentPhone}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Beneficiary Name</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.BeneficiaryName} name="BeneficiaryName" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.BeneficiaryName}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Beneficiary Phone</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.BeneficiaryPhone} name="BeneficiaryPhone" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.BeneficiaryPhone}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Beneficiary Address</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.BeneficiaryAddress} name="BeneficiaryAddress" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.BeneficiaryAddress}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Initial Method of Contact: (Indicate here if beneficiary was a walk-in.)</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.InitialMethodOfContact} name="InitialMethodOfContact" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.InitialMethodOfContact}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Agent’s Signature</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.AgentSignature} name="AgentSignature" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.AgentSignature}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Plan(s) the agent represented during this meeting</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.PlanTheAgentDuringThisMeeting} name="PlanTheAgentDuringThisMeeting" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.PlanTheAgentDuringThisMeeting}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Date Appointment Completed</Form.Label>
                                                        <Form.Control type="date" value={scopeOfAggrementDetail.DateAppointmentCompleted} name="DateAppointmentCompleted" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.DateAppointmentCompleted}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='mt-3 mb-5'><strong>Plan Use Only:</strong></div>
                                            <Row className='mb-5'>
                                                <Col xs={12}>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Agent, if the form wassigned by the beneficiary at time of appointment, provide explanation why SOA was not documented prior to meeting</Form.Label>
                                                        <Form.Control type="text" value={scopeOfAggrementDetail.PlanUseOnly} name="PlanUseOnly" placeholder="" onChange={handleChangeInput} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            <span role="alert">{errorsInfo.PlanUseOnly}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='mb-5'>
                                                <Col xs={12} className='text-center'>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Button type="submit">Submit</Button>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className='mb-8'>
                                                <Col xs={12} md={8} className='text-center mx-auto'>
                                                    *Scope of Appointment documentation is subject to CMS record retention requirements *
                                                    <em className='text-italic d-block small'>Aetna Medicare is a PDP, HMO, PPO plan with a Medicare contract. Our SNPs also have contracts with State Medicaid programs. Enrollment in our plans depends on contract renewal.</em>
                                                    Y0001_4011_7653 Accepted 05/2016
                                                </Col>
                                            </Row>
                                        </div>




                                        <Row className='mb-5'>
                                            <Col xs={12}>
                                                <h2 className='mb-3'>Stand-alone Medicare Prescription Drug Plans (Part D)</h2>
                                                <p><strong>Medicare Prescription Drug Plan (PDP):</strong> A stand-alone drug plan that adds prescription drug coverage to Original Medicare, some Medicare Cost Plans, some Medicare Private-Fee-for-Service Plans, and Medicare Medical Savings Account Plans.</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <h2 className='mb-3'>Medicare Advantage Plans (Part C) and Cost Plans </h2>
                                                <p><strong>Medicare Health Maintenance Organization (HMO):</strong> A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage and sometimes covers Part D prescription drug coverage. In most HMOs, you can only get your care from doctors or hospitals in the plan’s network (except in emergencies). </p>

                                                <p><strong>Medicare Preferred Provider Organization (PPO) Plan:</strong> A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage and sometimes covers Part D prescription drug coverage. PPOs have network doctors and hospitals but you can also use out-of-network providers, usually at a higher cost. </p>

                                                <p><strong>Medicare Private Fee-For-Service (PFFS) Plan:</strong> A Medicare Advantage Plan in which you may go to any Medicare-approved doctor, hospital and provider that accepts the plan’s payment, terms and conditions and agrees to treat you – not all providers will. If you join a PFFS Plan that has a network, you can see any of the network providers who have agreed to always treat plan members. You will usually pay more to see out-of-network providers. </p>

                                                <p>
                                                    <strong>Medicare Point of Service (POS) Plan:</strong> A type of Medicare Advantage Plan available in a local or regional area which combines the best feature of an HMO with an out-of-network benefit. Like the HMO, members are required to designate an in-network physician to be the primary health care provider. You can use doctors, hospitals, and providers outside of the network for an additional cost.
                                                </p>

                                                <p><strong>Medicare Special Needs Plan (SNP):</strong> A Medicare Advantage Plan that has a benefit package designed for people with special health care needs. Examples of the specific groups served include people who have both Medicare and Medicaid, people who reside in nursing homes, and people who have certain chronic medical conditions.</p>

                                                <p><strong>Medicare Medical Savings Account (MSA) Plan:</strong> MSA Plans combine a high deductible health plan with a bank account. The plan deposits money from Medicare into the account. You can use it to pay your medical expenses until your deductible is met.</p>

                                                <p><strong>Medicare Cost Plan:</strong> In a Medicare Cost Plan, you can go to providers both in and out of network. If you get services outside of the plan’s network, your Medicare-covered services will be paid for under Original Medicare but you will be responsible for Medicare coinsurance and deductibles.</p>

                                                <p><strong>Medicare Medicaid Plan (MMP):</strong> An MMP is a private health plan designed to provide integrated and coordinated Medicare and Medicaid benefits for dual eligible Medicare beneficiaries.</p>


                                                <h2 className='mb-3'>Dental/Vision/Hearing Products </h2>
                                                <p>Plans offering additional benefits for consumers who are looking to cover needs for dental, vision or hearing. These plans are not affiliated or connected to Medicare. </p>

                                                <h2 className='mb-3'>Hospital Indemnity Products  </h2>
                                                <p>Plans offering additional benefits; payable to consumers based upon their medical utilization; sometimes used to defray copays/coinsurance. These plans are not affiliated or connected to Medicare. </p>

                                                <h2 className='mb-3'>Medicare Supplement (Medigap) Products   </h2>
                                                <p>Plans offering a supplemental policy to fill “gaps” in Original Medicare coverage. A Medigap policy typically pays some or all of the deductible and coinsurance amounts applicable to Medicare-covered services, and sometimes covers items and services that are not covered by Medicare, such as care outside of the country. These plans are not affiliated or connected to Medicare. </p>
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>


                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Scope;
