import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import appRoutes from "../../configs/AppRoutes";
import { Form, Button, Modal } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { validateUserDetailFormData, validateChangePasswordFormData } from "./Validation";
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { handleApiResponse } from "../../apis/apiUtils";
import { uploadImage } from '../../common/ImageUpload';
import { Helmet } from "react-helmet";
import BackButton from "../../common/BackButton";
import SignaturePad from '../MultiForm/SignaturePad';

const Overview = () => {
  const { loading, loading2, loading3, user, isAdmin } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);

  const [userDetail, setUserDetail] = useState({});
  const [backgroundImage, setBackgroundImage] = useState(user !== null ? 'url(' + process.env.REACT_APP_ASSET_ENDPOINT + '/' + user.user_image + ')' : 'url("' + process.env.REACT_APP_FRONTEND + '"/assets/media/avatars/blank.png")');

  const [errorsInfo, setErrorsInfo] = useState({
    phonenumber: "",
    first_name: "",
    display_name: "",
    last_name: "",
    email: "",
    currentpassword: '',
    newpassword: '',
    confirmpassword: '',
    signature_image: '',
  });
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const handleShow = () => setShowSignaturePad(true);
  const handleClose = () => setShowSignaturePad(false);
  const onSaveSignature = async (signatureData) => {
    setShowSignaturePad(false);
    setUserDetail({ ...userDetail, signature_image: signatureData });
  };

  /** Click on of them suggestion and set in userdetail */
  const handleSuggestionClick = (selectedDisplayName) => {
    setUserDetail({ ...userDetail, display_name: selectedDisplayName });
    setSuggestions([]); // Clear suggestions after selecting one
  };

  /** On Blur Of Display Input Call This fun **/
  const handleBlurDisplay = async (e) => {
    const value = e.target.value;
    if (value) {
      try {
        const res = await sendRequest("/user/display-name-suggestions-auth", "POST", { display_name: value });
        if (res.data.type === "success") {
          if (res.data.data.length) {
            setErrorsInfo({ ...errorsInfo, display_name: '' });
          }
          setSuggestions(res.data.data);
        }
      } catch (error) {
        toast.error("Error fetching suggestions");
      }
    }
  };


  const getUser = async () => {
    try {
      dispatch(callCommonAction({ loading: true, breadCrumb: ['Dashboard', 'My Profile'] }));
      const res = await sendRequest(`/user/detail/${user._id}`, 'GET');
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);

      if (res.data.type === "success") {
        let caUser = res.data.data;

        setUserDetail({
          phonenumber: caUser !== null ? caUser.phonenumber : '',
          insurance_url: caUser !== null ? caUser.insurance_url : '',
          calendy_url: caUser !== null ? caUser.calendy_url : '',
          first_name: caUser !== null ? caUser.first_name : '',
          display_name: caUser !== null ? caUser.display_name : '',
          last_name: caUser !== null ? caUser.last_name : '',
          email: caUser !== null ? caUser.email : '',
          currentpassword: '',
          signature_image: caUser !== null ? caUser.signature_image : '',
          user_image: user !== null ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + caUser.user_image : 'url("' + process.env.REACT_APP_FRONTEND + '"/assets/media/avatars/blank.png")',
          newpassword: '',
          confirmpassword: '',
          user_image_remove: false, // Initial value
          notification_settings: {
            newLeads: caUser !== null ? caUser.hasOwnProperty('notification_settings') ? caUser.notification_settings.newLeads : false : false,
            newQuotes: caUser !== null ? caUser.hasOwnProperty('notification_settings') ? caUser.notification_settings.newQuotes : false : false,
            enableUploadToCrm: caUser !== null ? caUser.hasOwnProperty('notification_settings') ? caUser.notification_settings.enableUploadToCrm : false : false,
            enableOffer: caUser !== null ? caUser.hasOwnProperty('notification_settings') ? caUser.notification_settings.enableOffer : false : false,
          }

        });
        localStorage.setItem('user', JSON.stringify(res.data.data));
        dispatch(callCommonAction({ user: res.data.data }));
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading: false }));
    }
  };



  const handleUserImageChange = async (e) => {
    const selectedImage = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    if (selectedImage) {
      const checkValidation = await uploadImage(5, ['.png', '.jpg', '.jpeg'], selectedImage);
      if (checkValidation) {
        setUserDetail((prevUserDetail) => ({
          ...prevUserDetail,
          user_image: selectedImage,
        }));
        // Update the background image dynamically
        if (selectedImage) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setBackgroundImage(`url(${reader.result})`);
          };
          reader.readAsDataURL(selectedImage);
        } else {
          setBackgroundImage('url("' + process.env.REACT_APP_FRONTEND + '"/assets/media/avatars/blank.png")');
        }
      }
    }

  };

  const handleUserImageRemove = () => {
    setUserDetail((prevUserDetail) => ({ ...prevUserDetail, user_image: null, user_image_remove: true }));
    const fileInput = document.getElementById('userImageInput');   // Clear the file input field
    if (fileInput) fileInput.value = ''; // Reset the value to clear the selected file
    setBackgroundImage('url("' + process.env.REACT_APP_FRONTEND + '"/assets/media/avatars/blank.png")');
  };

  /** OnChange Update Input Values */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };

  /** handlePhoneChange */
  const handlePhoneChange = (phonenumber) => {
    if (phonenumber) {
      //console.log('phone', phonenumber);
      userDetail.phonenumber = phonenumber;
      setUserDetail(userDetail);
      checkFormIsValid("phonenumber");
    }
  };

  const handleChangePassword = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkChangePasswordFormIsValid(e.target.name);
  };

  /** Validation implemented here */
  const checkFormIsValid = async (fieldName) => {
    const res = validateUserDetailFormData(userDetail, fieldName, isValidPhoneNumber);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  };

  const checkChangePasswordFormIsValid = async (fieldName) => {
    const res = validateChangePasswordFormData(userDetail, fieldName);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  };

  /** Form Submit Functionality Here */
  const updateUser = async (e) => {
    e.preventDefault();
    if (await checkFormIsValid("") && suggestions.length === 0) {
      try {
        const formData = new FormData();
        Object.keys(userDetail).forEach((key) => {
          if (key != "signature_image") {
            formData.append(key, userDetail[key]);
          }
        });

        formData.append('signatureImage', signatureImage);

        formData.append('old_user_image', user.user_image);

        dispatch(callCommonAction({ loading: true }));
        let reqUrl = isAdmin ? '/admin/update-profile' : `/user/update-profile`;
        const res = await sendRequest(reqUrl, "POST", formData);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          localStorage.setItem('user', JSON.stringify(res.data.data));
          dispatch(callCommonAction({ user: res.data.data }));
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (await checkChangePasswordFormIsValid("")) {
      try {
        dispatch(callCommonAction({ loading2: true }));
        let reqUrl = isAdmin ? '/admin/update-password' : `/user/update-password`;
        const res = await sendRequest(reqUrl, "POST", userDetail);
        dispatch(callCommonAction({ loading2: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setUserDetail({ ...userDetail, currentpassword: '', newpassword: '', confirmpassword: '' });
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(callCommonAction({ loading2: false }));
      }
    }
  };

  // Function to handle toggle for each notification type (01-01-2024)
  const handleToggle = async (type, value) => {
    setUserDetail((prev) => ({
      ...prev,
      notification_settings: {
        ...prev.notification_settings,
        [type]: !value
      }
    }));
  };

  const submitNotificationSettings = async (e) => {
    e.preventDefault();
    try {
      dispatch(callCommonAction({ loading3: true }));
      const res = await sendRequest(`/user/notification-settings`, "POST", userDetail.notification_settings);
      dispatch(callCommonAction({ loading3: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        dispatch(callCommonAction({ user: res.data.data }));
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading3: false }));
    }
  };

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <Helmet>
        <title>iQuote | Profile</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <div id="kt_content_container" className="container-xxl">
        <div className="card mb-5 mb-xl-10">
          {/*begin::Card header*/}
          <div className="card-header align-content-center">
            {/**begin::Card title**/}
            <div className="card-title">
              <h4>Profile Details</h4>
            </div>


            <div className="d-flex">
              {!isAdmin && (
                 <a className="mx-2 btn btn-primary btn-sm" href={`${process.env.REACT_APP_AGENT_LANDING_PAGE}/${userDetail?.display_name}`} target="_blank" >Go to landing page</a> 
              )}
              <BackButton marginCustom={0} />

            </div>
          </div>

          {/*begin::Card header*/}
          {/*begin::Content*/}
          <div id="kt_account_settings_profile_details" className="collapse show">
            {/*begin::Form*/}
            <form id="kt_account_profile_details_form" className="form" noValidate onSubmit={updateUser} encType="multipart/form-data">
              {/*begin::Card body*/}
              <div className="card-body table-frame border-top p-9">
                {/*begin::Input group*/}
                <div className="row mb-6">

                  <label className="col-lg-4 col-form-label fw-semibold fs-6">Avatar</label>

                  <div className="col-lg-8">

                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: backgroundImage }}>

                      <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: backgroundImage }} />
                      <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                        <i className="ki-duotone ki-pencil fs-7">
                          <span className="path1" />
                          <span className="path2" />
                        </i>

                        <input type="file" id="userImageInput" name="user_image" accept=".png, .jpg, .jpeg" onChange={handleUserImageChange} />
                        <input type="hidden" name="user_image_remove" />
                      </label>
                      <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar" onClick={handleUserImageRemove}>
                        <i className="ki-duotone ki-cross fs-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </span>
                    </div>

                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                  </div>

                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="row mb-6">
                  {/*begin::Label*/}
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">First Name / Last Name</label>
                  {/*end::Label*/}
                  {/*begin::Col*/}
                  <div className="col-lg-8">
                    {/*begin::Row*/}
                    <div className="row">
                      {/*begin::Col*/}
                      <div className="col-lg-6 fv-row">
                        <input type="text" name="first_name" className={`form-control form-control-lg  mb-3 mb-lg-0 ${errorsInfo.first_name ? 'is-invalid' : ''}`} placeholder="First name" onChange={handleChange} value={userDetail.first_name} />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{errorsInfo.first_name}</span>
                          </div>
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-lg-6 fv-row">
                        <input type="text" name="last_name" className={`form-control form-control-lg  ${errorsInfo.last_name ? 'is-invalid' : ''}`} placeholder="Last name" onChange={handleChange} value={userDetail.last_name} />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{errorsInfo.last_name}</span>
                          </div>
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                {
                  isAdmin
                    ?
                    null
                    :
                    <div className="row mb-6">
                      {/*begin::Label*/}
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">Display Name</label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8 fv-row">
                        <input type="text" autoComplete="off" name="display_name" className={`form-control form-control-lg  ${errorsInfo.display_name || suggestions.length ? 'is-invalid' : ''}`} placeholder="Enter your display name" onBlur={handleBlurDisplay} onChange={handleChange} value={userDetail.display_name} />
                        {userDetail?.display_name && suggestions.length ?
                          <div className="text-danger mt-1">
                            <div className="d-flex">
                              <i class="ki-duotone ki-cross me-1 fs-2 text-danger">
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </i>
                              Not available.
                            </div>
                            {suggestions.map((suggestion, index) => (
                              <div key={index} onClick={() => handleSuggestionClick(suggestion.display_name)} className="ps-7 text-success">
                                Try:{" "}
                                <span className="cursor-pointer">
                                  {suggestion.display_name}
                                </span>
                              </div>
                            ))}
                          </div>
                          : null}
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{errorsInfo.display_name}</span>
                          </div>
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                }


                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="row mb-6">
                  {/*begin::Label*/}
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    <span className="required">Contact Phone</span>
                    <span className="ms-1" data-bs-toggle="tooltip" title="Phone number must be active">
                      <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    </span>
                  </label>
                  {/*end::Label*/}
                  {/*begin::Col*/}
                  <div className="col-lg-8 fv-row">
                    <Input
                      placeholder="Phone Number"
                      className={`form-control form-control-lg  ${errorsInfo.phonenumber ? 'is-invalid' : ''}`}
                      onChange={(handlePhoneChange)}
                      value={userDetail.phonenumber}
                    />
                    {/* <input type="text" autoComplete="off" name="phonenumber" className={`form-control form-control-lg  ${errorsInfo.phonenumber ? 'is-invalid' : ''}`} placeholder="Phone number" onChange={handleChange} value={userDetail.phonenumber} /> */}
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errorsInfo.phonenumber}</span>
                      </div>
                    </div>
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="row mb-6">
                  {/*begin::Label*/}
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">Email</label>
                  {/*end::Label*/}
                  {/*begin::Col*/}
                  <div className="col-lg-8 fv-row">
                    <input readOnly type="email" autoComplete="off" name="email" className={`form-control form-control-lg  ${errorsInfo.email ? 'is-invalid' : ''}`} placeholder="Email" onChange={handleChange} value={userDetail.email} />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errorsInfo.email}</span>
                      </div>
                    </div>
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Input group*/}


                {/*begin::Input group*/}
                {!isAdmin && (
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Signature</label>
                    <div className="col-lg-8 fv-row">
                      <div>
                        <Button onClick={handleShow} size='sm'>
                          <i class="ki-duotone ki-pencil">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          Write Signature
                        </Button>
                      </div>
                      <Modal size='lg' show={showSignaturePad} onHide={handleClose}>
                        <Modal.Header className='py-4' closeButton>
                          <Modal.Title>Write your signature</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <SignaturePad onSave={onSaveSignature} setSignatureImage={setSignatureImage} />
                        </Modal.Body>
                      </Modal>

                      {
                        signatureImage
                          ?
                          <div className='border border-dark-subtle mt-3 p-3 rounded-2 sign-image'>
                            <p className='m-0 small'>Signature Image:</p>
                            <img width={300} src={signatureImage} alt="Signature" />
                          </div>
                          :
                          userDetail.signature_image != '' && userDetail.signature_image != null &&
                          <div className='border border-dark-subtle mt-3 p-3 rounded-2 sign-image'>
                            <p className='m-0 small'>Signature Image:</p>
                            <img width={300} src={process.env.REACT_APP_ASSET_ENDPOINT + '/' + userDetail.signature_image} alt="Signature" />
                          </div>
                      }
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errorsInfo.signature_image}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


                {
                  isAdmin
                    ?
                    null
                    :
                    <>
                      <div className="mb-8 pt-5">
                        <div className="card-title m-0"><h3 className="fw-bold m-0">My Links</h3></div>
                      </div>
                      <hr className="mb-8" />
                      <div className="row mb-6">
                        {/*begin::Label*/}
                        <label className="col-lg-4 col-form-label fw-semibold fs-6"><a href="http://reviewmyinsuranceagent.com" target="_blank" className="">
                          ReviewMyInsuranceAgent URL
                        </a></label>

                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8 fv-row">
                          <input type="text" autoComplete="off" name="insurance_url" className={`form-control form-control-lg  ${errorsInfo.insurance_url ? 'is-invalid' : ''}`} placeholder="Enter URL" onChange={handleChange} value={userDetail.insurance_url} />
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{errorsInfo.insurance_url}</span>
                            </div>
                          </div>
                        </div>
                        {/*end::Col*/}
                      </div>
                      <div className="row mb-6">
                        {/*begin::Label*/}
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">Calendar URL</label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8 fv-row">
                          <input type="text" autoComplete="off" name="calendy_url" className={`form-control form-control-lg  ${errorsInfo.calendy_url ? 'is-invalid' : ''}`} placeholder="Enter URL" onChange={handleChange} value={userDetail.calendy_url} />
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{errorsInfo.calendy_url}</span>
                            </div>
                          </div>
                        </div>
                        {/*end::Col*/}
                      </div>
                    </>
                }
              </div>
              {/*end::Card body*/}
              {/*begin::Actions*/}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="submit" className="btn btn-primary btn-sm" id="kt_account_profile_details_submit" disabled={loading ? true : false} >Save Changes</button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Content*/}
        </div>



        <div className="card mb-5 mb-xl-10">
          {/*begin::Card header*/}
          <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Change Password</h3>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Content*/}
          <div id="kt_account_settings_signin_method" className="collapse show">
            {/*begin::Card body*/}
            <div className="card-body  border-top p-9">
              <div className="d-flex flex-wrap align-items-center mb-10">
                <div id="kt_signin_password_edit" className="flex-row-fluid">
                  {/*begin::Form*/}
                  <form id="kt_signin_change_password" className="form" noValidate onSubmit={updatePassword}>
                    <div className="row mb-1">
                      <div className="col-lg-4">
                        <div className="fv-row mb-0">
                          <label htmlFor="currentpassword" className="form-label fs-6 fw-bold mb-3">Current Password</label>
                          <input type="password" className={`form-control form-control-lg  ${errorsInfo.currentpassword ? 'is-invalid' : ''}`} value={userDetail.currentpassword} name="currentpassword" id="currentpassword" onChange={handleChangePassword} />
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{errorsInfo.currentpassword}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="fv-row mb-0">
                          <label htmlFor="newpassword" className="form-label fs-6 fw-bold mb-3">New Password</label>
                          <input type="password" className={`form-control form-control-lg  ${errorsInfo.newpassword ? 'is-invalid' : ''}`} value={userDetail.newpassword} name="newpassword" id="newpassword" onChange={handleChangePassword} />
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{errorsInfo.newpassword}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="fv-row mb-0">
                          <label htmlFor="confirmpassword" className="form-label fs-6 fw-bold mb-3">Confirm New Password</label>
                          <input type="password" className={`form-control form-control-lg  ${errorsInfo.confirmpassword ? 'is-invalid' : ''}`} value={userDetail.confirmpassword} name="confirmpassword" id="confirmpassword" onChange={handleChangePassword} />
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{errorsInfo.confirmpassword}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-text mb-5">Password must be at least 8 character and contain symbols</div>
                    <div className="d-flex">
                      <button type="submit" id="kt_password_submit" className="btn btn-primary me-2 px-6 btn-sm" disabled={loading2 ? true : false}>Update Password</button>
                    </div>
                  </form>
                  {/*end::Form*/}
                </div>

              </div>

            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Content*/}
        </div>

        {/* Notification Settings 01-01-2024*/}
        {
          !isAdmin
            ?
            <div className="card mb-5 mb-xl-10">
              {/*begin::Card header*/}
              <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_notification_settings_method">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Settings</h3>
                </div>
              </div>
              {/*end::Card header*/}

              {/*begin::Content*/}

              <div id="kt_account_notification_settings_method" className="collapse show">
                {/*begin::Card body*/}
                <div className="card-body  border-top p-9">
                  <div className="d-flex flex-wrap align-items-center mb-10">
                    <div id="kt_notification_settings_edit" className="flex-row-fluid">
                      {/*begin::Form*/}
                      <form id="kt_notification_settings" className="form" noValidate onSubmit={submitNotificationSettings}>
                        <div className="row mb-1">
                          <div className="col-lg-4">
                            <div className="fv-row mb-5">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="newLeads"
                                defaultChecked={userDetail?.notification_settings?.newLeads}
                                onChange={() => handleToggle('newLeads', userDetail?.notification_settings?.newLeads)}
                                label="Email me when I get a new lead"
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-3">
                            <div className="fv-row mb-3">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="newQuotes"
                                defaultChecked={userDetail?.notification_settings?.newQuotes}
                                onChange={() => handleToggle('newQuotes', userDetail?.notification_settings?.newQuotes)}
                                label="Updates on new quotes"
                              />
                            </div>
                          </div> */}
                          <div className="col-lg-4">
                            <div className="fv-row mb-5">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="enableOffer"
                                defaultChecked={userDetail?.notification_settings?.enableOffer}
                                onChange={() => handleToggle('enableOffer', userDetail?.notification_settings?.enableOffer)}
                                label="Enable Offers"
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-4">
                            <div className="fv-row mb-3">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="enableUploadToCrm"
                                defaultChecked={userDetail?.notification_settings?.enableUploadToCrm}
                                onChange={() => handleToggle('enableUploadToCrm', userDetail?.notification_settings?.enableUploadToCrm)}
                                label="Enable Upload To CRM"
                              />
                            </div>
                          </div> */}

                          <div className="col-lg-12">
                            <div className="d-flex py-3">
                              <button type="submit" id="kt_notification_submit" className="btn btn-primary me-2 px-6 btn-sm" disabled={loading3 ? true : false}>Update Settings</button>
                              {/* <button type="submit" id="kt_notification_submit" className="btn btn-primary me-2 px-6 btn-sm" disabled={loading ? true : false}>Save</button> */}
                            </div>
                          </div>
                        </div>

                      </form>
                      {/*end::Form*/}
                    </div>
                  </div>

                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Content*/}
            </div>
            :
            null
        }
      </div>

    </div>
  );
};

export default Overview;
