import React from 'react';
import appRoutes from '../configs/AppRoutes';
import { Routes, Route } from 'react-router-dom';

import ForgotPassword from './../components/ForgotPassword/ForgotPassword';
import Login from './../components/Login/Login';
import NoRouteFound from './../components/NoRouteFound';
import ResetPassword from './../components/ResetPassword/ResetPassword';
import Dashboard from './../components/Admin/Dashboard/Dashboard';
import UserList from './../components/User/List';
import ErrorReport from '../components/ErrorReport/List';
import InvoiceHistory from './../components/InvoiceHistory/List';
import OfferAddEdit from '../components/Admin/Offer/AddEdit';
import Overview from '../components/Profile/Overview';
import UserDetail from './../components/User/Detail';
import PaymentHistory from '../components/Admin/Payment/List';
import PolicyAddEdit from '../components/Admin/Policy/AddEdit';
import PlanAddEdit from '../components/Admin/Plan/AddEdit';
import QuestionAddEdit from '../components/Admin/Question/AddEdit';
import PolicyList from '../components/Admin/Policy/List';
import NewsListAdmin from '../components/Admin/News/List';
import NewsAddEdit from '../components/Admin/News/AddEdit';
import NewsDetail from '../components/Admin/News/Detail';
import PolicyDetail from '../components/Admin/Policy/Detail';
import OfferList from './../components/Admin/Offer/List';
import PlanList from './../components/Admin/Plan/List';
import ManagePlan from './../components/Admin/Plan/ManageFeature';
import OfferDetail from './../components/Admin/Offer/Detail';
import CarrierDetail from './../components/Admin/Carrier/Detail';
import OuestionList from '../components/Admin/Question/List';
import CarrierList from '../components/Admin/Carrier/List';
import ContactUs from '../components/CMS/ContactUs';
import AboutUs from '../components/CMS/AboutUs';
import Home from './../components/Home/Home';
import PageVideo from '../components/CMS/PageVideo';

// Containers
const DefaultLayout = React.lazy(() => import('./../layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./../layout/AuthLayout'));
const CmsLayout = React.lazy(() => import('./../layout/CmsLayout'));

const AdminRoute = () => {
    return (
        <Routes>
            {/* LoggedIn Routes Start */}
            <Route exact path="/admin/" element={<AuthLayout />} >
                <Route index element={<Dashboard />} />
                <Route path={appRoutes.adminQuestionListRoute} element={<OuestionList />} />
                <Route path={appRoutes.adminOfferFormRoute + "/:id?"} element={<OfferAddEdit />} />
                <Route path={appRoutes.adminUserDetailRoute+ "/:userId"} element={<UserDetail />} />
                <Route path={appRoutes.adminOfferDetailRoute+ "/:id"} element={<OfferDetail />} />
                <Route path={appRoutes.adminPaymentHistoryRoute} element={<PaymentHistory />} />
                <Route path={appRoutes.adminProfileRoute} element={<Overview />} />
                <Route path={appRoutes.adminDashboardRoute} element={<Dashboard />} />
                <Route path={appRoutes.adminPolicyAddEditRoute + "/:id?"} element={<PolicyAddEdit />} />
                <Route path={appRoutes.adminPlanFormRoute + "/:id?"} element={<PlanAddEdit />} />
                <Route path={appRoutes.adminPolicyDetailRoute+ "/:id"} element={<PolicyDetail />} />
                <Route path={appRoutes.adminQuestionAddEditRoute + "/:id?"} element={<QuestionAddEdit />} />
                <Route path={appRoutes.adminPolicyListRoute} element={<PolicyList />} />
                <Route path={appRoutes.adminPlanListRoute} element={<PlanList />} />
                <Route path={appRoutes.adminManagePlanRoute + "/:id"} element={<ManagePlan />} />
                <Route path={appRoutes.adminOfferListRoute} element={<OfferList />} />
                <Route path={appRoutes.adminCarrierListRoute} element={<CarrierList />} />
                <Route path={appRoutes.adminCarrierDetailRoute+ "/:id"} element={<CarrierDetail />} />
                <Route path={appRoutes.adminUserListRoute} element={<UserList />} />
                <Route path={appRoutes.adminNewsListRoute} element={<NewsListAdmin />} />
                <Route path={appRoutes.adminNewsAddEditRoute + "/:id?"} element={<NewsAddEdit />} />
                <Route path={appRoutes.adminNewsDetailRoute + "/:id?"} element={<NewsDetail />} />

                <Route path={appRoutes.adminErrorReportRoute} element={<ErrorReport />} />
                <Route path={appRoutes.adminInvoiceHistoryRoute + "/:customerId"} element={<InvoiceHistory />} />
                <Route path={appRoutes.adminPageVideoRoute} element={<PageVideo />} />
            </Route>
            {/* LoggedIn Routes End */}

            {/* Without Logged In Routes Start */}
            <Route exact path="/admin/" element={<DefaultLayout />} >
                <Route exact path={appRoutes.adminLoginRoute} element={<Login />} />
                <Route exact path={appRoutes.adminForgotPasswordRoute} element={<ForgotPassword />} />
                <Route exact path={appRoutes.adminResetPasswordRoute + "/:token"} element={<ResetPassword />} />
            </Route>
            {/* Without Logged In Routes Start */}


            <Route exact path="/" element={<CmsLayout />} >
                <Route exact index path={appRoutes.homePageRoute} element={<Home />} />
                <Route exact path={appRoutes.contactUsRoute} element={<ContactUs />} />
                <Route exact path={appRoutes.aboutUsRoute} element={<AboutUs />} />
            </Route>

            <Route path='*' element={<NoRouteFound />} />


        </Routes>

    );
};

export default AdminRoute;
