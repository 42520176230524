import React from 'react';
import { Helmet } from 'react-helmet';

const AboutUs = () => {

  return (
    <>
      <Helmet>
        <title>About iQuote</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <section className='inner-banner mb-0'>
        <div className='container'>
          <h1 className='text-center text-white fs-2hx'>About Us</h1>
        </div>
      </section>
      <section className="video-sec">
        <div className="container">
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 text-center'>
              <h2>Welcome to iQuote</h2>
              <p className='mt-6'>At iQuote, we believe in empowering insurance professionals with the most advanced and efficient tools to enhance their business. Our AI-powered insurance quoting engine is not just a tool; it's a game-changer. Let's dive into what makes iQuote the most powerful revenue-generating machine ever released.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-1.png`} alt="Image 1" className="row-img mw-100" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">It Starts With the Setup...</h2>
                  <p className="row-text">When your prospects share their contact information, iQuote takes the lead in setting up multiple opportunities for you to showcase the best products. Controlled by you, the product list on the first screen sets up every prospect as a multiple sale opportunity for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-2.png`} alt="Image 2" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Data Collection Made Easy!</h2>
                  <p className="row-text">Our dynamic data collection form, powered by artificial intelligence, makes obtaining information from your prospect a breeze. With a maximum of four questions asked at a time, the form adapts to your prospect's needs, saving all entered data instantly. Even if they leave halfway through, you retain all the information collected.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-3.png`} alt="Image 3" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Instant Price Quote Available for Selected Products</h2>
                  <p className="row-text">With partnerships with over 120 carriers, iQuote can provide instant price ranges for the products your prospects are interested in. These quotes are securely stored in our database, easily accessible whenever you need them. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-4.png`} alt="Image 4" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Referrals, Referrals, Referrals!</h2>
                  <p className="row-text">The iQuote Offers feature allows you to guide your prospects towards additional opportunities after they've filled in the insurance form. From home security systems to refinancing home loans, we make it easy for your prospects to express their interests, giving you the chance to send referrals to your trusted partners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-5.png`} alt="Image 5" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">iQuote Anywhere</h2>
                  <p className="row-text">Take iQuote wherever you go! Embed the iQuote Anywhere Form on your website, share it on social media, or even include it on your business card. Your clients can access it anytime, anywhere.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-7.png`} alt="Image 6" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Build Up Your Reputation</h2>
                  <p className="row-text">At the end of the iQuote Anywhere Form, clients can leave a review on your ReviewMyInsuranceAgent profile, boosting your online presence effortlessly. The integration with ReviewMyInsuranceAgent allows easy sharing of reviews on Facebook and Google My Business.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-6.png`} alt="Image 7" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Lead to Appointment: We Make it Easy</h2>
                  <p className="row-text">Simplify the appointment-setting process by integrating your iQuote Anywhere Form with your calendar link. After form submission, prospects are prompted to schedule a call with you for the next steps, seamlessly connecting them with your online booking system.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
    </>
  );
};

export default AboutUs;
