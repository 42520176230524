import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../../apis/apiUtils';
import appRoutes from '../../../configs/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Chart from 'react-apexcharts';
import Helper from '../../../apis/Helper';


const Dashboard = () => {
    const { isLoggedIn, isAdmin } = useSelector((state) => state.common);
    const [dashboard, setDashboard] = useState({ userCount: 0, offerCount: 0 });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf('year').subtract(1, 'year'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    
    const [chart, setChart] = useState({
        options: {
            chart: {
                id: "basic-bar",
                height: '100%',
                width: '100%',
            },
            xaxis: {
                categories: [], // For Year And Months
            }
        },
        series: [{
            name: 'Number of lead',
            data: [] // For Data Use Only
        }]
    });


    useEffect(() => {
        if (isLoggedIn && isAdmin) {
            getDashboard(new Date(startDate), new Date(endDate));
        }
    }, []);


    const handleDateChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        if (startDate !== null && endDate !== null) {
            getDashboard(new Date(startDate), new Date(endDate));
        }
    };


    /** Get Dashboard Data **/
    const getDashboard = async (start_date, end_date) => {
        try {
            start_date = moment(start_date).format('YYYY-MM-DD');
            end_date = moment(end_date).format('YYYY-MM-DD');
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Dashboards', 'Default'] }));
            const res = await sendRequest(`/admin/dashboard`, 'GET', { start_date, end_date });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setDashboard({ userCount: res.data.data.userCount, offerCount: res.data.data.offerCount });
                setChart(prevState => ({
                    options: {
                        ...prevState.options,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: res.data.data.chartData.allMonths,
                        },
                    },
                    series: [{
                        name: 'Number of lead',
                        data: res.data.data.chartData.allData,
                    }],
                }));
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Dashboard</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}

                {/**end::Container**/}

                {/* new container start here */}
                <div id="kt_content_container" className="container-xxl">
                    <div className="row g-5 g-xl-10">
                        <div className="col-xl-4">
                            {/**begin::Lists Widget 19**/}
                            <div className="card card-flush h-xl-100">
                                {/**begin::Heading**/}
                                <div className="stats-head card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                                    {/**begin::Title**/}
                                    <h3 className="card-title align-items-start flex-column justify-content-center text-white pt-15 w-100">
                                        <span className="fw-bold fs-2x mb-3 text-center w-100">Statics</span>

                                    </h3>
                                    {/**end::Title**/}
                                    {/**begin::Toolbar**/}

                                    {/**end::Toolbar**/}
                                </div>
                                {/**end::Heading**/}
                                {/**begin::Body**/}
                                <div className="card-body mt-n20">
                                    {/**begin::Stats**/}
                                    <div className="mt-n20 position-relative">
                                        {/**begin::Row**/}
                                        <div className="row g-3 g-lg-6">
                                            {/**begin::Col**/}
                                            <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminUserListRoute)} >
                                                {/**begin::Items**/}
                                                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                    {/**begin::Symbol**/}
                                                    <div className="symbol symbol-30px me-5 mb-8">
                                                        <span className="symbol-label">
                                                            <i className="ki-duotone ki-profile-user fs-1 text-primary">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                                <span className="path4"></span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    {/**end::Symbol**/}
                                                    {/**begin::Stats**/}
                                                    <div className="m-0">
                                                        {/**begin::Number**/}
                                                        <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{dashboard.userCount}</span>
                                                        {/**end::Number**/}
                                                        {/**begin::Desc**/}
                                                        <span className="text-gray-500 fw-semibold fs-6">Number Of Agents</span>
                                                        {/**end::Desc**/}
                                                    </div>
                                                    {/**end::Stats**/}
                                                </div>
                                                {/**end::Items**/}
                                            </div>
                                            {/**end::Col**/}
                                            {/**begin::Col**/}
                                            <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminOfferListRoute)}>
                                                {/**begin::Items**/}
                                                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                    {/**begin::Symbol**/}
                                                    <div className="symbol symbol-30px me-5 mb-8">
                                                        <span className="symbol-label">
                                                            <i className="ki-duotone ki-discount text-primary fs-1">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    {/**end::Symbol**/}
                                                    {/**begin::Stats**/}
                                                    <div className="m-0">
                                                        {/**begin::Number**/}
                                                        <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{dashboard.offerCount}</span>
                                                        {/**end::Number**/}
                                                        {/**begin::Desc**/}
                                                        <span className="text-gray-500 fw-semibold fs-6"  >Number of Offers</span>
                                                        {/**end::Desc**/}
                                                    </div>
                                                    {/**end::Stats**/}
                                                </div>
                                                {/**end::Items**/}
                                            </div>
                                            {/**end::Col**/}
                                            {/**begin::Col**/}
                                            <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminNewsListRoute)}>
                                                {/**begin::Items**/}
                                                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                    {/**begin::Symbol**/}
                                                    <div className="symbol symbol-30px me-5 mb-8">
                                                        <span className="symbol-label">
                                                            <i className="ki-duotone ki-award fs-1 text-primary">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    {/**end::Symbol**/}
                                                    {/**begin::Stats**/}
                                                    <div className="m-0">
                                                        {/**begin::Number**/}
                                                        <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">News</span>
                                                        {/**end::Number**/}
                                                        {/**begin::Desc**/}
                                                        <span className="text-gray-500 fw-semibold fs-6">Management</span>
                                                        {/**end::Desc**/}
                                                    </div>
                                                    {/**end::Stats**/}
                                                </div>
                                                {/**end::Items**/}
                                            </div>
                                            {/**end::Col**/}
                                            {/**begin::Col**/}
                                            <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminErrorReportRoute)}>
                                                {/**begin::Items**/}
                                                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                    {/**begin::Symbol**/}
                                                    <div className="symbol symbol-30px me-5 mb-8">
                                                        <span className="symbol-label">
                                                            <i className="ki-duotone ki-information-5 text-primary fs-1">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    {/**end::Symbol**/}
                                                    {/**begin::Stats**/}
                                                    <div className="m-0">
                                                        {/**begin::Number**/}
                                                        <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">Error</span>
                                                        {/**end::Number**/}
                                                        {/**begin::Desc**/}
                                                        <span className="text-gray-500 fw-semibold fs-6">Logs</span>
                                                        {/**end::Desc**/}
                                                    </div>
                                                    {/**end::Stats**/}
                                                </div>
                                                {/**end::Items**/}
                                            </div>
                                            {/**end::Col**/}
                                        </div>
                                        {/**end::Row**/}
                                    </div>
                                    {/**end::Stats**/}
                                </div>
                                {/**end::Body**/}
                            </div>
                            {/**end::Lists Widget 19**/}
                        </div>

                        {/** chart start here**/}

                        <div className="col-xl-8">
                            {/**begin::Chart widget 18**/}
                            <div className="card card-flush h-xl-100">
                                {/**begin::Header**/}
                                <div className="card-header pt-7">
                                    {/**begin::Title**/}
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-gray-800">Lead Stats</span>
                                        <span className="text-gray-500 mt-1 fw-semibold fs-6">Leads per month</span>
                                    </h3>
                                    {/**end::Title**/}
                                    {/**begin::Toolbar**/}
                                    <div className="card-toolbar">
                                        {/**begin::Daterangepicker(defined in src/js/layout/app.js)**/}
                                        <div className="btn btn-sm btn-light d-flex align-items-center px-4">
                                            {/**begin::Display range**/}
                                            <div className="text-gray-600 w-100 fw-bold fs-14 custom-date-pick">
                                                <DateRangePicker
                                                    startDate={startDate}
                                                    startDateId="start_date"
                                                    endDate={endDate}
                                                    endDateId="end_date"
                                                    onDatesChange={handleDateChange}
                                                    focusedInput={focusedInput}
                                                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                    isOutsideRange={() => false} // Allow all dates
                                                    displayFormat="MM/DD/YYYY"
                                                />
                                            </div>
                                            {/**end::Display range**/}

                                        </div>

                                        {/**end::Daterangepicker**/}
                                    </div>
                                    {/**end::Toolbar**/}
                                </div>
                                {/**end::Header**/}
                                {/**begin::Body**/}
                                <div className="card-body d-flex align-items-end px-0 pt-3 pb-5">
                                    {/**begin::Chart**/}
                                    <div id="kt_charts_widget_18_chart" className="h-325px w-100 min-h-auto ps-4 pe-6">
                                        <Chart options={chart.options} series={chart.series} type="bar" height={320} />                        
                                    </div>
                                    {/**end::Chart**/}
                                </div>
                                {/**end: Card Body**/}
                            </div>
                            {/**end::Chart widget 18**/}
                        </div>

                    </div>
                </div>

            </div>
            {/**end::Post**/}
        </div>
    );
};

export default Dashboard;
