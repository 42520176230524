
import STRINGS from '../../../common/strings/strings';

export function validateScopeFormData(scopeOfAggrementDetail, fieldName) {
	const errors = {};
	let formValidCheck = true;
  
	// Validation rules based on fieldDefinitions
	for (const field in scopeOfAggrementDetail) {
	  if (!scopeOfAggrementDetail[field] && (fieldName === field || fieldName === '')) {
		if(field != 'BeneficiaryOrAuthorizedSignature'){
			errors[field] = STRINGS.fieldReq;
			formValidCheck = false;
		}
	  }
	}
  
	return { formValidCheck, errors };
}