import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../apis/APIs';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../apis/apiUtils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import BackButton from '../../common/BackButton';
import Alerts from '../../common/Alerts/Alerts';
import axios from 'axios';

const ManageCrm = () => {

    const { contentLoading, loading2, alert, leadboostLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [errorsInfo, setErrorsInfo] = useState({ api_key: '', webhook_url: '' });
    const [userSetting, setUserSetting] = useState({});
    const [userSubscription, setUserSubscription] = useState({});


    useEffect(() => {
        getUserSetting();
    }, []);


    /***** Get Api Key Data ***/
    const getUserSetting = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Crm', 'Settings'] }));
            const res = await sendRequest('/user/crm-setting', 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUserSetting(res.data.data);
                setUserSubscription(res.data.subscription);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /***** Update Api Key Data ***/
    const updateApiKeyData = async (e) => {
        e.preventDefault();
        if (checkFormIsValid(userSetting, "")) {
            try {
                dispatch(callCommonAction({ contentLoading: true }));
                const res = await sendRequest('/user/crm-setting', 'POST', userSetting);
                dispatch(callCommonAction({ contentLoading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    setUserSetting(res.data.data);
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ user: res.data.data }));
                }
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ contentLoading: false }));
            }
        }
    };




    /************Connect LeadBoost************ */
    const connectToLeadboost = async (e) => {
        e.preventDefault();
        try {
            dispatch(callCommonAction({ leadboostLoading: true }));
            const res = await sendRequest('/user/connect-leadboost', 'POST');
            if (res.data.type === "success") {
                handleApiResponse(res);
                setUserSetting(res.data.data);
                dispatch(callCommonAction({ leadboostLoading: false }));
            } else {
                const custom = Alerts.confirmationPopupLeadBoost(userSetting.email, 'Are you sure, you want to create a new user in leadboost?', createUserToLeadboost);
                dispatch(callCommonAction({ alert: custom, leadboostLoading: false }));
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ leadboostLoading: false }));
        }
    };



    /*********After confirmation delete data*********/
    const createUserToLeadboost = async (email) => {
        dispatch(callCommonAction({ alert: null }));
        if (email) {
            try {
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest(`/user/create-leadboost`, 'POST', { email: email });
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getUserSetting();
                }
            } catch (error) {
                dispatch(callCommonAction({ loading: false }));
                toast.error('Something went wrong');
            }
        }
    };

    // Function to handle toggle for each notification type (01-01-2024)
    const handleToggle = async (type, value) => {
        setUserSetting((prev) => ({
            ...prev,
            notification_settings: {
                ...prev.notification_settings,
                [type]: !value
            }
        }));
    };

    /** Handle Input Change Manage Heere */
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setUserSetting((prev) => ({
            ...prev,
            crm_settings: {
                ...prev.crm_settings,
                [name]: value
            }
        }));

        checkFormIsValid({ ...userSetting, crm_settings: { ...userSetting.crm_settings, [name]: value } }, name);
    };

    const handleChangeLeadboostSetting = async (e) => {
        const { name, checked } = e.target;
        const payloadData = { ...userSetting };
        payloadData.leadboost_settings[name] = checked;
        setUserSetting(payloadData);
        try {
            const res = await sendRequest('/user/update-leadboost-setting', 'POST', payloadData);
            handleApiResponse(res);
            if (res.data.type === "success") {
                getUserSetting();
            }
        } catch (error) {
            console.log(checked,'error setting', error);
            toast.error('Something went wrong');
            dispatch(callCommonAction({ contentLoading: false }));
        }
    };

    /** Validate Form For CRM Setting ****/
    const checkFormIsValid = (userSetting, fieldName) => {
        const { crm_settings } = userSetting;
        let errorMsg = ''; let errors = {}; let formVaildCheck = true;
        if ((fieldName == 'api_key' || fieldName == '') && (!crm_settings.api_key)) {
            errorMsg = !crm_settings.api_key ? 'This field is required' : '';
            formVaildCheck = false;
            errors.api_key = errorMsg;
        }
        setErrorsInfo(errors);
        return formVaildCheck;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserSetting((prevData) => ({ ...prevData, [name]: value }));
        setErrorsInfo((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear error on change
    };

    const saveWebhookForm = async (e) => {
        e.preventDefault();
        if (!userSetting.webhook_url) {
            setErrorsInfo((prevErrors) => ({ ...prevErrors, webhook_url: 'Webhook URL is required.' }));
        } else if (!/^(http|https):\/\/[^\s]+/.test(userSetting.webhook_url)) {
            setErrorsInfo((prevErrors) => ({ ...prevErrors, webhook_url: 'Invalid Webhook URL format. Must start with http:// or https://' }));
        } else {
            try {
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest(`/user/update-webhook-url`, 'POST', userSetting);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getUserSetting();
                }
            } catch (error) {
                dispatch(callCommonAction({ loading: false }));
                toast.error('Something went wrong');
            }
        }
    };


    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {/* {console.log(userSetting, 'userSetting')} */}
            <Helmet>
                <title>iQuote | Crm Management</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {alert}
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header align-content-center">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>Agent CRM/GoHighLevel Settings</h4>
                            </div>
                            <div className="">
                                <BackButton marginCustom={0} />
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body">
                            <form onSubmit={updateApiKeyData}>
                                <div className="row">

                                    <div className='col-12'>
                                        <h5 className='mb-5'>Need help finding your API Key? Here's a quick video to show you where to find it in your account</h5>
                                        <iframe height={100} className='api-video mb-5' src="https://www.loom.com/embed/d012be0a6e834b74908f3c791613481b?sid=26b50bb1-e8e8-47b8-94a1-190dfb5bfa66" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                        <hr></hr>
                                    </div>

                                    <div className="col-12 col-md-8">
                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <h5 className="d-flex mt-2">Api Key</h5> {userSubscription?.plan?.unit_amount == 0 ? 'To active this options please upgrade your plan' : ''}
                                            </label>
                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                <input disabled={userSubscription?.plan?.unit_amount == 0 ? true : false} type="text" onChange={handleChangeInput} value={userSetting?.crm_settings?.api_key} className={`form-control form-control-solid ${errorsInfo.api_key ? 'is-invalid' : ''}`} name="api_key" />
                                            </div>

                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.api_key}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-1"></div>

                                    <div className="col-12 col-md-3 switch-col">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            <span className=""></span>
                                        </label>
                                        <div className="fv-row mt-8">
                                            <Form.Check
                                                type="switch"
                                                id="enableUploadToCrm"
                                                disabled={userSubscription?.plan?.unit_amount == 0 ? true : false}
                                                checked={userSetting?.notification_settings?.enableUploadToCrm || false}
                                                onChange={() => handleToggle('enableUploadToCrm', userSetting?.notification_settings?.enableUploadToCrm || false)}
                                                label="Enable Upload To CRM"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" disabled={userSubscription?.plan?.unit_amount == 0 ? true : false} className='btn btn-primary btn-sm'>{
                                            contentLoading
                                                ?
                                                <span className="indicator-progress" style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                                :
                                                <span className="indicator-label">Save</span>
                                        }</button>
                                    </div>


                                </div>
                            </form>

                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                    <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className={`card mt-2 h-100`}>
                        <div className='card-body connect-button-body'>
                            <form onSubmit={saveWebhookForm}>
                                <div className="row">
                                    <div className='col-lg-12 mt-4'>
                                        <h4 className='mb-5'>Setting up Webhooks for Data Transfer</h4>
                                        <div className='d-flex'>
                                            <input
                                                type="text"
                                                name="webhook_url"
                                                className={`form-control  me-2 ${errorsInfo.webhook_url ? 'is-invalid' : ''}`}
                                                value={userSetting.webhook_url}
                                                onChange={handleChange}
                                                aria-describedby="webhookUrlHelp"
                                            />
                                            <button type="submit" className='btn btn-primary btn-sm' style={{ width: 100 }} disabled={loading2 ? true : false}>{
                                                loading2
                                                    ?
                                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                    :
                                                    <span className="indicator-label">Save</span>
                                            }</button>


                                        </div>
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{errorsInfo.webhook_url}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card mt-2 h-100">
                        <div className='card-body connect-button-body'>
                            {userSetting?.leadboost_settings?.leadboost_connectivity === false || userSetting?.leadboost_settings?.leadboost_connectivity === undefined ?
                                <form onSubmit={connectToLeadboost}>
                                    <div className="row">
                                        <div className='col-12 mt-4 text-center'>
                                            <h4 className='mb-5'>LeadBoost Conectivity</h4>

                                            <button type="submit" className='btn btn-primary btn-sm' disabled={leadboostLoading ? true : false}>{
                                                leadboostLoading
                                                    ?
                                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                    :
                                                    <span className="indicator-label">Connect to LeadBoost</span>
                                            }</button>
                                        </div>
                                    </div>
                                </form>
                                :

                                <div className="row">

                                    <h4 className="mt-4">Leadboost Setting</h4>
                                    <div className="fv-row mt-8">
                                        <div className="form-check form-switch">
                                            <input type="checkbox" id="enrichWithInsurance" name="enrich_with_insurance" onChange={handleChangeLeadboostSetting} checked={userSetting?.leadboost_settings?.enrich_with_insurance} value="true" className="form-check-input" />
                                            <label title="" for="enrichWithInsurance" className="form-check-label">LeadBoost: Enrich with Insurance Information</label>
                                        </div>
                                    </div>
                                    <div className="fv-row mt-8">

                                        <div className="form-check form-switch">
                                            <input type="checkbox" id="enrichWithIndividual" name="enrich_with_individual" onChange={handleChangeLeadboostSetting} checked={userSetting?.leadboost_settings?.enrich_with_individual} value="true" className="form-check-input" />
                                            <label title="" for="enrichWithIndividual" className="form-check-label">LeadBoost: Enrich with Individual Insights</label>
                                        </div>
                                    </div>

                                    <div className="fv-row mt-8">
                                        <div className="form-check form-switch">
                                            <input type="checkbox" id="enrichWithDnc" name="enrich_with_dnc" onChange={handleChangeLeadboostSetting} className="form-check-input" checked={userSetting?.leadboost_settings?.enrich_with_dnc} value="true" />
                                            <label title="" for="enrichWithDnc" className="form-check-label">LeadBoost: Enrich with DNC data</label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </div>

                </div>
            </div>
            {/**End::Post**/}
        </div>
    );
};

export default ManageCrm;
