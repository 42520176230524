import React from 'react';
import appRoutes from './../configs/AppRoutes';
import { Routes, Route } from 'react-router-dom';
import ForgotPassword from './../components/ForgotPassword/ForgotPassword';
import Login from './../components/Login/Login';
import Home from './../components/Home/Home';
import NoRouteFound from './../components/NoRouteFound';
import Register from './../components/Register/Register';
import ResetPassword from './../components/ResetPassword/ResetPassword';
import Dashboard from './../components/User/Dashboard/Dashboard';
import ErrorReport from '../components/ErrorReport/List';
import InvoiceHistory from './../components/InvoiceHistory/List';
import Overview from './../components/Profile/Overview';
import MultiStepForm from '../components/MultiForm/MultiForm';
import ManageCarrier from '../components/User/ManageCarrier';
import ManageOffer from '../components/User/ManageOffer';
import ManagePolicy from '../components/User/ManagePolicy';
import LeadList from '../components/User/Lead/List';
import LeadDetail from '../components/User/Lead/Detail';
import NewsDetail from '../components/Admin/News/Detail';
import PaymentHistory from '../components/User/Payment/List';
import ManageCrm from '../components/User/ManageCrm';
import ManagePlan from '../components/User/ManagePlan';
import AccountSignUp from '../components/User/AccountSignUp';
import ContactUs from '../components/CMS/ContactUs';
import AboutUs from '../components/CMS/AboutUs';
import Scope from '../components/User/Aggrement/Scope';
import EmbeddableFormContent from '../components/User/EmbeddableFormContent/AddEdit';

// Containers
const DefaultLayout = React.lazy(() => import('./../layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./../layout/AuthLayout'));
const CmsLayout = React.lazy(() => import('./../layout/CmsLayout'));

const UserRoute = () => {
    return (
        <Routes>
            {/* LoggedIn Routes Start */}
            <Route exact path="/user/" element={<AuthLayout />} >
                <Route index element={<Dashboard />} />
                <Route path={appRoutes.dashboardRoute} element={<Dashboard />} />
                <Route path={appRoutes.profileRoute} element={<Overview />} />
                <Route path={appRoutes.errorReportRoute} element={<ErrorReport />} />
                <Route path={appRoutes.invoiceHistoryRoute + "/:customerId"} element={<InvoiceHistory />} />
                <Route path={appRoutes.carrierManagmentRoute} element={<ManageCarrier />} />
                <Route path={appRoutes.leadListRoute} element={<LeadList />} />
                <Route path={appRoutes.leadDetailRoute + "/:id"} element={<LeadDetail />} />
                <Route path={appRoutes.newsDetailRoute + "/:id"} element={<NewsDetail />} />
                <Route path={appRoutes.offerManagmentRoute} element={<ManageOffer />} />
                <Route path={appRoutes.policyManagmentRoute} element={<ManagePolicy />} />
                <Route path={appRoutes.crmSettingsRoute} element={<ManageCrm />} />
                <Route path={appRoutes.paymentHistoryRoute} element={<PaymentHistory />} />
                <Route path={appRoutes.planManagmentRoute} element={<ManagePlan />} />
                <Route exact path={appRoutes.embeddableForm + "/:id?"} element={<MultiStepForm />} />
                <Route exact path={appRoutes.scopeRoute + "/:id?"} element={<Scope />} />
                <Route path={appRoutes.embeddableFormContentRoute} element={<EmbeddableFormContent />} />
            </Route>
            {/* LoggedIn Routes End */}

            {/* Without Logged In Routes Start */}
            <Route exact path="/user/" element={<DefaultLayout />} >
                <Route exact path={appRoutes.multiStepFormRoute + "/:id?"} element={<MultiStepForm />} />
                <Route exact path={appRoutes.loginRoute} element={<Login />} />
                <Route exact path={appRoutes.loginRoute} element={<Login />} />
                <Route exact path={appRoutes.registerRoute} element={<Register />} />
                <Route path={appRoutes.accountSignUpRoute} element={<AccountSignUp />} />
                <Route exact path={appRoutes.forgotPasswordRoute} element={<ForgotPassword />} />
                <Route exact path={appRoutes.resetPasswordRoute + "/:token"} element={<ResetPassword />} />
            </Route>
            {/* Without Logged In Routes Start */}

            <Route exact path={appRoutes.landingPageMultiStepFormRoute + "/:display_name"} element={<MultiStepForm />} />

            <Route exact path="/" element={<CmsLayout />} >
                <Route exact index path={appRoutes.homePageRoute} element={<Home />} />

                <Route exact path={appRoutes.contactUsRoute} element={<ContactUs />} />
                <Route exact path={appRoutes.aboutUsRoute} element={<AboutUs />} />
            </Route>

            <Route path='*' element={<NoRouteFound />} />

        </Routes>
    );
};

export default UserRoute;
