import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from 'react-phone-number-input/input';
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../configs/AppRoutes";
import { validateRegisterFormData } from "./Validation";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { handleApiResponse } from "../../apis/apiUtils";
import { Helmet } from "react-helmet";
import { GoogleLogin } from '@react-oauth/google';


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const Register = () => {
	const checkoutFormRef = useRef();
	const navigate = useNavigate();

	const [formStep, setFormStep] = useState(1);
	const [planList, setPlanList] = useState([]);
	const [userDetail, setUserDetail] = useState({ terms: false, phonenumber: "", role: 2, first_name: "", display_name: "", last_name: "", email: "", password: "", password_confirmation: "", price_id: '', amount: 0, plan_id: '' });
	const [errorsInfo, setErrorsInfo] = useState({});
	const { loading, loading2 } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [suggestions, setSuggestions] = useState([]);



	useEffect(() => {
		getPlanList();
	}, []);



	/** Click on of them suggestion and set in userdetail */
	const handleSuggestionClick = (selectedDisplayName) => {
		setUserDetail({ ...userDetail, display_name: selectedDisplayName });
		setSuggestions([]); // Clear suggestions after selecting one
	};

	/** On Blur Of Display Input Call This fun **/
	const handleBlurDisplay = async (e) => {
		const value = e.target.value;
		if (value) {
			try {
				const res = await sendRequest("/user/display-name-suggestions", "POST", { display_name: value });
				if (res.data.type === "success") {
					if (res.data.data.length) {
						setErrorsInfo({ ...errorsInfo, display_name: '' });
					}
					setSuggestions(res.data.data);
				}
			} catch (error) {
				toast.error("Error fetching suggestions");
			}
		}
	};

	/** Get Plan List For Manage **/
	const getPlanList = async () => {
		try {
			dispatch(callCommonAction({ loading: true, breadCrumb: ['Plan', 'List'] }));
			const res = await sendRequest('/plan/for-guest-user', 'GET', { page: 1, limit: 100000, search_string: '', status: 1 });
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setPlanList(res.data.data.docs);
			}
		} catch (error) {
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	/** OnChange Update Input Values */
	const handleChange = (e) => {
		userDetail[e.target.name] = e.target.value;
		if (e.target.name == 'display_name') {
			setSuggestions([]); // Clear suggestions after selecting one
		}
		setUserDetail(userDetail);
		checkFormIsValid(e.target.name);
	};
	const handleTermsChange = (e) => {
		userDetail["terms"] = e.target.checked;
		setUserDetail(userDetail);
		checkFormIsValid(e.target.name);
	};

	/** handlePhoneChange */
	const handlePhoneChange = (phonenumber) => {
		if (phonenumber) {
			userDetail.phonenumber = phonenumber;
			setUserDetail(userDetail);
			checkFormIsValid("phonenumber");
		}
	};


	/** Validation implemented here */
	const checkFormIsValid = async (fieldName) => {
		const res = validateRegisterFormData(formStep, userDetail, fieldName, isValidPhoneNumber);
		if (fieldName) {
			fieldName = fieldName.trim();
			let err = res.errors[fieldName];
			if (typeof err != "undefined") {
				errorsInfo[fieldName] = err;
			} else {
				errorsInfo[fieldName] = "";
			}
			setErrorsInfo({ ...errorsInfo });
		} else {
			setErrorsInfo({ ...res.errors });
		}

		return res.formVaildCheck;
	};

	/** Form Submit Functionality Here */
	const registerUser = async () => {
		if (await checkFormIsValid("") && suggestions.length === 0) {

			try {
				dispatch(callCommonAction({ loading: true }));
				let countForm = formStep;
				countForm = countForm + 1;
				let body = { ...userDetail };

				if (formStep === 2) {
					body.payment_method_id = '';
					if (body.amount > 0) {

						let paymentMethod = await checkoutFormRef.current.handleCardElement();  //Call stripe Api

						// console.log('paymentMethod', paymentMethod);

						if (paymentMethod && paymentMethod.id) {
							console.log(paymentMethod && paymentMethod.id);
							body.payment_method_id = paymentMethod.id;
						} else {

							dispatch(callCommonAction({ loading: false }));
							return false;
						}
					}
					setErrorsInfo((prev) => ({ ...prev, card: '' }));

					const res = await sendRequest(`/user/register`, "POST", body);
					// console.log('rrrrrrrrrrrrrrr', body);
					handleApiResponse(res);

					if (res.data.type === "success") {
						setFormStep(countForm);
					}
				} else {
					setFormStep(countForm);
				}
				dispatch(callCommonAction({ loading: false }));
			} catch (error) {
				toast.error('Something went wrong');
				dispatch(callCommonAction({ loading: false }));
			}

		}
	};

	const handlePlanChange = (plan) => {
		userDetail["price_id"] = plan.stripe_plan_id;
		userDetail["plan_id"] = plan._id;
		userDetail["amount"] = plan.unit_amount;
		setUserDetail(userDetail);
		checkFormIsValid("price_id");
	};

	const preStepCall = () => setFormStep(formStep - 1);


	const renderPlanList = () => {
		return planList.map((plan) => {

			return (
				<div className="col-xl-4 mb-10" key={plan._id}>
					{console.log(plan.stripe_plan_id, 'plan', userDetail.price_id)}
					<div className="plan-item">
						<div><img src={`${process.env.REACT_APP_FRONTEND}/assets/home/text-logo.png`} alt="" /></div>
						<h2>{plan.name}</h2>
						<div className="plan-price">
							<span>${parseFloat(plan.unit_amount).toFixed(2)}</span>/{plan.recurring_obj.interval_count > 1 && plan.recurring_obj.interval_count}
							{plan.recurring_obj.interval.charAt(0).toUpperCase() + plan.recurring_obj.interval.slice(1)}
						</div>
						<ul>
							{
								plan.plan_features.length > 0
									?
									plan.plan_features.map((feature) => {
										return <li key={feature._id}>{feature.name}</li>;
									})
									:
									null
							}
						</ul>
						{/* {console.log(plan, 'plan', userDetail)} */}



						<button
							type='button'
							onClick={() => handlePlanChange(plan)}
							className={`btn btn-lg  w-100 mb-5 select-plan-btn ${userDetail?.price_id && userDetail?.price_id === plan?.stripe_plan_id ? 'selected' : ''}`}
						>
							<span className="indicator-label">Select Plan</span>
						</button>


					</div>
				</div>
			);
		});
	};


	const decodeJWT = async (response) => {
		try {
			const googleAccessToken = response.credential;
			dispatch(callCommonAction({ loading2: true }));
			const res = await sendRequest('/user/register-by-google', 'POST', { token: googleAccessToken });
			dispatch(callCommonAction({ loading2: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				localStorage.setItem("token", res.data.access_token);
				localStorage.setItem('user', JSON.stringify(res.data.data));
				localStorage.setItem('isAdmin', res.data.data.role === 1);
				//window.location.reload();
				dispatch(callCommonAction({ isLoggedIn: true, user: res.data.data, token: res.data.access_token, isAdmin: res.data.data.role === 1 }));
			}
		} catch (error) {
			console.log('error',error);
			toast.error(error.message);
			dispatch(callCommonAction({ loading2: false }));
		}
	};

	return (
		<>

			{/* {console.log('errorMessage',errorsInfo)} */}
			<Helmet>
				<title>iQuote | Register Form</title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
			<form className="bg-white d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep last" id="kt_create_account_stepper" data-kt-stepper="true">
				<div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
					<div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y ggg bgi-size-cover bgi-position-center login-screen-bg">
						<div className="d-flex flex-center py-10 py-lg-20 mt-lg-20">
							<a href={undefined} onClick={() => navigate(appRoutes.homePageRoute)}>
								<img alt="Logo" src="/assets/home/logo.png" className="h-70px" />
							</a>
						</div>
						<div className="d-flex flex-row-fluid justify-content-center p-10">
							<div className="stepper-nav">
								<div className={`stepper-item ${formStep === 1 ? 'current' : ''}  ${formStep > 1 ? 'completed' : ''} `} data-kt-stepper-element="nav">
									<div className="stepper-wrapper">
										<div className="stepper-icon rounded-3">
											<i className="ki-duotone ki-check fs-2 stepper-check" />
											<span className="stepper-number">1</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title fs-2">
												Account Info
											</h3>
											<div className="stepper-desc fw-normal">
												Setup your account settings
											</div>
										</div>
									</div>
									<div className="stepper-line h-40px">
									</div>
								</div>
								<div className={`stepper-item ${formStep === 2 ? 'current' : ''}  ${formStep > 2 ? 'completed' : ''} `} data-kt-stepper-element="nav">
									<div className="stepper-wrapper">
										<div className="stepper-icon">
											<i className="ki-duotone ki-check fs-2 stepper-check" />
											<span className="stepper-number">2</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title ">
												Billing Details
											</h3>
											<div className="stepper-desc fw-normal">
												Provide your payment info
											</div>
										</div>
									</div>
									<div className="stepper-line h-40px">
									</div>
								</div>
								<div className={`stepper-item ${formStep === 3 ? 'current' : ''}`} data-kt-stepper-element="nav">
									<div className="stepper-wrapper">
										<div className="stepper-icon">
											<i className="ki-duotone ki-check fs-2 stepper-check" />
											<span className="stepper-number">3</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title ">
												Completed
											</h3>
											<div className="stepper-desc fw-normal">
												Your account is created
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex flex-center flex-wrap px-5 py-10">
							<div className="d-flex fw-normal">
								{/* <a href={undefined} className="text-white-50 px-5" target="_blank">Terms</a> */}
								<a href={undefined} onClick={() => navigate(appRoutes.homePageRoute, { state: { priceScrollViewReach: true } })} className="text-white-50 px-5" target="_blank">Plans</a>
								<a href={undefined} onClick={() => navigate(appRoutes.contactUsRoute)} className="text-white-50 px-5" target="_blank">Contact Us</a>
								<a href={undefined} onClick={() => navigate(appRoutes.loginRoute)} className="text-white-50 px-5" target="_blank">Login</a>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column flex-lg-row-fluid py-10">
					<div className="d-flex flex-center flex-column flex-column-fluid">
						<div className="w-lg-650px w-xl-1080px w-xxl-1600px p-10 p-lg-15 mx-auto">
							<div className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_create_account_form" data-select2-id="select2-data-kt_create_account_form">
								{/* Tab 1 START */}
								<div className={`${formStep === 1 ? 'current' : ''}`} data-kt-stepper-element="content">
									<div className="w-100">
										<div className="pb-10 pb-lg-15">
											<h2 className="fw-bold text-gray-900">Account Info</h2>
											{/* <div className="text-muted fw-semibold fs-6">
												If you need more info, please check out
												<a href="#" className="link-primary fw-bold">Help Page</a>.
											</div> */}
										</div>
										<div className="row">
											<div className="fv-row mb-8 col">
												<label className="form-label fw-bold text-gray-900 fs-6">First Name</label>
												<input
													placeholder="Enter first name"
													type="text"
													autoComplete="off"
													name="first_name"
													className={`form-control bg-transparent ${errorsInfo.first_name ? 'is-invalid' : ''}`}
													onChange={handleChange}
													value={userDetail.first_name}
												/>
												<div className="fv-plugins-message-container">
													<div className="fv-help-block"><span role="alert" />{errorsInfo.first_name}</div>
												</div>
											</div>
											<div className="fv-row mb-8 col">
												<label className="form-label fw-bold text-gray-900 fs-6">Last Name</label>
												<input
													placeholder="Enter last name"
													type="text"
													autoComplete="off"
													name="last_name"
													className={`form-control bg-transparent ${errorsInfo.last_name ? 'is-invalid' : ''}`}
													onChange={handleChange}
													value={userDetail.last_name}
												/>
												<div className="fv-plugins-message-container">
													<div className="fv-help-block"><span role="alert" />{errorsInfo.last_name}</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="fv-row mb-8 col">
												<label className="form-label fw-bold text-gray-900 fs-6">Display Name</label>
												<input
													placeholder="Enter display name"
													type="text"
													autoComplete="off"
													name="display_name"
													className={`form-control bg-transparent ${errorsInfo.display_name || suggestions.length ? 'is-invalid' : ''}`}
													onChange={handleChange}
													onBlur={handleBlurDisplay}
													value={userDetail.display_name}
												/>
												{userDetail.display_name && suggestions.length ?
													<div className="text-danger mt-1">
														<div className="d-flex">
															<i class="ki-duotone ki-cross me-1 fs-2 text-danger">
																<span class="path1"></span>
																<span class="path2"></span>
															</i>
															Not available.
														</div>
														{suggestions.map((suggestion, index) => (
															<div key={index} onClick={() => handleSuggestionClick(suggestion.display_name)} className="ps-7 text-success">
																Try:{" "}
																<span className="cursor-pointer">
																	{suggestion.display_name}
																</span>
															</div>
														))}
													</div>
													: null}
												<div className="fv-plugins-message-container">
													<div className="fv-help-block"><span role="alert" />{errorsInfo.display_name}</div>
												</div>
											</div>
											<div className="fv-row mb-8 col">
												<label className="form-label fw-bold text-gray-900 fs-6">Phone Number</label>
												<Input
													country="US"
													placeholder="Phone Number"
													className={`form-control bg-transparent ${errorsInfo.phonenumber ? 'is-invalid' : ''}`}
													onChange={(e) => handlePhoneChange(e)}
													value={userDetail.phonenumber}
												/>
												<div className="fv-plugins-message-container">
													<div className="fv-help-block"><span role="alert" />{errorsInfo.phonenumber}</div>
												</div>
											</div>
										</div>
										<div className="fv-row mb-8 ">
											<label className="form-label fw-bold text-gray-900 fs-6">Email</label>
											<input
												placeholder="Email"
												type="email"
												autoComplete="off"
												name="email"
												className={`form-control bg-transparent ${errorsInfo.email ? 'is-invalid' : ''}`}
												onChange={handleChange}
												value={userDetail.email}
											/>
											<div className="fv-plugins-message-container">
												<div className="fv-help-block"><span role="alert" />{errorsInfo.email}</div>
											</div>
										</div>
										<div className="row">
											<div className="fv-row mb-8 col" data-kt-password-meter="true">
												<div className="mb-1">
													<label className="form-label fw-bold text-gray-900 fs-6">Password</label>
													<div className="position-relative mb-3">
														<input
															type="password"
															placeholder="Password"
															autoComplete="off"
															name="password"
															className={`form-control bg-transparent ${errorsInfo.password ? 'is-invalid' : ''}`}
															onChange={handleChange}
															value={userDetail.password}
														/>
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																<span role="alert">{errorsInfo.password}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="fv-row mb-5 col">
												<label className="form-label fw-bold text-gray-900 fs-6">Confirm Password</label>
												<input
													type="password"
													placeholder="Confirm Password"
													autoComplete="off"
													name="password_confirmation"
													className={`form-control bg-transparent ${errorsInfo.password_confirmation ? 'is-invalid' : ''}`}
													onChange={handleChange}
													value={userDetail.password_confirmation}
												/>
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														<span role="alert">{errorsInfo.password_confirmation}</span>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
								{/* Tab 1 END */}

								{/* Tab 2 START */}
								<div className={`${formStep === 2 ? 'current' : ''}`} data-kt-stepper-element="content">
									<div className="w-100" data-select2-id="select2-data-26-byp7">
										<div className="pb-10 pb-lg-15">
											<h2 className="fw-bold text-gray-900">Billing Details</h2>
											<div className="text-muted fw-semibold fs-6">
												If you need more info, please check out
												<a href={undefined} onClick={() => { navigate(appRoutes.homePageRoute, { state: { priceScrollViewReach: true } }); }} className="text-primary fw-bold"> Price Page</a>.
											</div>
										</div>
										<div className="mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
											<label className="d-flex align-items-center form-label mb-5">
												Select Account Plan
												<span className="ms-1" data-bs-toggle="tooltip" aria-label="Monthly billing will be based on your account plan" data-bs-original-title="Monthly billing will be based on your account plan" data-kt-initialized={1}>
													<i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span> </label>
											{/* <div className="mb-0 choose-plan">
												{
													planList.map((plan) => {
														return (
															<label className="d-flex flex-stack mb-5 cursor-pointer">
																<span className="d-flex align-items-center me-2">
																	<span className="symbol symbol-50px me-6">
																		<span className="symbol-label">
																			<i class="ki-duotone ki-file-sheet fs-2x ">
																				<span class="path1"></span>
																				<span class="path2"></span>
																			</i> </span>
																	</span>
																	<span className="d-flex flex-column">
																		<span className="fw-bold text-gray-800 text-hover-primary fs-5">{plan.name}</span>
																		<span className="fs-6 fw-semibold text-muted">{plan.description}</span>
																	</span>
																</span>
																<div className="d-flex">
																	<span class="fs-3 me-5 text-primary">${parseFloat(plan.unit_amount).toFixed(2)}</span>
																	<span className="form-check form-check-custom form-check-solid">
																		<input onChange={(e) => handlePlanChange(plan)} className="form-check-input" type="radio" name="account_plan" defaultValue={1} />
																	</span>
																</div>
															</label>
														);
													})
												}

											</div> */}

											<section className="plan-section plan-sec-register" id="destination">
												<div className="container">
													<div className="row align-items-center justify-content-center align-items-lg-stretch plan-sec-row">
														{planList.length > 0 && renderPlanList()}
													</div>
												</div>
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														<span role="alert">{errorsInfo.price_id}</span>
													</div>
												</div>
											</section>
										</div>
										{
											userDetail.amount === 0
												?
												null
												:
												<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
													<div className="position-relative">
														<Elements stripe={stripePromise}>
															<CheckoutForm ref={checkoutFormRef} errorsInfo={errorsInfo} />
														</Elements>
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																<span role="alert">{errorsInfo.card}</span>
															</div>
														</div>
													</div>
													<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
													</div>
												</div>
										}
										<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
											<label className="form-check form-check-inline" >
												<input
													className={`form-check-input ${errorsInfo.terms ? 'is-invalid' : ''}`}
													type="checkbox"
													name="terms"
													value="false"
													onChange={handleTermsChange}
												/>
												<span>
													I Accept the{" "}
													<a href={undefined} target="_blank" className="ms-1 link-primary cursor-pointer">
														Terms
													</a>
													.
												</span>
											</label>
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{errorsInfo.terms}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Tab 2 END */}

								{/* Tab 3 START */}
								<div className={`${formStep === 3 ? 'current' : ''}`} data-kt-stepper-element="content">
									<table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" height="auto" style={{ borderCollapse: 'collapse' }}>
										<tbody>
											<tr>
												<td align="center" valign="center" style={{ textAlign: 'center', paddingBottom: '10px' }}>
													<div style={{ textAlign: 'center', margin: '0 60px 34px 60px' }}>
														<div style={{ marginBottom: '10px' }}>
															<a href={undefined} onClick={() => navigate(appRoutes.homePageRoute)} rel="noopener">
																<img alt="Logo" src="/Logo-iQuote-black.png" style={{ height: '35px' }} />
															</a>
														</div>
														<div style={{ marginBottom: '15px' }}>
															<img alt="Logo" src="/icon-positive-vote-3.svg" />
														</div>
														<div style={{ fontSize: '14px', fontWeight: 500, marginBottom: '42px', }}>
															<p style={{ marginBottom: '9px', color: '#181C32', fontSize: '22px', fontWeight: 700 }}>Hey {userDetail.first_name + ' ' + userDetail.last_name}, thanks for signing up!</p>
															<p style={{ marginBottom: '2px', color: '#7E8299' }}>To proceed to access your account and explore our platform further,</p>
															<p style={{ marginBottom: '2px', color: '#7E8299' }}>please click on Login button</p>
														</div>

														<a href={undefined} onClick={() => navigate(appRoutes.loginRoute)} className='btn btn-primary'>
															Login
														</a>
													</div>
												</td>
											</tr>
											{/* <tr>
												<td align="center" valign="center" style={{ fontSize: '13px', textAlign: 'center', padding: '0 10px 10px 10px', fontWeight: 500, color: '#A1A5B7', }}>
													<p style={{ color: '#181C32', fontSize: '16px', fontWeight: 600, marginBottom: '9px' }}>It’s all about customers!</p>
													<p style={{ marginBottom: '2px' }}>Call our customer care number: +31 6 3344 55 56</p>
													<p style={{ marginBottom: '4px' }}>You may reach us at <a href="#" rel="noopener" target="_blank" style={{ fontWeight: 600 }}>devs.keenthemes.com</a>.</p>
													<p>We serve Mon-Fri, 9AM-18AM</p>
												</td>
											</tr>
											<tr>
												<td align="center" valign="center" style={{ textAlign: 'center', paddingBottom: '20px' }}>
													<a href="#" style={{ marginRight: '10px' }}><img alt="Logo" src="/icon-linkedin.svg" /></a>
													<a href="#" style={{ marginRight: '10px' }}><img alt="Logo" src="/icon-facebook.svg" /></a>
													<a href="#"><img alt="Logo" src="/icon-twitter.svg" /></a>
												</td>
											</tr> */}
										</tbody>
									</table>
								</div>
								{/* Tab  END */}
								{
									formStep === 3
										?
										null
										:
										<div className="d-flex flex-stack pt-15">

											<div className="mr-2">
												{
													formStep !== 1 ?
														<button onClick={preStepCall} type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
															<i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1" /><span className="path2" /></i> Previous
														</button>
														:
														formStep == 1
															?
															<GoogleLogin
																onSuccess={decodeJWT}
																onError={(error) => {
																	console.log('Login Failed', error);
																}}
																useOneTap={true}
																theme={'filled_blue'}
																text={'signup_with'}
															/>
															:
															null
												}

											</div>

											<div className="d-flex">

												<button onClick={registerUser} type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={loading ? true : false}>
													{
														loading
															?
															<span className="indicator-progress" style={{ display: 'block' }}>
																Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
															:
															<>
																<span className="indicator-label">{formStep === 2 ? 'Submit' : 'Continue'}</span>
																<i className="ki-duotone ki-arrow-right fs-4 ms-1"><span className="path1" /><span className="path2" /></i>
															</>

													}
												</button>

											</div>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default Register;
