import STRINGS from '../../../common/strings/strings';

export function validateNewsFormData(data, fieldName) {
    const { firstScreenEditorContent, lastScreenEditorContent } = data; 
   
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    
    if (firstScreenEditorContent === '' ) {
        errorMsg = firstScreenEditorContent === '' ? STRINGS.product.descriptionReq : '';
        errors.firstScreenEditorContent = errorMsg;
        formVaildCheck = false;
    }

    if (lastScreenEditorContent === '' ) {
        errorMsg = lastScreenEditorContent === '' ? STRINGS.product.descriptionReq : '';
        errors.lastScreenEditorContent = errorMsg;
        formVaildCheck = false;
    }


    return { formVaildCheck: formVaildCheck, errors: errors };
}