const isImage = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
const isVideo = ['video/mpg', 'video/mp2', 'video/mpeg', 'video/mpe', 'video/mpv', 'video/mp4'];
const isAudio = ['audio/mpeg', 'audio/midi', 'audio/x-midi', 'audio/aac'];
const isFile = ['application/pdf', 'text/csv', 'text/plain'];
const stripeProduct = 'prod_P5xbi2Bwb7dl7J';

const productsForScope = [
    {
        id: '6669597dce1c8e310479c236',
        keyType: 1,
        type: 'Stand-alone Medicare Prescription Drug Plans(Part D)',
        checked: false
    },
    {
        id: '666959bace1c8e310479c23e',
        keyType: 2,
        type: 'Medicare Advantage Plans (Part C) and Cost Plans',
        checked: false
    },
    {
        id: '666959d6ce1c8e310479c246',
        keyType: 3,
        type: 'Dental/Vision/Hearing Products',
        checked: false
    },
    {
        id: '666959fbce1c8e310479c24e',
        keyType: 4,
        type: 'Hospital Indemnity Products',
        checked: false
    },
    {
        id: '66695a1cce1c8e310479c256',
        keyType: 5,
        type: 'Medicare Supplement (Medigap) Products',
        checked: false
    },
];


const intervalInMonth = [
    { value: '1', label: '1 month' },
    { value: '2', label: '2 month' },
    { value: '3', label: '3 month' },
    { value: '4', label: '4 month' },
    { value: '5', label: '5 month' },
    { value: '6', label: '6 month' },
    { value: '7', label: '7 month' },
    { value: '8', label: '8 month' },
    { value: '9', label: '9 month' },
    { value: '10', label: '10 month' },
    { value: '11', label: '11 month' },
    { value: '12', label: '12 month' },
    { value: '13', label: '13 month' },
    { value: '14', label: '14 month' },
    { value: '15', label: '15 month' },
    { value: '16', label: '16 month' },
    { value: '17', label: '17 month' },
    { value: '18', label: '18 month' },
    { value: '19', label: '19 month' },
    { value: '20', label: '20 month' },
    { value: '21', label: '21 month' },
    { value: '22', label: '22 month' },
    { value: '23', label: '23 month' },
    { value: '24', label: '24 month' },
    { value: '25', label: '25 month' },
    { value: '26', label: '26 month' },
    { value: '27', label: '27 month' },
    { value: '28', label: '28 month' },
    { value: '29', label: '29 month' },
    { value: '30', label: '30 month' },
    { value: '31', label: '31 month' },
    { value: '32', label: '32 month' },
    { value: '33', label: '33 month' },
    { value: '34', label: '34 month' },
    { value: '35', label: '35 month' },
    { value: '36', label: '36 month' }
];
const intervalType = [{ name: 'Day', id: 'day' }, { name: 'Week', id: 'week' }, { name: 'Month', id: 'month' }, { name: 'Year', id: 'year' }];
const healthList = [
    { "value": "PP", "label": "Preferred Plus" },
    { "value": "P", "label": "Preferred" },
    { "value": "RP", "label": "Regular Plus" },
    { "value": "R", "label": "Regular" },
    { "value": "T1 copyright Compulife Software Inc", "label": "Table Rating 1 or A" },
    { "value": "T2 copyright Compulife Software Inc", "label": "Table Rating 2 or B" },
    { "value": "T3 copyright Compulife Software Inc", "label": "Table Rating 3 or C" },
    { "value": "T4 copyright Compulife Software Inc", "label": "Table Rating 4 or D" },
    { "value": "T5 copyright Compulife Software Inc", "label": "Table Rating 5 or E" },
    { "value": "T6 copyright Compulife Software Inc", "label": "Table Rating 6 or F" },
    { "value": "T7 copyright Compulife Software Inc", "label": "Table Rating 7 or G" },
    { "value": "T8 copyright Compulife Software Inc", "label": "Table Rating 8 or H" },
    { "value": "T9 copyright Compulife Software Inc", "label": "Table Rating 9 or I" },
    { "value": "T10 copyright Compulife Software Inc", "label": "Table Rating 10 or J" },
    { "value": "T11 copyright Compulife Software Inc", "label": "Table Rating 11 or K" },
    { "value": "T12 copyright Compulife Software Inc", "label": "Table Rating 12 or L" },
    { "value": "T13 copyright Compulife Software Inc", "label": "Table Rating 13 or M" },
    { "value": "T14 copyright Compulife Software Inc", "label": "Table Rating 14 or N" },
    { "value": "T15 copyright Compulife Software Inc", "label": "Table Rating 15 or O" },
    { "value": "T16 copyright Compulife Software Inc", "label": "Table Rating 16 or P" },
];

const carrierList = [
    {
        "value": "AAAL",
        "label": "AAA Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AAAL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AAAL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AAAL-large.png"
        }
    },
    {
        "value": "ALLS",
        "label": "Allstate Life Insurance Co of New York",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ALLS-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ALLS-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ALLS-large.png"
        }
    },
    {
        "value": "AMAM",
        "label": "American-Amicable Life Ins Co of TX",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMAM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMAM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMAM-large.png"
        }
    },
    {
        "value": "AMCN",
        "label": "American Continental Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMCN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMCN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMCN-large.png"
        }
    },
    {
        "value": "AMEM",
        "label": "American Memorial Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMEM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMEM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMEM-large.png"
        }
    },
    {
        "value": "AMEQ",
        "label": "American Equity Investment Life Ins Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMEQ-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMEQ-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMEQ-large.png"
        }
    },
    {
        "value": "AMFI",
        "label": "American Fidelity Assurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMFI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMFI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMFI-large.png"
        }
    },
    {
        "value": "AMGE",
        "label": "American General Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMGE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMGE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMGE-large.png"
        }
    },
    {
        "value": "AMNT",
        "label": "American National Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMNT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMNT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMNT-large.png"
        }
    },
    {
        "value": "AMNX",
        "label": "American National Life Ins Company of TX",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMNX-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMNX-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMNX-large.png"
        }
    },
    {
        "value": "AMNY",
        "label": "American National Life Ins Company of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMNY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMNY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMNY-large.png"
        }
    },
    {
        "value": "AMPR",
        "label": "American Progressive Life & Health of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMPR-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMPR-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMPR-large.png"
        }
    },
    {
        "value": "AMSU",
        "label": "American United Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMSU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMSU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMSU-large.png"
        }
    },
    {
        "value": "AMSV",
        "label": "Americo Financial Life and Annuity Ins.",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMSV-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMSV-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMSV-large.png"
        }
    },
    {
        "value": "AMTA",
        "label": "Ameritas Life Insurance Corp",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMTA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMTA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMTA-large.png"
        }
    },
    {
        "value": "AMTX",
        "label": "Ameritas Life Insurance Corp  (FLX)",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMTX-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMTX-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMTX-large.png"
        }
    },
    {
        "value": "AMTY",
        "label": "Ameritas Life Insurance Corp of New York",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AMTY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AMTY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AMTY-large.png"
        }
    },
    {
        "value": "ASSU",
        "label": "Assurity Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ASSU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ASSU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ASSU-large.png"
        }
    },
    {
        "value": "ATHE",
        "label": "Athene Life Insurance Company of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ATHE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ATHE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ATHE-large.png"
        }
    },
    {
        "value": "AURO",
        "label": "Aurora National Life Assurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/AURO-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/AURO-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/AURO-large.png"
        }
    },
    {
        "value": "BALT",
        "label": "Baltimore Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/BALT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/BALT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/BALT-large.png"
        }
    },
    {
        "value": "BANK",
        "label": "Bankers Fidelity Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/BANK-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/BANK-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/BANK-large.png"
        }
    },
    {
        "value": "BANN",
        "label": "Banner Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/BANN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/BANN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/BANN-large.png"
        }
    },
    {
        "value": "BOST",
        "label": "Boston Mutual Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/BOST-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/BOST-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/BOST-large.png"
        }
    },
    {
        "value": "CAPI",
        "label": "Capitol Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/CAPI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/CAPI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/CAPI-large.png"
        }
    },
    {
        "value": "CETN",
        "label": "Centrian Life Insurance",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/CETN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/CETN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/CETN-large.png"
        }
    },
    {
        "value": "CHRI",
        "label": "Christian Fidelity Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/CHRI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/CHRI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/CHRI-large.png"
        }
    },
    {
        "value": "CINN",
        "label": "Cincinnati Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/CINN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/CINN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/CINN-large.png"
        }
    },
    {
        "value": "COLL",
        "label": "Columbian Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/COLL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/COLL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/COLL-large.png"
        }
    },
    {
        "value": "COLM",
        "label": "Columbian Mutual Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/COLM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/COLM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/COLM-large.png"
        }
    },
    {
        "value": "COLU",
        "label": "Columbus Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/COLU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/COLU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/COLU-large.png"
        }
    },
    {
        "value": "EMPL",
        "label": "EMC National Life Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/EMPL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/EMPL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/EMPL-large.png"
        }
    },
    {
        "value": "EQUF",
        "label": "Equitable Financial Life Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/EQUF-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/EQUF-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/EQUF-large.png"
        }
    },
    {
        "value": "EQUI",
        "label": "Equitable Life & Casualty Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/EQUI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/EQUI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/EQUI-large.png"
        }
    },
    {
        "value": "FAMI",
        "label": "Family Benefit Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/FAMI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/FAMI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/FAMI-large.png"
        }
    },
    {
        "value": "FARM",
        "label": "Farmers New World Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/FARM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/FARM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/FARM-large.png"
        }
    },
    {
        "value": "FEDE",
        "label": "Federal Life Insurance Company (Mutual)",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/FEDE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/FEDE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/FEDE-large.png"
        }
    },
    {
        "value": "FIDL",
        "label": "Fidelity Life Association",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/FIDL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/FIDL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/FIDL-large.png"
        }
    },
    {
        "value": "FORE",
        "label": "Forethought Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/FORE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/FORE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/FORE-large.png"
        }
    },
    {
        "value": "GERB",
        "label": "Gerber Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/GERB-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/GERB-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/GERB-large.png"
        }
    },
    {
        "value": "GLEA",
        "label": "Gleaner Life Insurance Society",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/GLEA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/GLEA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/GLEA-large.png"
        }
    },
    {
        "value": "GOVE",
        "label": "Government Personnel Mutual Life Ins Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/GOVE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/GOVE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/GOVE-large.png"
        }
    },
    {
        "value": "GRAN",
        "label": "Grange Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/GRAN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/GRAN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/GRAN-large.png"
        }
    },
    {
        "value": "GUAR",
        "label": "Guardian Life Insurance Co of America",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/GUAR-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/GUAR-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/GUAR-large.png"
        }
    },
    {
        "value": "ILLI",
        "label": "Illinois Mutual Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ILLI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ILLI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ILLI-large.png"
        }
    },
    {
        "value": "INDE",
        "label": "Independent Order of Foresters",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/INDE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/INDE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/INDE-large.png"
        }
    },
    {
        "value": "JOHU",
        "label": "John Hancock Life Insurance Company USA",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/JOHU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/JOHU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/JOHU-large.png"
        }
    },
    {
        "value": "JOHY",
        "label": "John Hancock Life Insurance Company NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/JOHY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/JOHY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/JOHY-large.png"
        }
    },
    {
        "value": "KENT",
        "label": "Kentucky Home Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/KENT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/KENT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/KENT-large.png"
        }
    },
    {
        "value": "LAFA",
        "label": "Lafayette Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/LAFA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/LAFA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/LAFA-large.png"
        }
    },
    {
        "value": "LIBB",
        "label": "Liberty Bankers Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/LIBB-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/LIBB-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/LIBB-large.png"
        }
    },
    {
        "value": "LISW",
        "label": "Life Insurance Company of the Southwest",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/LISW-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/LISW-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/LISW-large.png"
        }
    },
    {
        "value": "LNLI",
        "label": "Lincoln Life & Annuity Company of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/LNLI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/LNLI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/LNLI-large.png"
        }
    },
    {
        "value": "LNNA",
        "label": "Lincoln National Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/LNNA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/LNNA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/LNNA-large.png"
        }
    },
    {
        "value": "MADI",
        "label": "Madison National Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MADI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MADI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MADI-large.png"
        }
    },
    {
        "value": "MAHA",
        "label": "Massachusetts Mutual Life Ins (Haven)",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MAHA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MAHA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MAHA-large.png"
        }
    },
    {
        "value": "MASM",
        "label": "Massachusetts Mutual Life Insurance",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MASM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MASM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MASM-large.png"
        }
    },
    {
        "value": "MIDT",
        "label": "Midland National Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MIDT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MIDT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MIDT-large.png"
        }
    },
    {
        "value": "MINM",
        "label": "Minnesota Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MINM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MINM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MINM-large.png"
        }
    },
    {
        "value": "MOTO",
        "label": "Motorists Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MOTO-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MOTO-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MOTO-large.png"
        }
    },
    {
        "value": "MOUN",
        "label": "Mountain Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MOUN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MOUN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MOUN-large.png"
        }
    },
    {
        "value": "MTRU",
        "label": "Mutual Trust Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/MTRU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/MTRU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/MTRU-large.png"
        }
    },
    {
        "value": "NABE",
        "label": "National Benefit Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NABE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NABE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NABE-large.png"
        }
    },
    {
        "value": "NASS",
        "label": "Nassau Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NASS-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NASS-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NASS-large.png"
        }
    },
    {
        "value": "NATI",
        "label": "National Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NATI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NATI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NATI-large.png"
        }
    },
    {
        "value": "NATW",
        "label": "Nationwide Life and Annuity Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NATW-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NATW-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NATW-large.png"
        }
    },
    {
        "value": "NATY",
        "label": "Nationwide Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NATY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NATY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NATY-large.png"
        }
    },
    {
        "value": "NAWE",
        "label": "National Western Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NAWE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NAWE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NAWE-large.png"
        }
    },
    {
        "value": "NEWY",
        "label": "New York Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NEWY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NEWY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NEWY-large.png"
        }
    },
    {
        "value": "NORA",
        "label": "North American Co for Life and Health",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NORA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NORA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NORA-large.png"
        }
    },
    {
        "value": "NORM",
        "label": "Northwestern Mutual Life Insurance",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/NORM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/NORM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/NORM-large.png"
        }
    },
    {
        "value": "OCCI",
        "label": "Occidental Life Ins Co of North Carolina",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/OCCI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/OCCI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/OCCI-large.png"
        }
    },
    {
        "value": "OHIN",
        "label": "Ohio National Life Assurance Corporation",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/OHIN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/OHIN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/OHIN-large.png"
        }
    },
    {
        "value": "OXFO",
        "label": "Oxford Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/OXFO-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/OXFO-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/OXFO-large.png"
        }
    },
    {
        "value": "PACI",
        "label": "Pacific Guardian Life Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PACI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PACI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PACI-large.png"
        }
    },
    {
        "value": "PACL",
        "label": "Pacific Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PACL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PACL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PACL-large.png"
        }
    },
    {
        "value": "PAUL",
        "label": "The Paul Revere Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PAUL-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PAUL-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PAUL-large.png"
        }
    },
    {
        "value": "PENN",
        "label": "Penn Mutual Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PENN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PENN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PENN-large.png"
        }
    },
    {
        "value": "PRCU",
        "label": "Polish Roman Catholic Union of America",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRCU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRCU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRCU-large.png"
        }
    },
    {
        "value": "PRIM",
        "label": "Primerica Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRIM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRIM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRIM-large.png"
        }
    },
    {
        "value": "PRIN",
        "label": "Principal Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRIN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRIN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRIN-large.png"
        }
    },
    {
        "value": "PRIP",
        "label": "Principal National Life Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRIP-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRIP-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRIP-large.png"
        }
    },
    {
        "value": "PROA",
        "label": "Protective Life and Annuity Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PROA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PROA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PROA-large.png"
        }
    },
    {
        "value": "PROT",
        "label": "Protective Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PROT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PROT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PROT-large.png"
        }
    },
    {
        "value": "PRTC",
        "label": "Provident Life and Accident Assurance",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRTC-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRTC-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRTC-large.png"
        }
    },
    {
        "value": "PRUC",
        "label": "Pruco Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRUC-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRUC-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRUC-large.png"
        }
    },
    {
        "value": "PRUJ",
        "label": "Pruco Life Insurance Co of New Jersey",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/PRUJ-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/PRUJ-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/PRUJ-large.png"
        }
    },
    {
        "value": "RESE",
        "label": "Reserve National Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/RESE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/RESE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/RESE-large.png"
        }
    },
    {
        "value": "ROYA",
        "label": "Royal Neighbors of America",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ROYA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ROYA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ROYA-large.png"
        }
    },
    {
        "value": "SAGI",
        "label": "Sagicor Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SAGI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SAGI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SAGI-large.png"
        }
    },
    {
        "value": "SAVE",
        "label": "Savings Bank Mutual Life Ins Co of MA",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SAVE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SAVE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SAVE-large.png"
        }
    },
    {
        "value": "SBLI",
        "label": "SBLI USA Life Insurance Co Inc",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SBLI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SBLI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SBLI-large.png"
        }
    },
    {
        "value": "SECU",
        "label": "Securian Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SECU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SECU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SECU-large.png"
        }
    },
    {
        "value": "SEMU",
        "label": "Security Mutual Life Insurance Co of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SEMU-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SEMU-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SEMU-large.png"
        }
    },
    {
        "value": "SENA",
        "label": "Security National Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SENA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SENA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SENA-large.png"
        }
    },
    {
        "value": "SENS",
        "label": "Sentinel Security Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SENS-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SENS-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SENS-large.png"
        }
    },
    {
        "value": "SENT",
        "label": "Sentry Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SENT-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SENT-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SENT-large.png"
        }
    },
    {
        "value": "STAN",
        "label": "Standard Life and Accident Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/STAN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/STAN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/STAN-large.png"
        }
    },
    {
        "value": "STAR",
        "label": "Starmount Life Insurance Co.",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/STAR-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/STAR-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/STAR-large.png"
        }
    },
    {
        "value": "SUNS",
        "label": "Sunset Life Insurance Company of America",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SUNS-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SUNS-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SUNS-large.png"
        }
    },
    {
        "value": "SUSA",
        "label": "S.USA Life Insurance Company, Inc.",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SUSA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SUSA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SUSA-large.png"
        }
    },
    {
        "value": "SYME",
        "label": "Symetra Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/SYME-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/SYME-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/SYME-large.png"
        }
    },
    {
        "value": "THRI",
        "label": "Thrivent Financial for Lutherans",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/THRI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/THRI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/THRI-large.png"
        }
    },
    {
        "value": "TRAN",
        "label": "Transamerica Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/TRAN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/TRAN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/TRAN-large.png"
        }
    },
    {
        "value": "TRIN",
        "label": "Trinity Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/TRIN-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/TRIN-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/TRIN-large.png"
        }
    },
    {
        "value": "UTFA",
        "label": "United Farm Family Life Insurance Co",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/UTFA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/UTFA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/UTFA-large.png"
        }
    },
    {
        "value": "UTHE",
        "label": "United Heritage Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/UTHE-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/UTHE-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/UTHE-large.png"
        }
    },
    {
        "value": "UTHO",
        "label": "United Home Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/UTHO-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/UTHO-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/UTHO-large.png"
        }
    },
    {
        "value": "UTOM",
        "label": "United of Omaha Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/UTOM-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/UTOM-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/UTOM-large.png"
        }
    },
    {
        "value": "UTST",
        "label": "United States Life Ins in the City of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/UTST-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/UTST-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/UTST-large.png"
        }
    },
    {
        "value": "WILP",
        "label": "William Penn Life Insurance Co of NY",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/WILP-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/WILP-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/WILP-large.png"
        }
    },
    {
        "value": "WOMA",
        "label": "Woman's Life Insurance Society",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/WOMA-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/WOMA-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/WOMA-large.png"
        }
    },
    {
        "value": "ZURI",
        "label": "Zurich American Life Insurance Company",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ZURI-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ZURI-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ZURI-large.png"
        }
    },
    {
        "value": "ZURY",
        "label": "Zurich Life Insurance Co of New York",
        "Logos": {
            "Small": "https://www.compulifeapi.com/images/logosapi/ZURY-small.png",
            "Medium": "https://www.compulifeapi.com/images/logosapi/ZURY-medium.png",
            "Large": "https://www.compulifeapi.com/images/logosapi/ZURY-large.png"
        }
    }
];
const catList = [
    { "value": "2", "label": "5 Year Level Term Guaranteed" },
    { "value": "3", "label": "10 Year Level Term Guaranteed" },
    { "value": "4", "label": "15 Year Level Term Guaranteed" },
    { "value": "5", "label": "20 Year Level Term Guaranteed" },
    { "value": "6", "label": "25 Year Level Term Guaranteed" },
    { "value": "7", "label": "30 Year Level Term Guaranteed" },
    { "value": "9", "label": "35 Year Level Term Guaranteed" },
    { "value": "0", "label": "40 Year Level Term Guaranteed" },
    { "value": "T", "label": "To Age 65 Level Guaranteed" },
    { "value": "U", "label": "To Age 70 Level Guaranteed" },
    { "value": "V", "label": "To Age 75 Level Guaranteed" },
    { "value": "A", "label": "To Age 80 Level Guaranteed" },
    { "value": "B", "label": "To Age 85 Level Guaranteed" },
    { "value": "C", "label": "To Age 90 Level Guaranteed" },
    { "value": "D", "label": "To Age 95 Level Guaranteed" },
    { "value": "E", "label": "To Age 100 Level Guaranteed" },
    { "value": "G", "label": "To Age 105 Level Guaranteed" },
    { "value": "H", "label": "To Age 110 Level Guaranteed" },
    { "value": "F", "label": "Other Term" },
    { "value": "J", "label": "15 Year Return of Premium" },
    { "value": "K", "label": "20 Year Return of Premium" },
    { "value": "L", "label": "25 Year Return of Premium" },
    { "value": "M", "label": "30 Year Return of Premium" },
    { "value": "W", "label": "To age 65 Return of Premium" },
    { "value": "8", "label": "To Age 121 Level  (No Lapse U/L)" },
    { "value": "P", "label": "To Age 121 Level - Pay to 100" },
    { "value": "Q", "label": "To Age 121 Level - Pay to 65" },
    { "value": "R", "label": "To Age 121 Level - 20 Pay" },
    { "value": "S", "label": "To Age 121 Level - 10 Pay" },
    { "value": "O", "label": "To Age 121 Level - Single Pay" },
    { "value": "Y", "label": "GIWL - Graded Benefit Whole Life" },
    { "value": "Z", "label": "Multiple Categories" },
    { "value": "z", "label": "Combined Category Analysis" }
];
const stateList = [
    { "value": "1", "label": "Alabama" },
    { "value": "2", "label": "Alaska" },
    { "value": "3", "label": "Arizona" },
    { "value": "4", "label": "Arkansas" },
    { "value": "5", "label": "California" },
    { "value": "6", "label": "Colorado" },
    { "value": "7", "label": "Connecticut" },
    { "value": "8", "label": "Delaware" },
    { "value": "9", "label": "Dist.Columbia" },
    { "value": "10", "label": "Florida" },
    { "value": "11", "label": "Georgia" },
    { "value": "12", "label": "Hawaii" },
    { "value": "13", "label": "Idaho" },
    { "value": "14", "label": "Illinois" },
    { "value": "15", "label": "Indiana" },
    { "value": "16", "label": "Iowa" },
    { "value": "17", "label": "Kansas" },
    { "value": "18", "label": "Kentucky" },
    { "value": "19", "label": "Louisiana" },
    { "value": "20", "label": "Maine" },
    { "value": "21", "label": "Maryland" },
    { "value": "22", "label": "Massachusetts" },
    { "value": "23", "label": "Michigan" },
    { "value": "24", "label": "Minnesota" },
    { "value": "25", "label": "Mississippi" },
    { "value": "26", "label": "Missouri" },
    { "value": "27", "label": "Montana" },
    { "value": "28", "label": "Nebraska" },
    { "value": "29", "label": "Nevada" },
    { "value": "30", "label": "New Hampshire" },
    { "value": "31", "label": "New Jersey" },
    { "value": "32", "label": "New Mexico" },
    { "value": "33", "label": "NY Business" },
    { "value": "34", "label": "North Carolina" },
    { "value": "35", "label": "North Dakota" },
    { "value": "36", "label": "Ohio" },
    { "value": "37", "label": "Oklahoma" },
    { "value": "38", "label": "Oregon" },
    { "value": "39", "label": "Pennsylvania" },
    { "value": "40", "label": "Rhode Island" },
    { "value": "41", "label": "South Carolina" },
    { "value": "42", "label": "South Dakota" },
    { "value": "43", "label": "Tennessee" },
    { "value": "44", "label": "Texas" },
    { "value": "45", "label": "Utah" },
    { "value": "46", "label": "Vermont" },
    { "value": "47", "label": "Virginia" },
    { "value": "48", "label": "Washington" },
    { "value": "49", "label": "West Virginia" },
    { "value": "50", "label": "Wisconsin" },
    { "value": "51", "label": "Wyoming" },
    { "value": "52", "label": "NY Non-Bus" },
    { "value": "53", "label": "Guam" },
    { "value": "54", "label": "Puerto Rico" },
    { "value": "55", "label": "Virgin Islands" },
    { "value": "56", "label": "Amer. Samoa" }
];

export default { intervalInMonth, productsForScope, isImage, isVideo, isAudio, isFile, stateList, catList, healthList, carrierList, stripeProduct, intervalType };
