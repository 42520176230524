import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../apis/APIs';
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from 'react-phone-number-input/input';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { validateUserFormData } from "./Validation";
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../apis/apiUtils';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import appSettings from '../../configs/AppConfig';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import appRoutes from '../../configs/AppRoutes';
import Pagination from '../../common/Pagination/pagination';
import { Helmet } from 'react-helmet';


const List = () => {
    const [users, setUsers] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const { loading2,isAdmin } = useSelector((state) => state.common);
    const handleClose = () => setShowEditModal(false);
    const navigate = useNavigate();
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState({ display_name: '', first_name: '', last_name: '', email: '', phonenumber: '' });
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: 'all' });

    /** Applied Filter Here */
    const applyFilter = () => {
        setPaginationData({ ...paginationData, page: 1 });
        getUsers(1, filter.status);
    };
    /**********ONLY LOAD TIME RUN***************/
    useEffect(() => {
        if (isAdmin && filter.search_string.length > 2 || filter.search_string === "") {
            getUsers(1, filter.status);
        }
    }, [filter.search_string]);


    const clearFilter = () => {
        setPaginationData({ ...paginationData, page: 1 });
        setFilter({ search_string: '', status: 'all' });
        getUsers(1, 'all');
    };

    /** Get List Of Users */
    const getUsers = async (page, status) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Agent', 'List'] }));
            const res = await sendRequest(`/admin/user-list`, 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUsers(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const renderTabeBody = () => {
        return users.map((user) => {
            let badgeCls = user.status === 1 ? 'success' : 'danger';
            return (
                <tr key={user.id}>
                    <td className="d-flex align-items-center">
                        {/**begin:: Avatar **/}
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                            <a href={undefined}>
                                <div className="symbol-label">
                                    <img src={user && user.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + user.user_image : '/assets/media/avatars/blank.png'} alt="Emma Smith" className="w-100" />
                                </div>
                            </a>
                        </div>
                        {/**end::Avatar**/}
                        {/**begin::User details**/}
                        <div className="d-flex flex-column">
                            <a href={undefined} className="text-gray-800 text-hover-primary mb-1" onClick={() => navigate(appRoutes.adminUserDetailRoute + '/' + user._id)}>{user.display_name}</a>
                            <span>{user.email}</span>
                        </div>
                        {/**begin::User details**/}
                    </td>
                    <td>{moment(new Date(user.created_at)).format('MM/DD/YYYY')}</td>
                    <td className='text-center'><span className={`text-uppercase badge badge-light-${badgeCls}`}>{user.status_name}</span></td>
                    <td className="text-end">
                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminUserDetailRoute + '/' + user._id)}>View</Dropdown.Item>
                                <Dropdown.Item href={undefined} onClick={() => handleEdit(user)}>Edit</Dropdown.Item>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminInvoiceHistoryRoute + '/' + user.stripe_customer)}>Payment History</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        });

    };

    /** Edit User **/
    const handleEdit = (user) => {
        setEditUser(user);
        setShowEditModal(true);
    };

    /** handlePhoneChange */
    const handlePhoneChange = (phonenumber) => {
        if (phonenumber) {
            editUser.phonenumber = phonenumber;
            setEditUser(editUser);
            checkFormIsValid("phonenumber");
        }
    };

    /** OnChange Update Input Values */
    const handleChange = (e) => {
        //console.log('e', e)
        editUser[e.target.name] = e.target.value;
        setEditUser(editUser);
        checkFormIsValid(e.target.name);
    };

    /** Validation implemented here */
    const checkFormIsValid = async (fieldName) => {
        const res = validateUserFormData(editUser, fieldName, isValidPhoneNumber);
        if (fieldName) {
            fieldName = fieldName.trim();
            let err = res.errors[fieldName];
            if (typeof err != "undefined") {
                errorsInfo[fieldName] = err;
            } else {
                errorsInfo[fieldName] = "";
            }
            setErrorsInfo({ ...errorsInfo });
        } else {
            setErrorsInfo({ ...res.errors });
        }
        return res.formVaildCheck;
    };

    /** Update User From Here **/
    const submitUpdateForm = async (e) => {
        if (await checkFormIsValid("")) {
            try {
                dispatch(callCommonAction({ loading2: true }));
                const { display_name, first_name, last_name, email, phonenumber, id } = editUser;
                const res = await sendRequest(`/admin/update-user-profile/` + id, 'POST', { display_name, first_name, last_name, email, phonenumber });
                dispatch(callCommonAction({ loading2: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    setShowEditModal(false);
                    setEditUser(null);
                    getUsers(paginationData.page, filter.status);
                }
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }

        }
    };

    /** Edit User Form ***/
    const userUpdateForm = () => {
        return (
            <Modal show={showEditModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                value={editUser.first_name}
                                name="first_name"
                                className={`form-control bg-transparent ${errorsInfo.first_name ? 'is-invalid' : ''}`}
                                onChange={handleChange}
                                autoFocus
                            />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{errorsInfo.first_name}</span>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter last name"
                                value={editUser.last_name}
                                name="last_name"
                                className={`form-control bg-transparent ${errorsInfo.last_name ? 'is-invalid' : ''}`}
                                onChange={handleChange}
                                autoFocus
                            />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{errorsInfo.last_name}</span>
                                </div>
                            </div>
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Display</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter display name"
                                value={editUser.display_name}
                                name="display_name"
                                className={`form-control bg-transparent ${errorsInfo.display_name ? 'is-invalid' : ''}`}
                                onChange={handleChange}
                                autoFocus
                            />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{errorsInfo.display_name}</span>
                                </div>
                            </div>
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number</Form.Label>
                            <Input
                                country="US"
                                placeholder="Phone Number"
                                className={`form-control bg-transparent ${errorsInfo.phonenumber ? 'is-invalid' : ''}`}
                                onChange={(e) => handlePhoneChange(e)}
                                value={editUser.phonenumber}
                            />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{errorsInfo.phonenumber}</span>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                name="email"
                                value={editUser.email}
                                className={`form-control bg-transparent ${errorsInfo.email ? 'is-invalid' : ''}`}
                                onChange={handleChange}
                                autoFocus
                            />
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{errorsInfo.email}</span>
                                </div>
                            </div>
                        </Form.Group>
                        {/* Add other form fields here based on your user object */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submitUpdateForm} disabled={loading2 ? true : false}>
                        {
                            loading2
                                ?
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                :
                                <span className="indicator-label">Save Changes</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };




    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Agent Management</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header border-0 pt-6">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                {/**begin::Search**/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search user" />
                                </div>
                                {/**end::Search**/}
                            </div>
                            {/**begin::Card title**/}
                            {/**begin::Card toolbar**/}
                            <div className="card-toolbar">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center me-2">
                                        <label className="form-label fw-semibold mb-0 me-2">Status:</label>
                                        <div >
                                            <select className="form-select form-select-solid fw-bold" value={filter.status} onChange={(e) => setFilter({ ...filter, status: e.target.value })} >
                                                <option value="all">All</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="reset" className="btn btn-light btn-active-light-primary me-2" onClick={clearFilter}>Reset</button>
                                        <button type="submit" className="btn btn-primary" onClick={applyFilter}>Apply</button>
                                    </div>
                                </div>



                                {/**end::Toolbar**/}
                                {/**begin::Group actions**/}
                                <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
                                    <div className="fw-bold me-5">
                                        <span className="me-2" data-kt-user-table-select="selected_count"></span>Selected</div>
                                    <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
                                </div>
                                {/**end::Group actions**/}
                            </div>
                            {/**end::Card toolbar**/}
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body table-frame py-4 border-top mt-5">
                            {/**begin::Table**/}
                            <div className='table-responsive'>
                            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-125px">Agent</th>
                                        <th className="min-w-125px">Joined Date</th>
                                        <th className="min-w-125px text-center">Status</th>
                                        <th className="text-end min-w-100px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-semibold">
                                    {users.length > 0 ? renderTabeBody() : <tr>
                                        <td colSpan={4} className='text-center'>No result found!</td>
                                    </tr>}
                                </tbody>
                            </table>
                            </div>
                            {/**end::Table**/}
                            <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                pageSize={paginationData.perpage} onPageChange={page => getUsers(page, filter.status)}
                            />
                            {showEditModal ? userUpdateForm() : null}
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
                {/**end::Container**/}
            </div>
            {/**end::Post**/}
        </div>
    );
};

export default List;
