import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateCmdFormData } from './Validation';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { sendRequest } from '../../apis/APIs';
import { handleApiResponse } from '../../apis/apiUtils';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { Helmet } from 'react-helmet';


const PageVideo = () => {
    const [videoList, setVideoList] = useState([]);
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        getPageVideos();
    }, []);

    /* Validation implemented here */
    const checkFormIsValid = (fieldName) => {
        const res = validateCmdFormData('page-video', videoList, fieldName, '');
        setFormErrors(res.errors);

        console.log('res.formVaildCheck', res.formVaildCheck)
        return res.formVaildCheck;
    };

    const getPageVideos = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Video', 'List'] }));
            const res = await sendRequest('/admin/page-video-list', 'GET', {});
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                console.log('res.data.data.docs', res.data.data);
                setVideoList(res.data.data);
            }
        } catch (error) {
            // toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const updatePage = async (video) => {
        //if (checkFormIsValid('')) {
            try {
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest('/admin/page-video-update/' + video._id, 'POST', video);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
            } catch (error) {
                toast.error(error.message);
                dispatch(callCommonAction({ loading: false }));
            }
        //}
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const index = e.target.getAttribute('index');
        // Make a copy of the data array
        const newData = [...videoList];
        // Update the name of the item at the found index
        newData[index] = { ...newData[index], [name]: value };
        setVideoList(newData);
        //checkFormIsValid(e.target.name);

    };

    const renderData = () => {
        return videoList.map((video, index) => {
            return (


                <div className='row mb-5' key={video._id}>
                    <div className='col-12 col-lg-4'>
                        <label className="form-label fs-6 fw-bold mb-3">Title</label>
                        <input
                            type="text"
                            name="title"
                            autoComplete="off"
                            className="form-control bg-transparent"
                            value={video.title}
                            index={index}
                            onChange={handleChange}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block"><span role="alert" />{formErrors.title}</div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <label className="form-label fs-6 fw-bold mb-3">Video url</label>
                        <input
                            type="text"
                            name="url"
                            autoComplete="off"
                            className="form-control bg-transparent"
                            value={video.url}
                            index={index}
                            onChange={handleChange}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block"><span role="alert" />{formErrors.url}</div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-2'>
                        <label className="form-label fs-6 fw-bold mb-3 d-block">&nbsp;</label>
                        <button type="button" className="btn btn-primary mt-1" onClick={() => updatePage(video)}>Submit</button>
                    </div>
                </div>


            );
        });
    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Video Page</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                {/**begin::Search**/}
                                {/* <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search plan" />
                                </div> */}
                                {/**end::Search**/}
                            </div>
                            {/**begin::Card title**/}
                            {/**begin::Card toolbar**/}
                            <div className="card-toolbar">
                                {/* <div className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end">
                                        <button onClick={() => { navigate(appRoutes.adminPlanFormRoute); }} className="btn btn-primary btn-sm" ><i className="ki-duotone ki-plus fs-2"></i>Add</button>
                                    </div>
                                </div> */}
                                {/**end::Toolbar**/}
                            </div>
                            {/**end::Card toolbar**/}
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body">
                            {/**begin::Table wrapper**/}

                            {/**begin::Table**/}

                            {videoList.length > 0 ? renderData() : 'No result found!'
                            }

                            {/**end::Table**/}


                            {/* <div className='pag mb-4'>
                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                    pageSize={paginationData.perpage} onPageChange={page => getPlanList(page, filter.status)}
                                />
                            </div> */}
                            {/**end::Table wrapper**/}
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
        </div>
    );

};

export default PageVideo;