import STRINGS from '../../../common/strings/strings';
export function validatePlanFormData(planDetail, fieldName, params) {
    const { interval, name, description, unit_amount } = planDetail;
    console.log('planDetail',planDetail)
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (name == '' && (fieldName == 'name' || fieldName == '')) {
        errorMsg = name === '' ? STRINGS.nameReq : '';
        errors.name = errorMsg;
        formVaildCheck = false;
    }
    if (interval == '' && (fieldName == 'interval' || fieldName == '')) {
        errorMsg = interval === '' ? 'Please select interval first' : '';
        errors.interval = errorMsg;
        formVaildCheck = false;
    }
    if (description == '' && (fieldName == 'description' || fieldName == '')) {
        errorMsg = description == '' ? 'The description field is required.' : '';
        errors.description = errorMsg;
        formVaildCheck = false;
    }

    if (params.id !== undefined){
        if (parseFloat(unit_amount) <= 0  || unit_amount == '' && (fieldName == 'unit_amount' || fieldName == '')) {
            errors.unit_amount = 'The price field is required.';
            formVaildCheck = false;
        }
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}