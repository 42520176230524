import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { validateNewsFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import { uploadImage } from '../../../common/ImageUpload';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helmet } from 'react-helmet';


const AddEdit = () => {


	const [editorContent, setEditorContent] = useState('');

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setEditorContent(data);
	};

	const [oldImage, setOldImage] = useState('');

	const params = useParams();
	const navigate = useNavigate();
	const [newsForm, setNewsForm] = useState({ name: "", des: "", sort: "", user_image: '' });

	const [errorsInfo, setErrorsInfo] = useState({ name: "", des: "", user_image: '' });
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.common);
	const [backgroundImage, setBackgroundImage] = useState();
	
	useEffect(() => {

		if (params.id !== undefined) getAddEditFormData();

		dispatch(callCommonAction({ breadCrumb: ['News', params.id === undefined ? 'Add Form' : 'Edit Form', appRoutes.adminNewsListRoute] }));
	}, []);

	const handleUserImageChange = async (e) => {
		const selectedImage = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

		if (selectedImage) {
			const checkValidation = await uploadImage(5, ['.png', '.jpg', '.jpeg'], selectedImage);
			if (checkValidation) {

				setNewsForm((prevUserDetail) => ({
					...prevUserDetail,
					user_image: selectedImage,
				}));
				// Update the background image dynamically
				if (selectedImage) {
					const reader = new FileReader();
					reader.onloadend = () => {
						setBackgroundImage(`${reader.result}`);
					};
					reader.readAsDataURL(selectedImage);
				} else {
					setBackgroundImage('url("assets/media/avatars/blank.png")');
				}
			}
		}

	};

	const handleUserImageRemove = () => {
		setNewsForm((prevUserDetail) => ({ ...prevUserDetail, user_image: null, user_image_remove: true }));
		const fileInput = document.getElementById('userImageInput');   // Clear the file input field

		if (fileInput) fileInput.value = ''; // Reset the value to clear the selected file
		setBackgroundImage('url("assets/media/avatars/300-1.jpg")');
	};

	const getAddEditFormData = async () => {
		try {
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest('/news/form/' + params.id, 'GET');
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setOldImage(res.data.data.user_image);
				setNewsForm(res.data.data);
				setEditorContent(res.data.data.des);
				setBackgroundImage(res.data.data.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + res.data.data.user_image : 'url("assets/media/avatars/blank.png")');

			}
		} catch (error) {
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	/** Handle Change ****/
	const handleChange = (e) => {
		newsForm[e.target.name] = e.target.value;
		setNewsForm({ ...newsForm, [e.target.name]: e.target.value });
		checkFormIsValid(e.target.name);
	};

	/** Validation implemented here */
	const checkFormIsValid = async (fieldName) => {
		const res = validateNewsFormData(newsForm, fieldName, editorContent);
		setErrorsInfo(res.errors);
		return res.formVaildCheck;
	};

	/** Submit Form Data */
	const formSubmit = async (e) => {
		e.preventDefault();
		//console.log('CKEditor Content:', editorContent);
		if (await checkFormIsValid('')) {
			newsForm.des = editorContent;
			const formData = new FormData();
			Object.keys(newsForm).forEach((key) => {
				formData.append(key, newsForm[key]);
			});

			formData.append('old_news_image', oldImage);

			try {
				dispatch(callCommonAction({ loading: true }));
				var url = params.id === undefined ? '/news/create' : '/news/update/' + params.id;
				const res = await sendRequest(url, 'POST', formData);
				dispatch(callCommonAction({ loading: false }));
				handleApiResponse(res);
				if (res.data.type === "success") {
					navigate(appRoutes.adminNewsListRoute);
				}
			} catch (error) {
				toast.error('Something went wrong');
				dispatch(callCommonAction({ loading: false }));
			}
		}
	};

	// Add or remove toolbar options as needed
	const config = {
		toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'link', 'undo', 'redo'],
	};


	return (
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<Helmet>
                <title>iQuote | News Form</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
			<div id="kt_content_container" className="container-xxl">
				<BackButton />
				<div className="card">
					<div className="card-header" id="kt_chat_contacts_header">
						<div className="card-title">
							<i className="ki-duotone ki-badge fs-1 me-2">
								<span className="path1"></span>
								<span className="path2"></span>
								<span className="path3"></span>
								<span className="path4"></span>
								<span className="path5"></span>
							</i>
							<h2>News Form</h2>
						</div>
					</div>
					<div className="card-body table-frame pt-5">
						<form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={formSubmit} encType="multipart/form-data">
							<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
								<div className="col">
									<div className="fv-row mb-7 fv-plugins-icon-container">
										<label className="fs-6 fw-semibold form-label mt-3">
											<span className="required">Name</span>
										</label>
										<input type="text" onChange={handleChange} value={newsForm.name} className="form-control form-control-solid" name="name" />
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errorsInfo.name}</span>
											</div>
										</div>
									</div>
									<div className="col-lg-8">
										<div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: backgroundImage }}>
											<div className="image-input-wrapper w-125px h-125px">
												<img style={{ width: "100%", height: "100%", maxWidth: "125px", maxHeight: "125px" }} src={backgroundImage ? backgroundImage : '/assets/media/avatars/blank.png'} />
											</div>
											<label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
												<i className="ki-duotone ki-pencil fs-7">
													<span className="path1" />
													<span className="path2" />
												</i>
												<input type="file" id="userImageInput" name="user_image" accept=".png, .jpg, .jpeg" onChange={handleUserImageChange} />
												<input type="hidden" name="user_image_remove" />
											</label>
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{errorsInfo.user_image}</span>
												</div>
											</div>
											<span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar" onClick={handleUserImageRemove}>
												<i className="ki-duotone ki-cross fs-2">
													<span className="path1" />
													<span className="path2" />
												</i>
											</span>
										</div>
										<div className="form-text">Allowed file types: png, jpg, jpeg.</div>
									</div>
									<button type="submit" id="kt_sign_up_submit" className="btn btn-primary me-2 mt-3" disabled={loading ? true : false}>
										{
											loading
												?
												<span className="indicator-progress" style={{ display: 'block' }}>
													Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
												</span>
												:
												<span className="indicator-label">Save</span>
										}
									</button>
								</div>
								<div className="col">
									<div className="fv-row mb-7">
										<label className="fs-6 fw-semibold form-label mt-3">
											<span>Description</span>
										</label>
										{/* <textarea style={{height: '100px'}} onChange={handleChange} value={newsForm.des} className="form-control form-control-solid" name="des"></textarea> */}
										<CKEditor
											editor={ClassicEditor}
											onChange={handleEditorChange}
											data={editorContent}
											config={config}
										/>
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errorsInfo.des}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>

				</div>
			</div>
		</div>

	);
};
export default AddEdit;
