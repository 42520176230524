import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';
import { sendRequest } from './apis/APIs';
import UserRoute from './routes/UserRoute';
import AdminRoute from './routes/AdminRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  const dispatch = useDispatch();
  const isAdminUrl = window.location.href.includes('admin');
  const { isLoggedIn, isAdmin, user } = useSelector((state) => state.common);
  //const navigate = useNavigate();

 

  //const navigate = useNavigate();
  /** Only Load Time Set State From LocalStorage */
  useEffect(() => {
    let raiseNotificationBell = localStorage.raiseNotificationBell != undefined ? localStorage.raiseNotificationBell == "true" ? true : false : false;
    if (localStorage.token !== undefined && localStorage.token !== null) {
      dispatch(callCommonAction({
        isLoggedIn: true,
        token: localStorage.token,
        user: JSON.parse(localStorage.user),
        subscription: localStorage.subscription === undefined ? null : JSON.parse(localStorage.subscription),
        isAdmin: localStorage.isAdmin === undefined ? false : localStorage.isAdmin === 'false' ? false : true,
        isSubscriptionCanceled: localStorage.isSubscriptionCanceled === undefined ? false : localStorage.isSubscriptionCanceled === false ? false : true
      }));
    }
    //without user logged in we also need raise Notification Bell
    dispatch(callCommonAction({ raiseNotificationBell: raiseNotificationBell }));
  }, [isLoggedIn, isAdmin]);

  useEffect(() => {
    if (localStorage.token !== undefined && localStorage.token !== null) {
      checkUserExist();
    }

  }, []);
  
  const checkUserExist = async () => {
    let userData = JSON.parse(localStorage.user)
    try {
      const res = await sendRequest(`/user/detail/${userData.id}`, 'GET');
      if (res.data.data === null) {
        localStorage.removeItem("token");
        localStorage.removeItem('user');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('subscription');
        dispatch(callCommonAction({ isLoggedIn: false, token: null, user: null, isAdmin: false }));
        isAdmin ? window.location('/admin/login') : window.location('/user/login');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Suspense>
          {isAdminUrl ? <AdminRoute /> : <UserRoute />}
        </Suspense>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
