import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { useDispatch, useSelector } from 'react-redux';
import { handleApiResponse } from '../../apis/apiUtils';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MyPaymentModal from './MyPaymentModal';


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const ManagePlan = () => {
    const [planList, setPlanList] = useState([]);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useSelector((state) => state.common);
    const [paginationData, setPaginationData] = useState({ perpage: 1000, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: '' });
    const [subscriptionData, setSubscriptionData] = useState({});
    const [readyToUpdatePlan, setReadyToUpdatePlan] = useState({});

    const openModal = (plan) => {
        if(subscriptionData?.created_by?.is_card_enter == 1){
            createNewSubscription(plan)
        }else{
            setReadyToUpdatePlan(plan);
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setReadyToUpdatePlan({});
        setIsModalOpen(false);
    };


    useEffect(() => {
        getPlanList(1, filter.status);
    }, []);

    /** Get Plan List For Manage **/
    const getPlanList = async (page, status) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Plan', 'List'] }));
            const res = await sendRequest('/plan/list', 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setPlanList(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: page });
                setSubscriptionData(res.data.subscription);
            }
        } catch (error) {
            console.log('error====>',error);
            toast.error('Something went wrong during getting plan list');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const newSubAlongWithSetPaymentMethod = async (paymentMethod) => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/subscription/create-and-setup', 'POST', { stripe_subscription_id: subscriptionData.stripe_subscription_id, payment_method_id: paymentMethod.id, price_id: readyToUpdatePlan.stripe_plan_id, plan_id: readyToUpdatePlan.id, stripe_customer: user.stripe_customer });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPlanList(1, filter.status);
            }
        } catch (error) {
            toast.error('Something went wrong during create subscription along payment method');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const updatePlan = (paymentMethod) => {
        if (Object.keys(readyToUpdatePlan).length > 0 && paymentMethod && paymentMethod.id) {
            newSubAlongWithSetPaymentMethod(paymentMethod);
        }
    };

    /** Create New  Subscription*/
    const createNewSubscription = async (plan) => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/subscription/create', 'POST', { price_id: plan.stripe_plan_id, plan_id: plan.id, stripe_customer: user.stripe_customer });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPlanList(1, filter.status);
            }
        } catch (error) {
            toast.error('Something went wrong during create subscription');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const resumeSubscription = async (subData, plan) => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/subscription/resume', 'POST', { subscription: subData, plan: plan });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPlanList(1, filter.status);
            }
        } catch (error) {
            toast.error('Something went wrong during resume subscription');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const cancelSubscription = async (subData) => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/subscription/cancel', 'POST', { subscription: subData });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPlanList(1, filter.status);
            }
        } catch (error) {
            toast.error('Something went wrong during cancel subscription');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const renderPlanList = () => {
        return planList.map((plan) => {

            if (plan.status === 0 && plan.id != subscriptionData.plan._id) {
                //console.log('if enter', plan.id != subscriptionData.plan._id);
            } else {
                return (
                    <div className="col-xl-4" key={plan._id}>
                        <div className="d-flex h-100 align-items-center">
                            <div className={`${plan.id == subscriptionData?.plan._id ? 'plan-active' : ''} h-100 w-100 d-flex flex-column flex-center rounded-3 bg-light  bg-opacity-75 py-15 px-10 border`}>
                                <div className="mb-7 text-center">
                                    <h1 className="text-gray-900 mb-5 fw-bolder">{plan.name}</h1>
                                    <div className="text-center">
                                        <span className="mb-2 text-primary fs-1">$</span>
                                        <span className="fs-3x fw-bold text-primary">{parseFloat(plan.unit_amount).toFixed(2)}</span>
                                        <span className="fw-semibold opacity-50">/{plan.recurring_obj.interval_count > 1 && plan.recurring_obj.interval_count}
                                            <span> {plan.recurring_obj.interval.charAt(0).toUpperCase() + plan.recurring_obj.interval.slice(1)}</span></span>
                                    </div>
                                </div>
                                <div className="w-100 mb-10">
                                    {
                                        plan.plan_features.length > 0
                                            ?
                                            plan.plan_features.map((feature) => {
                                                return (
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">{feature.name}</span>
                                                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </div>
                                                );
                                            })
                                            :
                                            null
                                    }
                                </div>
                                <div className="d-flex justify-content-center">
                                    {
                                        subscriptionData == null
                                            ?
                                            <button className="btn btn-sm btn-primary" onClick={() => createNewSubscription(plan)}>Select</button>
                                            :
                                            plan.id == subscriptionData.plan._id
                                                ?
                                                subscriptionData.stripe_status === "canceled"
                                                    ?
                                                    <button className="btn btn-sm btn-no-cursor btn-danger">Canceled</button>
                                                    :
                                                    subscriptionData.canceled_at !== null
                                                        ?
                                                        <button className="btn btn-sm btn-success" onClick={() => resumeSubscription(subscriptionData, plan)}>Resume</button>
                                                        :
                                                        subscriptionData?.is_free == 1
                                                            ?
                                                            <button className="btn btn-sm btn-dark mx-1 btn-no-cursor"> Activated</button>
                                                            :
                                                            <button className="btn btn-sm btn-dark mx-1 " onClick={() => cancelSubscription(subscriptionData)}> Cancel</button>

                                                :
                                                parseFloat(plan.unit_amount) > parseFloat(subscriptionData.plan.unit_amount) 
                                                    ?
                                                    parseFloat(plan.unit_amount) > 0 ? <button className="btn btn-sm btn-primary" onClick={() => { subscriptionData.is_free == 1 ? openModal(plan) : subscriptionData.stripe_status === "canceled" ? createNewSubscription(plan) : resumeSubscription(subscriptionData, plan); }}>Upgrade</button> : null
                                                    :
                                                    parseFloat(plan.unit_amount) > 0 ? <button className="btn btn-sm btn-primary" onClick={() => { subscriptionData.is_free == 1 ? openModal(plan) : subscriptionData.stripe_status === "canceled" ? createNewSubscription(plan) : resumeSubscription(subscriptionData, plan); }}>Downgrade</button> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        });
    };



    return (
        <div>
            <Helmet>
                <title>iQuote | Manage Plan</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="card" id="kt_pricing">
                <div className="card-body p-lg-17">
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bold mb-5">Choose Your Plan</h1>
                            {/* <div className="text-gray-600 fw-semibold fs-5">If you need more info about our pricing, please check
                                <a href="#" className="link-primary fw-bold"> Pricing Guidelines</a>.</div> */}
                        </div>
                        <div className="row g-10 justify-content-center">
                            {planList.length > 0 && renderPlanList()}
                            <Elements stripe={stripePromise}>
                                <MyPaymentModal isOpen={isModalOpen} onClose={closeModal} updatePlan={updatePlan} />
                            </Elements>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManagePlan;
