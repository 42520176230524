const STRINGS = {
    login: 'login',
    nameReq : 'Name field is required.',
   
    privacyReq : 'Privacy field is required to checked.',
    termsReq : 'Terms field is required to checked.',
    firstNameReq : 'First name field is required.',
    fieldReq : 'This field is required.',
    insuranceUrlReq : 'This field is required.',
    dateOfBirthReq : 'Date of birth field is required.',
    zipCodeReq : 'Zip code field is required.',
    invalidZipCode : 'Invalid zip code.',
    invalidInsuranceUrl : 'Invalid url.',
    policiesReq : 'At least one policy is required.',
    phoneReq : 'Phone number field is required.',
    phoneInvalid : 'Phone number is invalid',
    messageReq:'Message field is required',
    addressReq : 'Address field is required.',
    countryReq : 'Country field is required.',
    stateReq : 'State field is required.',
    invalidCountry : 'Country field is invalid.',
    invalidState : 'State field is invalid.',
    lastNameReq : 'Last name field is required.',
    displayNameReq : 'Display name field is required.',
    invalidEmail : 'The email must be a valid email address.',
    emailReq : 'Please enter email address',
    invalidPassword : 'Password must be at least 8 characters in length.',
    passwordReq : 'Password field is required.',
    newPasswordReq : 'New Password field is required.',
    currentPasswordReq : 'Current Password field is required.',
    invalidConfirmPassword : 'Confirm password must be at least 8 characters in length.',
    confirmPasswordReq :  'Confirm password field is required.',
    passwordMissMatch :  'The password input and confirm password must match.',
    loginFirstBeforeCheckout:'Please login first to proceed checkout.',
    product : {
        imageReq : 'Please select at least one image.',
        userimageReq: 'News Image is required.',
        imageMaxReq : 'You can upload only 6 Files.',
        cantBuyOwnProduct : 'You can`t buy your own product',
        titleReq : 'Title field is required.',
        descriptionReq: 'Description field is required.',
        addressReq: 'Address field is required.',
        deliveryPrice: 'Shipping price is required & must be greater than 0',
        categoryReq : 'Category field is required.',
        statusReq : 'Status field is required.',
        priceReq : 'Price is required & must be greater than 0',
        quantityReq : 'Product in stock field is required.',
        deliveryMethodReq : 'Delivery method field is required.',
        deliveryTimeReq : 'Delivery time field is required.',
        gameReq : 'Item field is required.',
        itemTypeReq : 'Item type field is required.',
        outOfStock : 'Product is already sold out',
        thankYouForPurchase: 'Thank you for your Purchase. Order placed successfully.'
    },
    cancelPayment:'You have cancelled your purchase',
    successPayment: 'Thank you for your Purchase. Order placed successfully.'
}
export default STRINGS;