import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    breadCrumb : ['Dashboards', 'Default'],
    pageTitle: 'Dashboard',
    raiseNotificationBell: false,
    isLoggedIn: false,
    loading: false,
    contentLoading: false,
    alert: null,
    user: null,
    token: null,
    isAdmin: false,
    isAsideOpen: false,
    isSubscriptionCanceled: false
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        callCommonAction: (state, action) => {
            return state = { ...state, ...action.payload };
        }
    },
});

// Action creators are generated for each case reducer function
export const { callCommonAction } = commonSlice.actions;

export default commonSlice.reducer;