const appRoutes = {

    embeddableForm: '/user/embeddable-form',
    multiStepFormRoute: '/user/multi-step-form',
    landingPageMultiStepFormRoute: '/pages',

    homePageRoute : '/',
    aboutUsRoute : '/about-us',
    contactUsRoute : '/contact-us',
    faqPageRoute : '/faq',

    
    /**User Links */
    loginRoute : '/user/login',
    registerRoute : '/user/register',
    forgotPasswordRoute : '/user/forgot-password',
    resetPasswordRoute : '/user/reset-password',
    errorListRoute : '/user/error-list',
    invoiceHistoryRoute : '/user/invoice-history',
    dashboardRoute : '/user/dashboard',
	profileRoute: '/user/profile',
    newsDetailRoute : '/user/news-detail',
    notificationRoute: '/user/notifications',
    errorReportRoute: '/user/error-report',
    carrierManagmentRoute: '/user/carrier-management',
    leadListRoute: '/user/lead-list',
    leadDetailRoute: '/user/lead-detail',
    offerManagmentRoute: '/user/offer-management',
    planManagmentRoute: '/user/plan-management',
    policyManagmentRoute: '/user/policy-management',
    paymentHistoryRoute : '/user/payment-history',
    accountSignUpRoute : '/user/aa',
    crmSettingsRoute : '/user/crm-settings',
    scopeRoute : '/user/scope',
    embeddableFormContentRoute : '/user/embeddable-form-content',

    /**Admin Links */
    adminLoginRoute : '/admin/login',
    adminForgotPasswordRoute : '/admin/forgot-password',
    adminResetPasswordRoute : '/admin/reset-password',
    adminDashboardRoute : '/admin/dashboard',
    adminPolicyAddEditRoute : '/admin/policy-form',
    adminQuestionAddEditRoute : '/admin/question-form',
    adminPolicyListRoute : '/admin/policy-list',
    adminPolicyDetailRoute : '/admin/policy-detail',
    adminManagePolicyRoute : '/admin/policy-manage',
    adminManagePlanRoute : '/admin/plan-manage',
    adminUserListRoute : '/admin/user-list',
    adminNewsListRoute : '/admin/news-list',
    adminNewsAddEditRoute : '/admin/news-form',
    adminNewsDetailRoute : '/admin/news-detail',
    adminInvoiceHistoryRoute : '/admin/invoice-history',
    adminPaymentHistoryRoute : '/admin/payment-history',
    adminOfferFormRoute: '/admin/offer-form',
    adminPlanFormRoute: '/admin/plan-form',
    adminOfferListRoute: '/admin/offer-list',
    adminOfferDetailRoute: '/admin/offer-detail',
    adminCarrierDetailRoute: '/admin/carrier-detail',
    adminProfileRoute: '/admin/profile',
    adminUserDetailRoute: '/admin/user',
    adminPlanListRoute: '/admin/plan-list',
    adminErrorReportRoute: '/admin/error-report',
    adminCarrierListRoute: '/admin/carrier-list',
    adminLeadsListRoute: '/admin/lead-list',
    adminQuestionListRoute: '/admin/question-list',
    adminPageVideoRoute: '/admin/page-video',
    
}
export default appRoutes