import React from 'react';

const AccountSignUp = () => {
  return (
    <>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep last" id="kt_create_account_stepper" data-kt-stepper="true">
        <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
          <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y hhh bgi-size-cover bgi-position-center" style={{ backgroundImage: 'url("' + process.env.REACT_APP_FRONTEND + '"/assets/auth-bg.png")' }}>
            <div className="d-flex flex-center py-10 py-lg-20 mt-lg-20">
              <a href="/metronic8/demo1/index.html">
                <img alt="Logo" src="/iquote-white.png" className="h-70px" />
              </a>
            </div>
            <div className="d-flex flex-row-fluid justify-content-center p-10">
              <div className="stepper-nav">
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon rounded-3">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title fs-2">
                        Account Info
                      </h3>
                      <div className="stepper-desc fw-normal">
                        Setup your account settings
                      </div>
                    </div>
                  </div>
                  <div className="stepper-line h-40px">
                  </div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title ">
                        Billing Details
                      </h3>
                      <div className="stepper-desc fw-normal">
                        Provide your payment info
                      </div>
                    </div>
                  </div>
                  <div className="stepper-line h-40px">
                  </div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title ">
                        Completed
                      </h3>
                      <div className="stepper-desc fw-normal">
                        Your account is created
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap px-5 py-10">
              <div className="d-flex fw-normal">
                <a href="#" className="text-white-50 px-5" target="_blank">Terms</a>
                <a href="#" className="text-white-50 px-5" target="_blank">Plans</a>
                <a href="#" className="text-white-50 px-5" target="_blank">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
              <form className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" id="kt_create_account_form" data-select2-id="select2-data-kt_create_account_form">
                {/* Tab 1 START */}
                <div className="current" data-kt-stepper-element="content">
                  <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                      <h2 className="fw-bold text-gray-900">Account Info</h2>
                      <div className="text-muted fw-semibold fs-6">
                        If you need more info, please check out
                        <a href="#" className="link-primary fw-bold">Help Page</a>.
                      </div>
                    </div>
                    <div className="row">
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bolder text-gray-900 fs-6">First Name</label><input placeholder="Enter first name" type="text" autoComplete="off" name="first_name" className="form-control bg-transparent " />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block"><span role="alert" /></div>
                        </div>
                      </div>
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Last Name</label><input placeholder="Enter last name" type="text" autoComplete="off" name="last_name" className="form-control bg-transparent " />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block"><span role="alert" /></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Display Name</label><input placeholder="Enter display name" type="text" autoComplete="off" name="display_name" className="form-control bg-transparent " />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block"><span role="alert" /></div>
                        </div>
                      </div>
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Phone Number</label><input placeholder="Phone Number" className="form-control bg-transparent " type="tel" autoComplete="tel" />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block"><span role="alert" /></div>
                        </div>
                      </div>
                    </div>
                    <div className="fv-row mb-8 ">
                      <label className="form-label fw-bolder text-gray-900 fs-6">Email</label><input placeholder="Email" type="email" autoComplete="off" name="email" className="form-control bg-transparent " />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"><span role="alert" /></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="fv-row mb-8 col" data-kt-password-meter="true">
                        <div className="mb-1">
                          <label className="form-label fw-bolder text-gray-900 fs-6">Password</label>
                          <div className="position-relative mb-3">
                            <input type="password" placeholder="Password" autoComplete="off" name="password" className="form-control bg-transparent " />
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block"><span role="alert" /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-5 col">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Confirm Password</label><input type="password" placeholder="Confirm Password" autoComplete="off" name="password_confirmation" className="form-control bg-transparent " />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block"><span role="alert" /></div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                {/* Tab 1 END */}

                {/* Tab 2 START */}
                <div className="" data-kt-stepper-element="content">
                  <div className="w-100" data-select2-id="select2-data-26-byp7">
                    <div className="pb-10 pb-lg-15">
                      <h2 className="fw-bold text-gray-900">Billing Details</h2>
                      <div className="text-muted fw-semibold fs-6">
                        If you need more info, please check out
                        <a href="#" className="text-primary fw-bold"> Help Page</a>.
                      </div>
                    </div>
                    <div className="mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="d-flex align-items-center form-label mb-5">
                        Select Account Plan
                        <span className="ms-1" data-bs-toggle="tooltip" aria-label="Monthly billing will be based on your account plan" data-bs-original-title="Monthly billing will be based on your account plan" data-kt-initialized={1}>
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span> </label>
                      <div className="mb-0">
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label">
                              <i class="ki-duotone ki-file-sheet fs-2x ">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                </i> </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold text-gray-800 text-hover-primary fs-5">Plan 1</span>
                              <span className="fs-6 fw-semibold text-muted">Best for startups</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="radio" name="account_plan" defaultValue={1} />
                          </span>
                        </label>
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label">
                              <i class="ki-duotone ki-file-sheet fs-2x ">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                </i> </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold text-gray-800 text-hover-primary fs-5">Plan 2</span>
                              <span className="fs-6 fw-semibold text-muted">Use images to your post
                                time</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="radio" defaultChecked name="account_plan" defaultValue={2} />
                          </span>
                        </label>
                        <label className="d-flex flex-stack mb-0 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label">
                              <i class="ki-duotone ki-file-sheet fs-2x ">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                </i> 
                                </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold text-gray-800 text-hover-primary fs-5">Plan 3</span>
                              <span className="fs-6 fw-semibold text-muted">Use images to enhance time
                                travel rivers</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="radio" name="account_plan" defaultValue={3} />
                          </span>
                        </label>
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                      </div>
                    </div>
                   {/*  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                        <span className="required">Name On Card</span>
                        <span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name" data-bs-original-title="Specify a card holder's name" data-kt-initialized={1}>
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span> </label>
                      <input type="text" className="form-control form-control-solid" placeholder name="card_name" defaultValue="Max Doe" />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                      </div>
                    </div> */}
                    <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="required fs-6 fw-semibold form-label mb-2">Card Number</label>
                      <div className="position-relative">
                        <input type="text" className="form-control form-control-solid" placeholder="Enter card number" name="card_number" defaultValue="4111 1111 1111 1111" />
                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                          <img src="/metronic8/demo1/assets/media/svg/card-logos/visa.svg" alt="" className="h-25px" />
                          <img src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" alt="" className="h-25px" />
                          <img src="/metronic8/demo1/assets/media/svg/card-logos/american-express.svg" alt="" className="h-25px" />
                        </div>
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-md-8 fv-row" data-select2-id="select2-data-25-7zpu">
                        <label className="required fs-6 fw-semibold form-label mb-2">Expiration Date</label>
                        <div className="row fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid" data-select2-id="select2-data-24-7p1r">
                          <div className="col-6" data-select2-id="select2-data-23-7eqz">
                            <select name="card_expiry_month" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Month" data-select2-id="select2-data-4-6ll9" tabIndex={-1} aria-hidden="true" data-kt-initialized={1}>
                              <option data-select2-id="select2-data-6-trgv" />
                              <option value={1} data-select2-id="select2-data-28-omtd">1</option>
                              <option value={2} data-select2-id="select2-data-29-pbib">2</option>
                              <option value={3} data-select2-id="select2-data-30-5hcg">3</option>
                              <option value={4} data-select2-id="select2-data-31-acb9">4</option>
                              <option value={5} data-select2-id="select2-data-32-tgs3">5</option>
                              <option value={6} data-select2-id="select2-data-33-ku4z">6</option>
                              <option value={7} data-select2-id="select2-data-34-yhod">7</option>
                              <option value={8} data-select2-id="select2-data-35-u6zq">8</option>
                              <option value={9} data-select2-id="select2-data-36-29ke">9</option>
                              <option value={10} data-select2-id="select2-data-37-vmsu">10</option>
                              <option value={11} data-select2-id="select2-data-38-5wpj">11</option>
                              <option value={12} data-select2-id="select2-data-39-z9a9">12</option>
                            </select><span className="select2 select2-container select2-container--bootstrap5 select2-container--below" dir="ltr" data-select2-id="select2-data-5-44yb" style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-card_expiry_month-5a-container" aria-controls="select2-card_expiry_month-5a-container"><span className="select2-selection__rendered" id="select2-card_expiry_month-5a-container" role="textbox" aria-readonly="true" title={3}>3</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                            </div>
                          </div>
                          <div className="col-6" data-select2-id="select2-data-41-1kb9">
                            <select name="card_expiry_year" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Year" data-select2-id="select2-data-7-byq9" tabIndex={-1} aria-hidden="true" data-kt-initialized={1}>
                              <option data-select2-id="select2-data-9-8ifc" />
                              <option value={2024} data-select2-id="select2-data-42-8d28">2024
                              </option>
                              <option value={2025} data-select2-id="select2-data-43-uxof">2025
                              </option>
                              <option value={2026} data-select2-id="select2-data-44-8pg4">2026
                              </option>
                              <option value={2027} data-select2-id="select2-data-45-0t3s">2027
                              </option>
                              <option value={2028} data-select2-id="select2-data-46-sl2d">2028
                              </option>
                              <option value={2029} data-select2-id="select2-data-47-w7vs">2029
                              </option>
                              <option value={2030} data-select2-id="select2-data-48-4ymb">2030
                              </option>
                              <option value={2031} data-select2-id="select2-data-49-xn76">2031
                              </option>
                              <option value={2032} data-select2-id="select2-data-50-332m">2032
                              </option>
                              <option value={2033} data-select2-id="select2-data-51-grab">2033
                              </option>
                              <option value={2034} data-select2-id="select2-data-52-qga1">2034
                              </option>
                            </select><span className="select2 select2-container select2-container--bootstrap5 select2-container--below" dir="ltr" data-select2-id="select2-data-8-8wtc" style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-card_expiry_year-2g-container" aria-controls="select2-card_expiry_year-2g-container"><span className="select2-selection__rendered" id="select2-card_expiry_year-2g-container" role="textbox" aria-readonly="true" title={2027}>2027</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                          <span className="required">CVV</span>
                          <span className="ms-1" data-bs-toggle="tooltip" aria-label="Enter a card CVV code" data-bs-original-title="Enter a card CVV code" data-kt-initialized={1}>
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span> </label>
                        <div className="position-relative">
                          <input type="text" className="form-control form-control-solid" minLength={3} maxLength={4} placeholder="CVV" name="card_cvv" />
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <i className="ki-duotone ki-credit-cart fs-2hx"><span className="path1" /><span className="path2" /></i>
                          </div>
                        </div>
                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-stack">
                      <div className="me-5">
                        <label className="fs-6 fw-semibold form-label">Save Card for further billing?</label>
                        <div className="fs-7 fw-semibold text-muted">If you need more info, please check budget
                          planning</div>
                      </div>
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked="checked" />
                        <span className="form-check-label fw-semibold text-muted">
                          Save Card
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Tab 2 END */}

                {/* Tab 3 START */}
                <div className="" data-kt-stepper-element="content">
                  <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" height="auto" style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                      <tr>
                        <td align="center" valign="center" style={{ textAlign: 'center', paddingBottom: '10px' }}>
                          <div style={{ textAlign: 'center', margin: '0 60px 34px 60px' }}>
                            <div style={{ marginBottom: '10px' }}>
                              <a href="#" rel="noopener" target="_blank">
                                <img alt="Logo" src="/Logo-iQuote-black.png" style={{ height: '35px' }} />
                              </a>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                              <img alt="Logo" src="/icon-positive-vote-3.svg" />
                            </div>
                            <div style={{ fontSize: '14px', fontWeight: 500, marginBottom: '42px',  }}>
                              <p style={{ marginBottom: '9px', color: '#181C32', fontSize: '22px', fontWeight: 700 }}>Hey Marcus, thanks for signing up!</p>
                              <p style={{ marginBottom: '2px', color: '#7E8299' }}>Lots of people make mistakes while creating</p>
                              <p style={{ marginBottom: '2px', color: '#7E8299' }}>paragraphs. Some writers just put whitespace in</p>
                              <p style={{ marginBottom: '2px', color: '#7E8299' }}>their text in random places</p>
                            </div>
                            
                            <a href="/metronic8/demo1/apps/invoices/view/invoice-1.html" target="_blank" className='btn btn-primary'>
                              Dashboard
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="center" style={{ fontSize: '13px', textAlign: 'center', padding: '0 10px 10px 10px', fontWeight: 500, color: '#A1A5B7',  }}>
                          <p style={{ color: '#181C32', fontSize: '16px', fontWeight: 600, marginBottom: '9px' }}>It’s all about customers!</p>
                          <p style={{ marginBottom: '2px' }}>Call our customer care number: +31 6 3344 55 56</p>
                          <p style={{ marginBottom: '4px' }}>You may reach us at <a href="#" rel="noopener" target="_blank" style={{ fontWeight: 600 }}>devs.keenthemes.com</a>.</p>
                          <p>We serve Mon-Fri, 9AM-18AM</p>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="center" style={{ textAlign: 'center', paddingBottom: '20px' }}>
                          <a href="#" style={{ marginRight: '10px' }}><img alt="Logo" src="/icon-linkedin.svg" /></a>
                          <a href="#" style={{ marginRight: '10px' }}><img alt="Logo" src="/icon-facebook.svg" /></a>
                          <a href="#"><img alt="Logo" src="/icon-twitter.svg" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Tab 2 END */}
                <div className="d-flex flex-stack pt-15">
                  <div className="mr-2">
                    <button type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                      <i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1" /><span className="path2" /></i> Previous
                    </button>

                  </div>
                  <div>
                    <button type="button" className="btn btn-lg btn-primary d-inline-block d-none">
                      <span className="indicator-label">
                        Submit
                        <i className="ki-duotone ki-arrow-right fs-4 ms-2"><span className="path1" /><span className="path2" /></i> </span>
                      <span className="indicator-progress">
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                    <button type="button" className="btn btn-lg btn-primary">
                      Continue
                      <i className="ki-duotone ki-arrow-right fs-4 ms-1"><span className="path1" /><span className="path2" /></i> </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSignUp;
