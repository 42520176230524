import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({marginCustom}) => {

    const navigate = useNavigate();
    //console.log(marginCustom,'marginCustom')
    return (
        
        <div className={`mb-${marginCustom !== undefined ? marginCustom : '5'}`}>
            <span className="btn btn-primary btn-sm" onClick={() => navigate(-1)}>
                <i className="ki-duotone ki-left"></i>Back
            </span>
        </div>
    );
};

export default BackButton;
