import { toast } from 'react-toastify'; // then this
import { store } from '../app/store';
import { callCommonAction } from '../redux/Common/CommonReducer';
import Helper from './Helper';


const handleApiResponse = (res) => {
    switch (res.data.type) {
        case "success":
            handleSuccess(res);
            break;
        case "validation_error":
            handleValidationError(res);
            break;
        case "failure":
            handleFailure(res);
            break;
        default:
            // Handle any other types if needed
            break;
    }
};

const handleSuccess = async (res) => {
    // Check subscription status
    if (res.data.subscription !== undefined && res.data.subscription !== null) {
        updateSubscriptionStatus(res.data.subscription);
    }

    if (res.data.message !== '') {
        toast.success(res.data.message, { toastId: 'success1' });
    }
};


/** Update Redux and localstorge according result **/
const updateSubscriptionStatus = (subscription) => {

    localStorage.setItem('subscription',subscription === null ? null :  JSON.stringify(subscription))
    // Check if the subscription is canceled
    if (subscription.stripe_status === "canceled" || subscription.canceled_at !== null) {
        store.dispatch(callCommonAction({ isSubscriptionCanceled: true }));
        store.dispatch(callCommonAction({ subscription: subscription }));
        localStorage.setItem('isSubscriptionCanceled', true)
    } else {
        store.dispatch(callCommonAction({ isSubscriptionCanceled: false }));
        localStorage.removeItem('isSubscriptionCanceled', false);
    }

};

const handleValidationError = (res) => {

    if (res.data.subscription != undefined) {
        updateSubscriptionStatus(res.data.subscription);
    }

    // Handle validation error specific logic if needed
    const validationError = res.data.data.errors;

    if (validationError && Object.keys(validationError).length > 0) {
        const firstErrorKey = Object.keys(validationError)[0];
        const firstErrorMessage = validationError[firstErrorKey][0];
        toast.error(firstErrorMessage, { toastId: 'success1' });
        // Handle validation error specific logic if needed
    } else {
        // Handle in case of unexpected structure or empty validationError
        toast.error('Validation error occurred.', { toastId: 'success1' });
    }
};

const handleFailure = (res) => {
    if (res.data.tokenExpired !== undefined && res.data.tokenExpired) {
        // Token expired, perform logout and cleanup
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.subscription !== undefined && localStorage.subscription !== null && localStorage.removeItem('subscription')
        toast.error('Logged out due to token expiration.', { toastId: 'success1' });
        setTimeout(() => window.location.reload(), 1000);
    } else {
        toast.error(res.data.message, { toastId: 'success1' });
    }
};

export { handleApiResponse };
