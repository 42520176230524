import { AppSettings } from '../configs';
import axios from 'axios';
import { store } from '../app/store';

const RESOURCE_URL = AppSettings.base_url;

export const sendRequest = async (endpoint, method, body) => {
    const usertoken = store.getState().common.token;
    const authHeader = { 'Authorization': 'Bearer ' + usertoken, 'Accept': 'application/json' };
    const REQUEST_URL = RESOURCE_URL + endpoint;
    const requestOptions = method === 'POST' ? { method: 'POST', url: REQUEST_URL, data: body, headers: authHeader } : { method: 'GET', url: REQUEST_URL, params: body, headers: authHeader};
    // Add responseType: 'blob' for GET requests that handle file downloads
    if (method === 'GET' && endpoint.includes('download-scope-appointment-pdf')) {
        requestOptions.responseType = 'blob'; // Set response type to blob for file download
    }
    return await axios(requestOptions);
};