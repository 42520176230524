import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const MyPaymentModal = ({ isOpen, onClose, updatePlan }) => {
	const [errorMessage, setErrorMessage] = useState('');
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			return;
		}

		const cardElement = elements.getElement(CardElement);

		const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: cardElement });

		if (error) {
			console.error(error.message);
			setErrorMessage(error.message);
		} else {
			// Send the paymentMethod.id to your server to complete the payment.
			updatePlan(paymentMethod);
			setErrorMessage('');
			onClose(); // Close the modal after successful payment
		}
	};

	return (


		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Payment Details</Modal.Title>
			</Modal.Header>
			<form onSubmit={handleSubmit}>
				<Modal.Body>

					<div className="form-group">
						<label htmlFor="card-element" className='mb-2'>Card Element</label>
						<CardElement
							id="card-element"
							options={{
								style: {
									base: {
										fontSize: '16px',
										color: '#424770',
										'::placeholder': {
											color: '#aab7c4',
										},
									},
									invalid: {
										color: '#9e2146',
									},
								},
							}}
						/>
					</div>
					{errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}


				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={onClose}>Close</Button>
					<button type="submit" className="btn btn-primary">Submit Payment</button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default MyPaymentModal;
