import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validateCmdFormData(page, userDetail, fieldName, isValidPhoneNumber) {
    const { first_name, last_name, email, phonenumber, message, title, url } = userDetail;
    let errorMsg = ''; let errors =  {}; let formVaildCheck = true;

    if (page === 'contact-us') {
        if (last_name === '' && (fieldName == 'last_name' || fieldName == '')) {
            errorMsg = last_name === '' ? STRINGS.lastNameReq : '';
            errors.last_name = errorMsg;
            formVaildCheck = false;
        }
        if (first_name === '' && (fieldName == 'first_name' || fieldName == '')) {
            errorMsg = first_name === '' ? STRINGS.firstNameReq : '';
            errors.first_name = errorMsg;
            formVaildCheck = false;
        }
        if ((fieldName == 'email' || fieldName == '') && (!Helper.validateEmail(email))) {
            errorMsg = email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
            errors.email = errorMsg;
            formVaildCheck = false;
            
        }
        if ((fieldName == 'phonenumber' || fieldName == '') && (!isValidPhoneNumber(phonenumber))) {
            errorMsg = "Please enter a valid phone number";
            errors.phonenumber = errorMsg;
            formVaildCheck = false;
        }
        if (message === '' && (fieldName == 'message' || fieldName == '')) {
            errorMsg = message === '' ? STRINGS.messageReq : '';
            errors.message = errorMsg;
            formVaildCheck = false;
        }
    }

    if (page === 'page-video') {
        console.log('asdasd',page)
        if (title === '' && (fieldName == 'title' || fieldName == '')) {
            errorMsg = title === '' ? STRINGS.lastNameReq : '';
            errors.title = errorMsg;
            formVaildCheck = false;
        }
        if (url === '' && (fieldName == 'url' || fieldName == '')) {
            errorMsg = url === '' ? STRINGS.firstNameReq : '';
            errors.url = errorMsg;
            formVaildCheck = false;
        }
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}