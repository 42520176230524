import axios from 'axios';
import appSettings from '../configs/AppConfig';
export default class Helper {

    /** Validate email address */
    static validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /** Validate mobile */
    static validateMobile(number) {
        return (number && number.length >= 10 && number.length <= 12);
    }

    /** Validate password */
    static validatePassword = (password) => {
        return (password && password.length >= 8) ? true : false;
    };

    static isNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return (evt && evt.length >= 0) ? true : false;;
    }


    static setUrlQueryParam = (name, value) => {
        // Construct URLSearchParams object instance from current URL querystring.
        var queryParams = new URLSearchParams(window.location.search);

        // Set new or modify existing parameter value. 
        queryParams.set(name, value);

        // Replace current querystring with the new one.
        window.history.replaceState(null, null, "?" + queryParams.toString());
    };

   
    static invoiceStatus = {
        'draft': { className: 'info', msg: 'The invoice isn’t ready to use. All invoices start in draft status.' },
        'open': { className: 'primary', msg: 'The invoice is finalized and awaiting payment.' },
        'paid': { className: 'success', msg: 'This invoice is paid.' },
        'void': { className: 'danger', msg: 'This invoice is canceled.' },
        'uncollectible': { className: 'warning', msg: 'The customer is unlikely to pay the invoice. Normally, you treat it as bad debt in your accounting process.' },
    };

}