import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import appSettings from '../../../configs/AppConfig';
import Pagination from '../../../common/Pagination/pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Alerts from '../../../common/Alerts/Alerts';
import { Helmet } from 'react-helmet';

const List = () => {
	const [policyList, setPolicyList] = useState([]);
	const { isAdmin, alert, isLoggedIn } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
	const [filter, setFilter] = useState({ search_string: '', status: 1 });
	const navigate = useNavigate();
	useEffect(() => {
		if (!isAdmin) navigate(appRoutes.adminLoginRoute);
		if (filter.search_string.length > 2 || filter.search_string === "") {
			getPolicyList(1);
		}
	}, [filter.search_string]);

	const getPolicyList = async (page) => {
		try {
			dispatch(callCommonAction({ loading: true, breadCrumb: ['Policy', 'List'] }));
			const res = await sendRequest('/policy/list', 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string });
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setPolicyList(res.data.data.docs);
				setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page : page });
			}
		} catch (error) {
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	/********Before Delete*******/
	const confirmBeforeDelete = (selectedId) => {
		const custom = Alerts.confirmDelete(selectedId, deleteSelectedPolicy);
		dispatch(callCommonAction({ alert: custom }));
	};


	/*********After confirmation delete data*********/
	const deleteSelectedPolicy = async (selectedId) => {
		dispatch(callCommonAction({ alert: null }));
		if (selectedId) {
			dispatch(callCommonAction({ loading: true }));
			try {
				const res = await sendRequest(`/policy/delete`, 'POST', { policy_id: selectedId });
				handleApiResponse(res);
				if (res.data.type === "success") {
					getPolicyList(1);
				}
			} catch (error) {
				console.error('deleteSelectedPolicy', error);
				toast.error('Something went wrong');
			}

		}
	};


	/******Status Update For Policy*** */
	const handleChange = async (event, policyId) => {
		try {
			let newStatus = event.target.checked ? 1 : 0;
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest('/policy/update-status/' + policyId, 'GET', { status: newStatus });
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				getPolicyList(1);
			}
		} catch (error) {
			//console.log('asxsa', error);
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	const renderTabeBody = () => {
		return policyList.map((policy, index) => {
			return (
				<tr key={policy.id}>
					<td>{policy.name}</td>
					{/* <td>{policy.des}</td> */}
					<td>
						<div className={"text-center"}>
							<Form.Check // prettier-ignore
								type="switch"
								id="custom-switch"
								defaultChecked={policy.status === 1 ? true : false}
								onChange={(e) => handleChange(e, policy.id)}
							/>
						</div>
					</td>
					<td className="text-end">
						<Dropdown>
							<Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
								Actions
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminPolicyDetailRoute + '/' + policy.id)}>View</Dropdown.Item>
								{/* <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminManagePolicyRoute + '/' + policy.id)}>Manage Policy</Dropdown.Item> */}
								<Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminPolicyAddEditRoute + '/' + policy.id)}>Manage Policy</Dropdown.Item>
								{/* <Dropdown.Item href={undefined} onClick={() => confirmBeforeDelete(policy.id)}>Delete</Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
					</td>
				</tr>
			);
		});

	};

	return (
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<Helmet>
                <title>iQuote | Policy List</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
			{alert}
			{/**begin::Post**/}
			<div className="post d-flex flex-column-fluid" id="kt_post">
				{/**begin::Container**/}
				<div id="kt_content_container" className="container-xxl">
					{/**begin::Card**/}
					<div className="card">
						{/**begin::Card header**/}
						<div className="card-header">
							{/**begin::Card title**/}
							<div className="card-title">
								{/**begin::Search**/}
								<div className="d-flex align-items-center position-relative my-1">
									<i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
										<span className="path1"></span>
										<span className="path2"></span>
									</i>
									<input type="text" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search policy" />
								</div>
								{/**end::Search**/}
							</div>
							{/**begin::Card title**/}
							{/**begin::Card toolbar**/}
							<div className="card-toolbar">
								<div className="d-flex align-items-center">
									<div className="d-flex justify-content-end">
										<button onClick={() => { navigate(appRoutes.adminPolicyAddEditRoute); }} className="btn btn-primary btn-sm" ><i className="ki-duotone ki-plus fs-2"></i>Add</button>
									</div>
								</div>
								{/**end::Toolbar**/}
							</div>
							{/**end::Card toolbar**/}
						</div>
						{/**end::Card header**/}
						{/**begin::Card body**/}
						<div className="card-body table-frame py-0">
							{/**begin::Table wrapper**/}
							<div className="table-responsive">
								{/**begin::Table**/}
								<table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5" id="kt_table_customers_logs">
									{/**begin::Table body**/}
									<thead>
										<tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
											<th className="min-w-125px">Name</th>
											{/* <th className="min-w-125px">Description</th> */}
											<th className="min-w-125px text-center">Status</th>
											<th className="min-w-125px text-end">Action</th>
										</tr>
									</thead>
									<tbody>
										{policyList.length > 0 ? renderTabeBody() : <tr>
											<td colSpan={3} className='text-center'>No result found!</td>
										</tr>}
									</tbody>
									{/**end::Table body**/}
								</table>
								{/**end::Table**/}
								
							</div>
							<div className='pag mb-4'>
									<Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
										pageSize={paginationData.perpage} onPageChange={page => getPolicyList(page, filter.status)}
									/>
								</div>
							{/**end::Table wrapper**/}
						</div>
						{/**end::Card body**/}
					</div>
					{/**end::Card**/}
				</div>
			</div>
		</div>
	);
};

export default List;
