import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({ onSave, setSignatureImage }) => {
    const signatureRef = useRef();

    const handleSave = async  () => {
        const signatureData = signatureRef.current.toDataURL();
        await onSave(signatureData);
        setSignatureImage(signatureData); // Set the signed image for visualization
    };

    const handleClear = () => {
        signatureRef.current.clear(); // Clear the canvas
        setSignatureImage(null); // Clear the signed image
    };


    return (
        <div className='text-center'>
            <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 700, height: 300, className: 'border border-dark-subtle rounded-1 shadow sigCanvas mb-3' }}
                ref={signatureRef}
            />
            <div className='d-flex gap-3 justify-content-center' >
                <Button variant='dark' size='sm' onClick={handleClear}>Clear</Button>
                <Button size='sm' onClick={handleSave}>Save Signature</Button>
            </div>
            
        </div>
    );
};

export default SignaturePad;
