import React, { useEffect, useState } from "react";
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { handleApiResponse } from "../../apis/apiUtils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormData from './FormQuestion';
import { validateFormData } from "./Validation";
import Select from 'react-select';
import { Helmet } from 'react-helmet';
import appRoutes from '../../configs/AppRoutes';
import moment from 'moment';
import _ from 'lodash';


const calendyBtn = (link) => {
    const handleCalendyButton = (link) => {
        window.open(link, '_blank');
    };
    return (
        <div className="col">
            <button type="button" onClick={() => handleCalendyButton(link)} className="btn btn-lg btn-primary w-100 mb-5 shedule-btn" >
                <i className="ki-duotone ki-telephone-geolocation fs-2 white">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>Schedule Call
            </button>
        </div>
    );
};

const checkScreenContent = (mainData, key) => {
    const sanitizeHTML = (html) => {
        return { __html: html };
    };

    if (key === 'firstScreenEditorContent') {
        return (
            <h4 className="mb-5" dangerouslySetInnerHTML={sanitizeHTML(mainData?.embeddableFormContent?.[key] ? mainData?.embeddableFormContent?.[key] : `Let's start with your Basic Information`)} />
        );
    } else if (key === 'lastScreenEditorContent1') {
        return (
            <p className="fs-5" dangerouslySetInnerHTML={sanitizeHTML(mainData?.embeddableFormContent?.[key] ? mainData?.embeddableFormContent?.[key] : `We're all done! I will get to work and send you the quotes for all of the products you requested information on. </br></br> My iQuote engine is getting some initial price points for some of your requests, including:`)} />
        );
    } else if (key === 'lastScreenEditorContent2') {
        return (
            <p className="fs-5" dangerouslySetInnerHTML={sanitizeHTML(mainData?.embeddableFormContent?.[key] ? mainData?.embeddableFormContent?.[key] : `Please schedule a call with me to review the quotes:`)} />
        );
    }
};

const Step1 = ({ mainData, nextStep, formErrors, formData, handleChange, handleDateChange }) => (

    <div>
        {checkScreenContent(mainData, 'firstScreenEditorContent')}
        {/* <h4 className="mb-5">Step : {formData.step}</h4> */}
        <div className="row">
            <div className="fv-row mb-4 col">
                <input
                    placeholder="First Name"
                    type="text"
                    name="first_name"
                    className="form-control bg-transparent fw-normal"
                    onChange={(e) => handleChange(e, 'form1')}
                    value={formData.form1.first_name}
                />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.first_name}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">

            <div className="fv-row mb-4 col">
                <input
                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    className="form-control bg-transparent fw-normal"
                    onChange={(e) => handleChange(e, 'form1')}
                    value={formData.form1.last_name}
                />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.last_name}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">

            <div className="fv-row mb-4 col">
                <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    className="form-control bg-transparent fw-normal"
                    onChange={(e) => handleChange(e, 'form1')}
                    value={formData.form1.email}
                />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.email}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">

            <div className="fv-row mb-4 col">
                <DatePicker
                    selected={formData.form1.date_of_birth}
                    onChange={handleDateChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Date of Birth"
                    className="form-control bg-transparent fw-normal"
                    maxDate={new Date()} // Set the maximum allowed date to the current date
                />
                <div className="fv-plugins-message-container w-100">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.date_of_birth}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            {/* {mainData?.userData?.insurance_url ? alert(mainData.userData.insurance_url) : null} */}
            <div className="col">
                <button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={nextStep}>Next<i className="ki-duotone ki-right p-0 fs-4"></i></button>
            </div>

        </div>
    </div>
);

const Step2 = ({ mainData, nextStep, prevStep, formErrors, formData, handleChange, handlePhoneChange }) => (
    <div>
        {/* {console.log('form2 ===> ', formData)} */}
        <h4 className="mb-5">Great! What's the best Mobile Number For me to use for Quotes updates? </h4>
        {/* <h4 className="mb-5">Step : {formData.step}</h4> */}
        <div className="row">
            <div className="fv-row mb-4 col">
                <Input
                    country="US"
                    placeholder="Phone Number"
                    className="form-control bg-transparent fw-normal"
                    onChange={(e) => handlePhoneChange(e)}
                    value={formData.form2.phone_number}
                />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.phone_number}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="fv-row mb-4 col">
                <label className="form-label fw-bolder text-gray-900 fs-6">
                    What is your Zip Code?
                </label>
                <input
                    placeholder="Zip Code"
                    type="text"
                    name="zip_code"
                    className="form-control bg-transparent fw-normal"
                    onChange={(e) => handleChange(e, 'form2')}
                    value={formData.form2.zip_code}
                />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.zip_code}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col"><button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={prevStep}><i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
            <div className="col"><button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={nextStep}>Next<i className="ki-duotone ki-right p-0 fs-4"></i></button>
            </div>

        </div>

    </div>
);

const Step3 = ({ mainData, prevStep, nextStep, formErrors, formData, handlePolicyChange, policyList }) => {

    return (
        <div>
            <h4 className="mb-5">Please select the products you'd like me to help you with</h4>
            {/* <h4 className="mb-5">Step : {formData.step}</h4> */}
            <div className='row mb-5'>
                <div className='col-12 mb-5'>
                    {

                        policyList.map((policy, index) => {
                            if (policy.status) {
                                if (process.env.REACT_APP_POLICY_ID == policy.id) {
                                    //if (mainData.subscription.plan.plan_features.includes(process.env.REACT_APP_PLAN_FEATURE_CRM_UPLOAD_ID)) {
                                    return (
                                        <div className='check-list float-start mb-3 pe-2 w-50 fs-5' key={index}>
                                            <input
                                                type="checkbox"
                                                name="policies"
                                                id={policy._id}
                                                value={policy._id}
                                                checked={formData.form3.policies.includes(policy._id)}
                                                onChange={(e) => handlePolicyChange(e, 'form3')}
                                            />
                                            <label className="cursor-pointer" htmlFor={policy._id}>{policy.name}</label>
                                        </div>
                                    );
                                    //}
                                } else {
                                    return (
                                        <div className='check-list float-start mb-3 pe-2 w-50 fs-5' key={index}>
                                            <input
                                                type="checkbox"
                                                name="policies"
                                                id={policy._id}
                                                value={policy._id}
                                                checked={formData.form3.policies.includes(policy._id)}
                                                onChange={(e) => handlePolicyChange(e, 'form3')}
                                            />
                                            <label className="cursor-pointer" htmlFor={policy._id}>{policy.name}</label>
                                        </div>
                                    );
                                }

                            }

                        })
                    }

                </div>
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.policies}</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col"><button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={prevStep}><i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
                <div className="col"><button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={nextStep}>Next<i className="ki-duotone ki-right p-0 fs-4"></i></button></div>
            </div>
        </div>
    );
};

const Step4 = ({ mainData, nextStep, prevStep, formErrors, formData, setFormData, setFormErrors, attribute, setAttribute, stateList, healthList, catList, carrierList, selectedStateOption, selectedCatOption, selectedHealthOption, selectedCarrierOption, onChangeState, onChangeCat, onChangeHealth, onChangeCarrier }) => {

    const validateStep4 = () => {
        let erObj = {}; let allValidField = true;
        //console.log('attribute', attribute);
        // console.log('formData.form4', formData.form4);

        if (formData.form3.policies.length > 0 && formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID)) {
            if (!selectedStateOption) {
                erObj['state'] = 'This field is required';
                allValidField = false;
            }
            if (!selectedCatOption) {
                erObj['category'] = 'This field is required';
                allValidField = false;
            }
            if (!selectedHealthOption) {
                erObj['health'] = 'This field is required';
                allValidField = false;
            }
            if (selectedCarrierOption.length === 0) {
                erObj['carrier'] = 'This field is required';
                allValidField = false;
            }
        }

        setFormErrors(erObj);
        if (allValidField) {
            nextStep();
        }
    };

    return (
        <div>
            {/* {console.log('asx', typeof attribute)} */}
            <h4 className="mb-5">Excellent - my iQuote Engine will make this process as easy as possible</h4>

            <h5 className="mb-5">Let's start with this information:</h5>
            {/* <h4 className="mb-5">Step : {formData.step}</h4> */}

            <div className="row">
                {
                    formData.form3.policies.length > 0 && formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID)
                        ?
                        <>
                            <div className="fv-row mb-4 col-12">
                                <label className="form-label fw-bolder text-gray-900 fs-6">Company</label>
                                <div className="col-lg-12 fv-row">
                                    <Select
                                        defaultValue={selectedCarrierOption}
                                        onChange={onChangeCarrier}
                                        options={carrierList}
                                        isMulti
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="fv-plugins-message-container w-100">
                                    <div className="fv-help-block">
                                        <span role="alert">{formErrors.carrier}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="fv-row mb-4 col-12">
                                <label className="form-label fw-bolder text-gray-900 fs-6">Categories</label>
                                <div className="col-lg-12 fv-row">
                                    <Select
                                        defaultValue={selectedCatOption}
                                        onChange={onChangeCat}
                                        options={catList}
                                    />
                                </div>
                                <div className="fv-plugins-message-container w-100">
                                    <div className="fv-help-block">
                                        <span role="alert">{formErrors.category}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="fv-row mb-4 col-12">
                                <label className="form-label fw-bolder text-gray-900 fs-6">State</label>
                                <div className="col-lg-12 fv-row">
                                    <Select
                                        defaultValue={selectedStateOption}
                                        onChange={onChangeState}
                                        options={stateList}
                                    />
                                </div>
                                <div className="fv-plugins-message-container w-100">
                                    <div className="fv-help-block">
                                        <span role="alert">{formErrors.state}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="fv-row mb-4 col-12">
                                <label className="form-label fw-bolder text-gray-900 fs-6">Health</label>
                                <div className="col-lg-12 fv-row">
                                    <Select
                                        defaultValue={selectedHealthOption}
                                        onChange={onChangeHealth}
                                        options={healthList}
                                    />
                                </div>
                                <div className="fv-plugins-message-container w-100">
                                    <div className="fv-help-block">
                                        <span role="alert">{formErrors.health}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                }
            </div>

            <div className="row">
                <div className="col"><button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={prevStep}><i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
                <div className="col"><button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={validateStep4}>Next<i className="ki-duotone ki-right p-0 fs-4"></i></button></div>
            </div>
        </div>
    );

};

const Step5 = ({ mainData, nextStep, prevStep, formErrors, formData, setFormData, setFormErrors, attribute, setAttribute, stateList, healthList, catList, carrierList, selectedStateOption, selectedCatOption, selectedHealthOption, selectedCarrierOption, onChangeState, onChangeCat, onChangeHealth, onChangeCarrier }) => {


    return (
        <div>
            {/* {console.log('asx', typeof attribute)} */}
            <h4 className="mb-5">Excellent - my iQuote Engine will make this process as easy as possible</h4>
            {/* <h4 className="mb-5">Step : {formData.step}</h4> */}
            <h5 className="mb-5">Let's start with this information:</h5>

            <div className="row">
                <FormData
                    formData={formData}
                    setFormData={setFormData}
                    setAttribute={setAttribute}
                    attribute={attribute}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    mainData={mainData}
                />
            </div>
        </div>
    );

};

const Step6 = ({ mainData, prevStep, nextStep, formErrors, formData, handleOfferChange, offerList }) => {

    return (
        <div>
            <h4 className="mb-5">That's all the information I need from you right now! Would you
                like me to also send you information about the following?
                Check all of the below services, or click Next if you're not
                interested in any of them right now.</h4>
            {/* <h4 className="mb-5">Step : {formData.step}</h4> */}
            <div className='row mb-5'>
                <div className='col-12 mb-5'>
                    {
                        offerList.map((offer, index) => {
                            if (!offer.deleted) {
                                return (
                                    <div className='check-list float-start mb-3 pe-2 w-50 fs-5' key={index}>
                                        <input
                                            type="checkbox"
                                            name="offers"
                                            id={offer._id}
                                            value={offer._id}
                                            checked={formData.form5.offers.includes(offer._id)}
                                            onChange={(e) => handleOfferChange(e, 'form5')}
                                        />
                                        <label className="cursor-pointer" htmlFor={offer._id}>{offer.name}</label>
                                    </div>
                                );
                            }

                        })
                    }

                </div>
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                        <span role="alert">{formErrors.offers}</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col"><button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={prevStep}><i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
                <div className="col"><button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={nextStep}>Next<i className="ki-duotone ki-right p-0 fs-4"></i></button></div>
            </div>
        </div>
    );
};

const Step7 = ({ mainData, prevStep, formErrors, formData, handleChange }) => {
    let premiumMValues = [];
    let minPremiumM = 0.00;
    let maxPremiumM = 0.00;
    if (formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID) && formData.form6.length > 0) {
        premiumMValues = formData.form6.map(item => {
            //console.log("Check", Object.keys(item).length);
            if (Object.keys(item).length !== 0) {
                return parseFloat(item.Compulife_premiumM.replace(",", ""));
            }
        });
        // Finding the minimum and maximum values
        minPremiumM = Math.min(...premiumMValues);
        maxPremiumM = Math.max(...premiumMValues);
    }

    const handleReviewButton = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div>
            {/* <h4 className="mb-5">Step : {formData.step}</h4> */}

            {
                checkScreenContent(mainData, 'lastScreenEditorContent1')
            }
            {
                formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID) && formData.form6.length > 0
                    ?
                    <p className="fs-5 mb-10"><strong>Life Insurance: </strong>{'$' + minPremiumM + ' per month - $' + maxPremiumM + ' per month'}</p>
                    :
                    null
                // <p className="fs-5 mb-10">No quote found for company</p>
            }


            {
                checkScreenContent(mainData, 'lastScreenEditorContent2')
            }
            <div className="row">
                {mainData?.userData?.calendy_url ? calendyBtn(mainData?.userData?.calendy_url) : null}
            </div>
            <div className="row flex-column-reverse flex-md-row next-prev-buttons">
                <div className="col">
                    <button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={prevStep}>
                        <i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
                {mainData?.userData?.insurance_url ?
                    <div className="col"><button type="button" onClick={() => handleReviewButton(mainData.userData.insurance_url)} className="btn btn-lg btn-primary w-100 mb-5"><i className="fs-3 ki-duotone ki-messages">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                    </i>Post Review</button></div>
                    :
                    null
                }
                {/* <div className="col"><button type="submit" className="btn btn-lg btn-primary w-100 mb-5">Save</button></div> */}
            </div>
        </div>
    );

};

const MultiStepForm = () => {
    const [formData, setFormData] = useState({
        step: 1,
        form1: {
            first_name: '',
            last_name: '',
            email: '',
            date_of_birth: '',
            sex: '',
            smoker: ''
        },
        form2: {
            phone_number: '',
            zip_code: '',
            price: 0.00,
        },
        form3: { policies: [] },
        form4: [],
        form5: { offers: [] },
        form6: [],
        scopeOfAppointment: {},
        minPremiumM: 0.00,
        maxPremiumM: 0.00,
        combOffers: [],
        combPolicies: [],
    });
    const location = useLocation();
    const currentRouteName = location.pathname;
    const navigate = useNavigate();
    const [attribute, setAttribute] = useState({ select: [], number: [], radio: [], text: [] });
    const [formErrors, setFormErrors] = useState({});
    const [mainData, setMainData] = useState({});
    const { loading1, user, isAdmin } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [selectedStateOption, setSelectedStateOption] = useState(null);
    const [selectedCatOption, setSelectedCatOption] = useState(null);
    const [selectedHealthOption, setSelectedHealthOption] = useState(null);
    const [selectedCarrierOption, setSelectedCarrierOption] = useState([]);
    const onChangeState = (data) => setSelectedStateOption(data);
    const onChangeCat = (data) => setSelectedCatOption(data);
    const onChangeHealth = (data) => setSelectedHealthOption(data);
    const onChangeCarrier = (data) => setSelectedCarrierOption(data);
    const isRouteActive = (...routes) => routes.some(route => location.pathname.includes(route));
    const params = useParams();
    const leadId = params.id !== undefined ? params.id : null;
    let iframe_token = '';
    const url = window.location.href;
    let userFriendly = url.includes('pages') ? 1 : 0;

    // Split the URL by '/'
    var segments = url.split('/');
    // Get the last segment
    var lastSegment = segments[segments.length - 1];

    if (user != null) {


        if (userFriendly) {
            // console.log(lastSegment == user.display_name, 'lastSegment',lastSegment,'user.display_name',user.display_name)
            if (lastSegment != user.display_name) {
                iframe_token = lastSegment; // Now iframe_token is a display_name
            } else {
                iframe_token = user.display_name; // Now iframe_token is a display_name
            }
        } else {
            iframe_token = user.iframe_token;
        }

    } else {

        // Extract the encrypted user ID from the URL
        if (userFriendly) {
            iframe_token = lastSegment; // Now iframe_token is a display_name
        } else {
            iframe_token = new URLSearchParams(window.location.search).get('encryptedId');
        }
    }

    // console.log('iframe_token',iframe_token)

    useEffect(() => {
        if (iframe_token) {
            getMultiFormData();
        } else {
            toast.error('Please browse with correct URL');
        }
    }, []);


    const formSumbitHandle = async (e) => {
        e.preventDefault();
        await createAndUpdateLead(true);
    };

    const createAndUpdateLead = async (lastStepCall) => {
        const newObject = _.cloneDeep(formData);
        newObject['state'] = selectedStateOption;
        newObject['carriers'] = selectedCarrierOption;
        newObject['health'] = selectedHealthOption;
        newObject['category'] = selectedCatOption;
        newObject['attribute'] = attribute;
        newObject['iframe_token'] = iframe_token;
        newObject['userFriendly'] = userFriendly;
        newObject['lastStepCall'] = lastStepCall;

        newObject.form1.date_of_birth = moment(newObject.form1.date_of_birth).format('YYYY-MM-DD');
        //console.log('newObject', newObject);

        try {
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest(`/lead/create`, 'POST', newObject);
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "failure") {
                setFormData({ ...formData, step: 1 });
            }
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading1: false }));
        }
    };

    /** Get List of policy list */
    const getMultiFormData = async () => {
        try {
            dispatch(callCommonAction({ loading1: true, breadCrumb: ['Lead', 'Embeddable form', appRoutes.leadListRoute] }));
            const res = await sendRequest('/user/multi-form', 'POST', { userFriendly: userFriendly, iframe_token: iframe_token, lead_id: leadId });
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setMainData(res.data.data);
                let price = "0";
                let sex = '';
                let smoker = '';
                setSelectedCarrierOption(res.data.data.combineCarriers);
                if (leadId) {
                    /**For DynamiC Attaributes */
                    let attArry = { ...attribute };
                    res.data.data.leadData.leadQuestions.map((item) => {
                        const type = item.value_type;
                        if (type === 'select' || type === 'radio') {
                            if (item.question.name == 'Coverage') {
                                const pricObj = item.question.question_options.find((ele) => ele._id == item.question_options[0]);
                                if (pricObj.hasOwnProperty('_id')) {
                                    price = pricObj.value;
                                }
                            } else if (item.question.name == "Gender" || item.question.name == "Smoker") {
                                const selected = item.question.question_options.find(option => option._id === item.question_options[0]);
                                if (item.question.name == "Gender") {
                                    sex = selected.value;
                                } else {
                                    smoker = selected.value;
                                }
                            }
                            attArry[type].push({ [item.question._id]: item.question_options[0] });
                        } else {
                            attArry[type].push({ [item.question._id]: item.value });
                        }
                    });
                    /**End For DynamiC Attaributes */

                    //console.log('price',price,'sex', sex, 'smoker',smoker);

                    /** Set FormData */
                    setFormData((prev) => ({
                        ...prev,
                        form1: {
                            first_name: res.data.data.leadData.firstName,
                            last_name: res.data.data.leadData.lastName,
                            email: res.data.data.leadData.email,
                            date_of_birth: new Date(res.data.data.leadData.dateOfBirth),
                            sex: sex,
                            smoker: smoker
                        },
                        form2: {
                            phone_number: res.data.data.leadData.phone,
                            zip_code: res.data.data.leadData.postalCode,
                            price: price,
                        },
                        form3: { policies: res.data.data.leadData.policies.map((item) => item._id) },
                        form4: [],
                        form5: { offers: res.data.data.leadData.offers.map((item) => item._id) },
                        form6: [],
                        minPremiumM: res.data.data.leadData.heighestPrice,
                        maxPremiumM: res.data.data.leadData.lowestPrice,
                        combOffers: res.data.data.combineOffers,
                        combPolicies: res.data.data.combinePolicies
                    }));

                    /** End Set FormData */
                    let policyArray = res.data.data.leadData.policies.map((item) => item._id);


                    if (policyArray.includes(process.env.REACT_APP_POLICY_ID)) {

                        const health = res.data.data.healths.find((item) => res.data.data.leadData?.health ? item._id == res.data.data.leadData?.health?._id : item.value === "P");

                        if (health !== undefined && health.hasOwnProperty('_id')) {
                            setSelectedHealthOption(health);
                        }

                        const cat = res.data.data.categories.find((item) => item._id == res.data.data.leadData?.category?._id);
                        if (cat !== undefined && cat.hasOwnProperty('_id')) {
                            setSelectedCatOption(cat);
                        }

                        const state = res.data.data.states.find((item) => item._id == res.data.data.leadData?.state?._id);

                        if (state !== undefined && state.hasOwnProperty('_id')) {
                            setSelectedStateOption(state);
                        }
                    }
                } else {
                    setFormData((prev) => ({
                        ...prev,
                        combOffers: res.data.data.combineOffers,
                        combPolicies: res.data.data.combinePolicies
                    }));
                }
            }
        } catch (error) {
            toast.error(error.message);
            dispatch(callCommonAction({ loading1: false }));
        }
    };

    /** Handle On Date Change */
    const handleDateChange = (date) => {
        formData.form1['date_of_birth'] = date;
        setFormData((prevFormData) => ({
            ...prevFormData,
            form1: {
                ...prevFormData.form1,
                date_of_birth: date,
            },
        }));
        checkFormIsValid('form1', 'date', formData);
    };

    /** Handle Change For All Inputs */
    const handleChange = (e, formType) => {
        const { name, value } = e.target;
        formData[formType][name] = value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [formType]: {
                ...prevFormData[formType],
                [name]: value,
            },
        }));
        checkFormIsValid(formType, name, formData);
    };

    /** handlePhoneChange */
    const handlePhoneChange = (phone_number) => {

        if (phone_number) {
            formData.form2.phone_number = phone_number;
            setFormData((prevFormData) => ({
                ...prevFormData,
                'form2': {
                    ...prevFormData.form2,
                    phone_number: phone_number,
                },
            }));
            checkFormIsValid('form2', phone_number, formData);
        }

    };


    /* Validation implemented here */
    const checkFormIsValid = (formType, fieldName, data) => {
        const res = validateFormData(formType, fieldName, data, isValidPhoneNumber);
        setFormErrors(res.errors);
        return res.formVaildCheck;
    };

    /** Handle On Policy Change */
    const handlePolicyChange = async (e, formType) => {
        const { value, checked, name } = e.target;
        if (checked) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [formType]: {
                    ...prevFormData[formType],
                    [name]: [...prevFormData[formType].policies, value]
                },
            }));
            if (value == process.env.REACT_APP_POLICY_ID) {
                let policyArray = mainData.combinePolicies.map((item) => item._id);

                if (policyArray.includes(process.env.REACT_APP_POLICY_ID)) {
                    const health = mainData.healths.find((item) => item.value === "P");
                    if (health != undefined && health.hasOwnProperty('_id')) {
                        setSelectedHealthOption(health);
                    }
                }
            }
        } else {
            let form6Data = formData.form6;
            let form1Data = { ...formData.form1 };
            if (value == process.env.REACT_APP_POLICY_ID) {
                form6Data = []; //If LIC policy id remove from array then update their array and rest states
                setSelectedStateOption(null);
                setSelectedCatOption(null);
                setSelectedHealthOption(null);
                //setSelectedCarrierOption([]);
                form1Data['sex'] = '';
                form1Data['smoker'] = '';
            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['form6']: form6Data,
                ['form1']: form1Data,
                [formType]: {
                    ...prevFormData[formType],
                    [name]: prevFormData[formType].policies.filter((e) => e !== value)
                },
            }));
        }
    };

    /** Remove question If policy uncheked */
    const removeObjectsIfNotMatchIds = (quesIds) => {
        const updatedData = { ...attribute }; // Create a shallow copy of the state

        for (const key in updatedData) {
            if (updatedData.hasOwnProperty(key)) {
                updatedData[key] = updatedData[key].filter(obj => {
                    const objectId = Object.keys(obj)[0];
                    return quesIds.includes(objectId); //Only those record taken if match
                });
            }
        }

        setAttribute(updatedData); // Update the state with the modified data
    };

    /** Handle On Offer Change */
    const handleOfferChange = (e, formType) => {
        const { value, checked, name } = e.target;
        if (checked) { //For push
            setFormData((prevFormData) => ({
                ...prevFormData,
                [formType]: {
                    ...prevFormData[formType],
                    [name]: [...prevFormData[formType].offers, value]
                },
            }));
        } else { //For pull
            setFormData((prevFormData) => ({
                ...prevFormData,
                [formType]: {
                    ...prevFormData[formType],
                    [name]: prevFormData[formType].offers.filter((e) => e !== value)
                },
            }));
        }
    };

    /** get quotes from using third party api */
    const getQuote = async () => {
        const dateObject = new Date(formData.form1.date_of_birth);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
        const year = dateObject.getFullYear();

        let objectData = {
            "ZipCode": formData.form2.zip_code,
            "COMPINC": selectedCarrierOption.length > 0 ? selectedCarrierOption.map((i) => i.value).join(",") : "",
            "BirthMonth": month,
            "BirthYear": year,
            "Birthday": day,
            "FaceAmount": formData.form2.price,
            "Health": selectedHealthOption.value,
            "LANGUAGE": "E",
            "ModeUsed": "M",
            "NewCategory": selectedCatOption.value,
            "Sex": formData.form1.sex,
            "Smoker": formData.form1.smoker,
            "State": 0,
            "UserLocation": "json",
            'MaxNumResults': '100',
            'iframe_token': iframe_token,
            'userFriendly': userFriendly
        };

        // console.log('objectData', objectData, 'formData', formData, 'selectedCatOption', selectedCatOption, 'selectedHealthOption', selectedHealthOption);
        try {
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest('/policies/quote', 'POST', objectData);
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                let data = [];
                if (res.data.data.hasOwnProperty('message')) {
                    toast.error(res.data.data.message);
                } else if (res.data.data.hasOwnProperty('error')) {
                    toast.error(res.data.data.error);
                } else {
                    if (Object.keys(res.data.data).length !== 0) {
                        const compulifeResults = res.data.data?.Compulife_ComparisonResults?.Compulife_Results;
                        //console.log('type', typeof compulifeResults, 'Object.keys(compulifeResults).length', Object.keys(compulifeResults).length, 'compulifeResults.length', compulifeResults.length);
                        if (Object.keys(compulifeResults).length !== 0) {
                            data = compulifeResults.filter((item) => {
                                return Object.keys(item).length !== 0;
                            });
                        }
                    }
                }
                setFormData((prevFormData) => ({ ...prevFormData, form6: data }));
            }
        } catch (error) {
            toast.error(error.message);
        }

    };

    /*** Get Policies Questions Get By Policies Ids  */
    const getPoliciesQuestions = async () => {
        try {
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest('/policies/questions', 'POST', { policy_ids: formData.form3.policies });
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {

                //  console.log('car', res.data.data);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    form4: res.data.data,
                }));
                // Remove attribute object if policy questions remove by user
                removeObjectsIfNotMatchIds(res.data.quesIds);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading1: false }));
        }
    };

    const nextStep = async () => {
        let formStep = formData.step;


        let validateForm = true;

        if (formStep == 1) {
            validateForm = checkFormIsValid('form1', '', formData);

        } else if (formStep == 2) {
            validateForm = checkFormIsValid('form2', '', formData);
        } else if (formStep == 3) {
            validateForm = checkFormIsValid('form3', '', formData);
            if (validateForm) {
                getPoliciesQuestions(); // Policy questio get when form 3 is submitted
            }
        } else if (formStep == 5 && formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID)) {
            getQuote(); //on last step of question screeens
        }

        if (validateForm) {
            if (iframe_token) {
                if ((formStep == 5 && !mainData.userData.notification_settings.enableOffer) || formStep == 6) {
                    createAndUpdateLead(true);
                } else {
                    createAndUpdateLead(false);
                }
            } else {
                toast.error('Something went wrong, please try again with correct URL');
                return false;
            }
            if ((formStep == 3 && !formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID)) || (formStep == 5 && !mainData.userData.notification_settings.enableOffer)) {
                setFormData({ ...formData, step: formStep + 2 }); // If policy of lic not exsist or offer not anabled by agent then do this action
            } else {
                setFormData({ ...formData, step: formStep + 1 });
            }
        }
    };

    const prevStep = () => {
        if ((formData.step == 5 && !formData.form3.policies.includes(process.env.REACT_APP_POLICY_ID)) || (formData.step == 7 && !mainData.userData.notification_settings.enableOffer)) {
            setFormData({ ...formData, step: formData.step - 2 });
        } else {
            setFormData({ ...formData, step: formData.step - 1 });
        }
    };

    const renderForm = () => {
        switch (formData.step) {
            case 1:
                return <Step1
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    mainData={mainData}
                />;

            case 2:
                return <Step2
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    handleChange={handleChange}
                    handlePhoneChange={handlePhoneChange}
                    mainData={mainData}

                />;

            case 3:
                return <Step3
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    handlePolicyChange={handlePolicyChange}
                    policyList={mainData?.combinePolicies?.length ? mainData.combinePolicies : []}
                    mainData={mainData}

                />;

            case 4:
                return <Step4
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    setFormData={setFormData}
                    setAttribute={setAttribute}
                    attribute={attribute}
                    setFormErrors={setFormErrors}
                    stateList={mainData?.states?.length ? mainData.states : []}
                    healthList={mainData?.healths?.length ? mainData.healths : []}
                    catList={mainData?.categories?.length ? mainData.categories : []}
                    carrierList={mainData?.combineCarriers?.length ? mainData.combineCarriers : []}
                    selectedStateOption={selectedStateOption}
                    selectedCatOption={selectedCatOption}
                    selectedHealthOption={selectedHealthOption}
                    selectedCarrierOption={selectedCarrierOption}
                    onChangeState={onChangeState}
                    onChangeCat={onChangeCat}
                    onChangeHealth={onChangeHealth}
                    onChangeCarrier={onChangeCarrier}
                    mainData={mainData}

                />;


            case 5:
                return <Step5
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    setFormData={setFormData}
                    setAttribute={setAttribute}
                    attribute={attribute}
                    setFormErrors={setFormErrors}
                    stateList={mainData?.states?.length ? mainData.states : []}
                    healthList={mainData?.healths?.length ? mainData.healths : []}
                    catList={mainData?.categories?.length ? mainData.categories : []}
                    carrierList={mainData?.userData?.carriers?.length ? mainData.combineCarriers : []}
                    selectedStateOption={selectedStateOption}
                    selectedCatOption={selectedCatOption}
                    selectedHealthOption={selectedHealthOption}
                    selectedCarrierOption={selectedCarrierOption}
                    onChangeState={onChangeState}
                    onChangeCat={onChangeCat}
                    onChangeHealth={onChangeHealth}
                    onChangeCarrier={onChangeCarrier}
                    mainData={mainData}

                />;

            case 6:
                return mainData.userData.notification_settings.enableOffer
                    ?
                    <Step6
                        nextStep={nextStep}
                        prevStep={prevStep}
                        formErrors={formErrors}
                        formData={formData}
                        handleOfferChange={handleOfferChange}
                        offerList={mainData?.combineOffers?.length ? mainData.combineOffers : []}
                        mainData={mainData}

                    />
                    :
                    null;

            case 7:
                return <Step7
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formErrors={formErrors}
                    formData={formData}
                    handleChange={handleChange}
                    mainData={mainData}
                />;

            default:
                return null;
        }
    };


    // console.log('state', selectedCatOption, selectedHealthOption, selectedStateOption, selectedCarrierOption);
    // console.log('formdata', formData);
    //console.log('formErrors full', formErrors);

    const copyCode = () => {

        const codeElement = document.querySelector('.iframe-code-box code');
        const textToCopy = codeElement.textContent;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert('Code copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy code: ', err);
            });
    };

    // Assuming mainData is your data object
    const displayName = mainData?.userData?.display_name?.replace(/([A-Z])/g, ' $1').trim();

    return (
        <div className="px-4 px-md-0">
            <Helmet>
                <title>{`${displayName}: Request Quote using iQuote`}</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {!isAdmin && isRouteActive('/user/embeddable-form') && (
                <div className="container-xxl mt-5">
                    <div className="d-flex justify-content-end">

                        <a className="mx-2 btn btn-primary btn-sm" href={`${process.env.REACT_APP_AGENT_LANDING_PAGE}/${user?.display_name}`} target="_blank" >Go to landing page</a>
                    </div>
                </div>
            )}
            <form
                className={`inner-steps form w-100 position-relative`}
                noValidate
                id="kt_sign_in_form"
                onSubmit={formSumbitHandle}
            >
                <div className="">
                    <div className="form-logo mb-10">
                        <img width={250} className="img-fluid" src="/Logo-iQuote-black.png" />
                        <p className="logo-user-name">{mainData?.userData?.display_name} </p>
                    </div>
                </div>
                {loading1 ? <div className="content-loader form-loader">
                    <img width={50} src="/pulse.gif" /> </div> : null}

                {renderForm()}

            </form>

            {
                //currentRouteName == '/user/embeddable-form' || currentRouteName.includes('/pages')
                currentRouteName == '/user/embeddable-form'
                    ?
                    <div className="iframe-code-box">
                        <div className="text-end mb-3">
                            <button className="btn btn-primary btn-sm" type="button" onClick={copyCode}>
                                <i className="ki-duotone ki-copy fs-2"></i> Copy iframe</button>
                        </div>
                        <div className="iframe-code-box-inner">
                            <pre><code>&lt;iframe src="{`${process.env.REACT_APP_BUILD_URL + `/user/multi-step-form?encryptedId=` + encodeURIComponent(mainData?.userData?.iframe_token)}`}" width="600" height="400"&gt;&lt;/iframe&gt;</code></pre>
                        </div>
                    </div>
                    :
                    null
            }

        </div>
    );
};

export default MultiStepForm;
